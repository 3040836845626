import { ORG_UPDV2 } from "src/consts.js";
import { updateTokens } from "src/utils/updateTokens.js";

// запрос отправляет данные на добавление или модификацию организации из панели администратора
export const fetchClBaseEdit = async (bodyData) => {
  // запрос
  const response = await fetch(ORG_UPDV2, {
    method: 'POST',
    body: JSON.stringify(bodyData),
    headers: {
      'Content-type': 'application/json; charset=UTF-8',
      'User-Agent': 'LiftupSite/1.0.0',
      'Authorization': `Bearer ${localStorage.getItem('tokenlu')}`,
    },
  });
  const data = await response.json();
  console.log('data: ', data.status);

  if (response.status === 401 || data.loginstatus === 7) {

    // перезапись токенов
    await updateTokens();

    // запрос после перезаписи токенов
    const response = await fetch(ORG_UPDV2, {
      method: 'POST',
      body: JSON.stringify(bodyData),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
        'User-Agent': 'LiftupSite/1.0.0',
        'Authorization': `Bearer ${localStorage.getItem('tokenlu')}`,
      },
    });
    const data = await response.json();
    console.log('data: ', data);

    console.log('обновление организации произошло c перезаписью токенов');

    return data;
  }

  console.log('обновление организации произошло без перезаписи токенов');

  return data;
};
