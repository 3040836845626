import st from './RegForm.module.css';
import { Link, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import handleInputChange from 'src/utils/handleInputChange';
import phoneNumberMask from 'src/utils/phoneNumberMask.js';
import passwordShowHide from 'src/utils/passwordShowHide.js';
import hashPassword from 'src/utils/hashPassword';
import { useDispatch, useSelector } from 'react-redux';
import { addReg } from 'src/store/registration/reg';

export const RegForm = () => {
  const dataReg = useSelector(state => state.regReducer.dataReg);

  // инпуты
  const [regNameCompany, setRegNameCompany] = useState(dataReg.orgfullName || '');
  const [regInnCompany, setRegInnCompany] = useState(dataReg.orginn || '');
  const [regFio, setRegFio] = useState(dataReg.userFSLname || '');
  const [regPhoneNum, setRegPhoneNum] = useState(phoneNumberMask(dataReg.userPhone?.slice(1)) || '');
  const [regMail, setRegMail] = useState(dataReg.userEmail || '');
  const [regPassword, setRegPassword] = useState('');
  const [regPassword2, setRegPassword2] = useState('');

  // уведомления
  const [showNoteNameCompany, setShowNoteNameCompany] = useState('');
  const [showNoteINN, setShowNoteINN] = useState('');
  const [showNoteFIO, setShowNoteFIO] = useState('');
  const [showNotePhone, setShowNotePhone] = useState('');
  const [showNoteMail, setShowNoteMail] = useState('');
  const [showNotePass, setShowNotePass] = useState('');
  const [showNotePass2, setShowNotePass2] = useState('');



  useEffect(() => {

    if (dataReg.orgfullName !== '') {
      setShowNoteNameCompany(true);
    }
    if (dataReg.orginn !== '') {
      setShowNoteINN(true);
    }
    if (dataReg.userFSLname !== '') {
      setShowNoteFIO(true);
    }
    if (dataReg.userPhone !== '') {
      setShowNotePhone(true);
    }
    if (dataReg.userEmail !== '') {
      setShowNoteMail(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // не удалять!
  // const [countryСode, setСountryСode] = useState('');

  const dispatch = useDispatch();
  const navigate = useNavigate();


  // чекбокс управление
  const [checked, setChecked] = useState(true);
  const chengeCheckbox = () => {
    setChecked(!checked);
  };

  // скрываем показываем пароль
  const [passwordVisibility, setPasswordVisibility] = useState('password');

  // паттерны валидации
  const patternNameCompany = /^((?!\s{2}).){3,28}$/;
  const patternINN = /^(([0-9]{12})|([0-9]{10}))?$/;
  const patternFIO = /^[А-ЯA-Z][а-яa-zА-ЯA-Z-]{0,}\s[А-ЯA-Z][а-яa-zА-ЯA-Z-]{1,}(\s[А-ЯA-Z][а-яa-zА-ЯA-Z-]{1,})?$/;
  const patternMail = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
  const patternPassword = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[ -/:-@[-`{-~]).{8,64}$/;

  // меняет значение в инпуте "телефон" на отформатированное
  const phoneNumberFormatter = (e) => {
    const formattedTargetValue = phoneNumberMask(e.target.value);

    e.target.value = formattedTargetValue;

    setRegPhoneNum(e.target.value);

    if (e.target.value.length === 13) {
      setShowNotePhone(true);
    } else {
      setShowNotePhone(false);
    }
  };

  // приводим содержимое инпута "телефон" в нужный вид
  const numPhoneForReq = 7 + regPhoneNum.replace(/\D/g, '');

  // формируем объект для запроса
  const dataObjForReq = {
    'orginn': regInnCompany,
    'orgfullName': regNameCompany,
    'userFSLname': regFio,
    'userPhone': numPhoneForReq,
    'userEmail': regMail,
    'userHashPass': hashPassword(regPassword)
  };

  // сохраняем введенные данные в хранилище Redax при нажатии на кнопку "Зарегистрироваться" 
  // и переходим на страницу подтверждения телефона
  const collectFormData = (e) => {
    e.preventDefault();

    dispatch(addReg(dataObjForReq));

    navigate('/signin/regphone');
  };

  return (
    <>
      <h2 className={st.title}>Добро пожаловать</h2>

      <p className={st.description}>Введите свои данные для доступа в личный кабинет</p>

      <Link className={st.close_btn} to='/'></Link>

      <div className={st.reg_autor_btns}>
        <button
          className={st.reg_autor_btn}
          onClick={() => navigate('/signin/author')}
        >Авторизация</button>
        <button
          className={st.reg_autor_btn_active}
        // onClick={() => navigate('/signin/reg')}
        >Регистрация</button>
      </div>

      <form className={st.reg_form}>

        {/* ввод наименования компании */}
        <div className={st.note_wrap}>
          <p className={
            showNoteNameCompany === '' ? st.note_empty : showNoteNameCompany ? st.note_hide : st.note_mail
          }>Введите наименование компании</p>

          <input
            className={st.input}
            type='text '
            name='namecompany'
            value={regNameCompany}
            onChange={(e) => handleInputChange(e, patternNameCompany, setShowNoteNameCompany, setRegNameCompany)}
            // onChange={(e) => setRegNameCompany(e.target.value)}
            placeholder='Наименование компании'
          />
        </div>

        {/* ввод ИНН компании */}
        <div className={st.note_wrap}>
          <p className={
            showNoteINN === '' ? st.note_empty : showNoteINN ? st.note_hide : st.note_mail
          }>Введите ИНН компании</p>

          <input
            className={st.input}
            type='text '
            name='INNcompany'
            value={regInnCompany}
            onChange={(e) => handleInputChange(e, patternINN, setShowNoteINN, setRegInnCompany)}
            // onChange={(e) => setRegInnCompany(e.target.value)}
            placeholder='ИНН компании'
          />
        </div>

        {/* ввод ФИО компании */}
        <div className={st.note_wrap}>
          <p className={
            showNoteFIO === '' ? st.note_empty : showNoteFIO ? st.note_hide : st.note_mail
          }>Введите ФИО ответственного сотрудника</p>

          <input
            className={st.input}
            type='text '
            name='fio'
            value={regFio}
            onChange={(e) => handleInputChange(e, patternFIO, setShowNoteFIO, setRegFio)}
            // onChange={(e) => setRegFio(e.target.value)}
            placeholder='ФИО ответственного сотрудника'
          />
        </div>

        {/* ввод номера телефона компании */}
        <div className={st.note_wrap}>
          <p className={
            showNotePhone === '' ? st.note_empty : showNotePhone ? st.note_hide : st.note_mail
          }>Введите корректный номер телефона</p>

          <label className={st.label}>
            <p className={st.select}>+7</p>
            {/* <select
              className={st.select}
              name='countryСode'
              value={countryСode}
              onChange={(e) => setСountryСode(e.target.value)}>
              <option value=''>+7</option>
              <option value='+86'>+86</option>
            </select> */}

            <input
              className={st.input_phone}
              type='text '
              name='phonenum'
              value={regPhoneNum}
              onChange={(e) => phoneNumberFormatter(e)}
              placeholder='Номер мобильного телефона'
            />
          </label>
        </div>

        {/* ввод E-MAIL компании */}
        <div className={st.note_wrap}>
          <p className={
            showNoteMail === '' ? st.note_empty : showNoteMail ? st.note_hide : st.note_mail
          }>Введите корректный e-mail</p>

          <input
            className={st.input}
            type='email '
            name='regmail'
            value={regMail}
            onChange={(e) => handleInputChange(e, patternMail, setShowNoteMail, setRegMail)}
            // onChange={(e) => setRegMail(e.target.value)}
            placeholder='E-mail'
          />
        </div>

        {/* ввод первого пароля компании */}
        <div className={st.note_wrap}>
          <p className={showNotePass === '' ? st.note_empty : showNotePass ? st.note_hide : st.note_pass}>
            Пароль должен содержать не менее 8 символов и состоять из больших латинских букв, маленьких латинских букв, цифр и символов
          </p>

          <div className={st.input_wrap}>
            <input
              className={st.input}
              type={passwordVisibility}
              name='regpassword'
              value={regPassword}
              onChange={(e) => handleInputChange(e, patternPassword, setShowNotePass, setRegPassword)}
              // onChange={(e) => setRegPassword(e.target.value)}
              placeholder='Пароль'
            />
            <button
              className={passwordVisibility === 'password' ? st.input_btn_closed : st.input_btn_open}
              onClick={(e) => passwordShowHide(e, passwordVisibility, setPasswordVisibility)}
            ></button>
          </div>
        </div>

        {/* ввод вторго пароля компании */}
        <div className={st.note_wrap}>
          <p className={showNotePass2 === '' ? st.note_empty : regPassword === regPassword2 ? st.note_hide : st.note_mail}>
            Пароли не совпадают
          </p>

          <input
            className={st.input}
            type={passwordVisibility}
            name='regpassword2'
            value={regPassword2}
            onChange={(e) => handleInputChange(e, patternPassword, setShowNotePass2, setRegPassword2)}
            placeholder='Повторный ввод пароля'
          />
        </div>

        {/* чекбока принятие польз. соглашения компании */}
        <label className={st.input_check_wrap}>
          <input
            className={st.input_check}
            type='checkbox'
            name='assent'
            checked={checked}
            onChange={chengeCheckbox}
          />

          <p>Я&nbsp;принимаю условия Пользовательского соглашения и&nbsp;даю своё согласие на&nbsp;обработку моей персональной информации на&nbsp;условиях, определенных Политикой конфиденциальности.</p>
        </label>

        {/* кнопка зарегистрироваться */}
        <button
          className={st.button_submit}
          onClick={collectFormData}
          disabled={
            (
              showNoteNameCompany &&
              showNoteINN &&
              showNoteFIO &&
              showNotePhone &&
              showNoteMail &&
              showNotePass &&
              showNotePass2 &&
              checked &&
              regPassword === regPassword2
            ) ? false : true
          }
        >Зарегистрироваться</button>

        <button
          className={st.button_submit}
          onClick={() => {
            // dispatch(addReg(dataObjForReq));
            navigate('/signin/regphone')
          }}
        >Шаг вперед</button>
      </form>
    </>
  )
};
