import st from './SectionBrands.module.css';
import otis_logo from '../../img/logo_brends/otis_logo.svg';
import kone_logo from '../../img/logo_brends/kone_logo.svg';
import blt_logo from '../../img/logo_brends/blt_logo.svg';
import detail from '../../img/warranty_parts/detail_1_dvig_new.svg';
import detai2 from '../../img/warranty_parts/detail_2_chastotnii_new.svg';
import detai3 from '../../img/warranty_parts/detail_3_gl_plata.svg';
import detai4 from '../../img/warranty_parts/detail_4_plata_dveri.svg';
import { useState } from 'react';

export const SectionBrands = () => {
  // выпадающий список моб версии лифты, эскалаторы, травалаторы
  const [dropdown, setDropdown] = useState(false);

  // состояния по брендам (левый блок кнопки с названиями брэндов)
  const [btnBrand1, setBtnBrand1] = useState(true);
  const [btnBrand2, setBtnBrand2] = useState(false);
  const [btnBrand3, setBtnBrand3] = useState(false);

  // состояния по брендам (левый блок кнопки с названиями брэндов)
  const [elevator, setElevator] = useState(true);
  const [escalator, setEscalator] = useState(false);
  const [travelator, setTravelator] = useState(false);

  // присваиваем класс кнопке бренда NavLink
  // const setActive = ({ isActive }) => isActive ? `${st.brand_link_active}` : `${st.brand_link}`;
  // присваиваем класс кнопке информации бренда NavLink
  // const setInfoActive = ({ isActive }) => isActive ? `${st.info_item_btn_active}` : `${st.info_item_btn}`;


  return (
    <section className={`${st.sectionbrands} container`}>
      <h2>Бренды, с которыми мы работаем</h2>

      <div className={st.brands_container}>
        {/* левый блок с брендами */}
        <div className={st.wrap_btns}>
          <ul className={st.btns_list}>
            <li className={st.btn_item}>
              <div
                className={!btnBrand1 ? st.brand_img : st.brand_img_active}
                onClick={() => {
                  setBtnBrand1(true);
                  setBtnBrand2(false);
                  setBtnBrand3(false);
                }}
              >
                <img src={blt_logo} alt='' />
              </div>
            </li>

            <li className={st.btn_item}>
              <div
                className={!btnBrand2 ? st.brand_img : st.brand_img_active}
                onClick={() => {
                  setBtnBrand1(false);
                  setBtnBrand2(true);
                  setBtnBrand3(false);
                }}
              >
                {/* <img src={kone_logo} alt='' /> */}
              </div>
            </li>

            <li className={st.btn_item}>
              <div
                className={!btnBrand3 ? st.brand_img : st.brand_img_active}
                onClick={() => {
                  setBtnBrand1(false);
                  setBtnBrand2(false);
                  setBtnBrand3(true);
                }}
              >
                {/* <img src={otis_logo} alt='' /> */}
              </div>
            </li>
          </ul>
        </div>

        {/* правый блок с информацией */}
        <div className={st.wrap_info}>
          <div className={st.wrap_btn_group}>
            {/* мобильная версия табов - выпадающий список */}
            <div className={st.drop_down_mob}>
              <p>Лифты</p>

              <div
                className={!dropdown ? st.btn_dropdown_down : st.btn_dropdown_up}
                onClick={() => setDropdown(!dropdown)}>
                <svg width="18" height="9" viewBox="0 0 18 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M17 1L8.988 7 1 1" stroke="#fff" strokeWidth="2" />
                </svg>
              </div>
            </div>

            <ul className={!dropdown ? st.info_btn_list : st.info_btn_list_show}>
              <li className={st.info_item}>
                <button
                  className={elevator ? st.info_item_btn_active : st.info_item_btn}
                  onClick={() => {
                    setElevator(true);
                    setEscalator(false);
                    setTravelator(false);
                    setDropdown(false);
                  }}>
                  Лифты
                </button>
              </li>
              <li className={st.info_item}>
                <button
                  className={escalator ? st.info_item_btn_active : st.info_item_btn}
                  onClick={() => {
                    setElevator(false);
                    setEscalator(true);
                    setTravelator(false);
                    setDropdown(false);
                  }}>
                  Эскалаторы
                </button>
              </li>
              <li className={st.info_item}>
                <button
                  className={travelator ? st.info_item_btn_active : st.info_item_btn}
                  onClick={() => {
                    setElevator(false);
                    setEscalator(false);
                    setTravelator(true);
                    setDropdown(false);
                  }}>
                  Траволаторы
                </button>
              </li>
              <li className={st.info_item_empty}></li>
            </ul>
          </div>

          <p className={st.description}>
            Предоставляем гарантию на ключевые компоненты
            {elevator ? ' лифтов ' : escalator ? ' эскалаторв ' : travelator ? ' травалаторов ' : ' ЛИФТОВ '}
            ведущих и наиболее широко распространенных брендов.
          </p>

          {/* <h3>Детали, на&nbsp;которые распространяется гарантия:</h3> */}
          <h3>Основные&nbsp;узлы</h3>

          <ul className={st.warranty_parts_list}>
            <li className={st.warranty_part_item}>
              <img src={detail} alt='' />

              <div className={st.item_content}>
                <h4>Электродвигатель главного привода</h4>
                <p>(без гарантии на подшипники)</p>
              </div>
            </li>
            <li className={st.warranty_part_item}>
              <img src={detai2} alt='' />

              <div className={st.item_content}>
                <h4>Частотный преобразователь</h4>
                <p>Частотный преобразователь</p>
              </div>
            </li>
            <li className={st.warranty_part_item}>
              <img src={detai3} alt='' />

              <div className={st.item_content}>
                <h4>Главная плата управления
                  {elevator ? ' лифтом ' : escalator ? ' эскалатором ' : travelator ? ' травалатором ' : ' ЛИФТОВ '}
                </h4>
                <p>
                  Главная плата управления
                  {elevator ? ' лифтом ' : escalator ? ' эскалатором ' : travelator ? ' травалатором ' : ' ЛИФТОВ '}
                </p>
              </div>
            </li>
            <li className={elevator ? st.warranty_part_item : st.hidden}>
              <img src={detai4} alt='' />

              <div className={st.item_content}>
                <h4>Плата управления приводом дверей кабины</h4>
                <p>Плата управления приводом дверей кабины</p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </section>
  )
};
