import { SLIDES_REORDER } from "src/consts";
import { updateTokens } from "src/utils/updateTokens";

// смена порядка слайдов
export const fetchSlideReorder = async (bodyReorder) => {
  // отправляем запрос на смену порядковых номеров слайдов
  const responseslidereorder = await fetch(SLIDES_REORDER, {
    method: 'POST',
    body: JSON.stringify(bodyReorder),
    headers: {
      'Content-type': 'application/json; charset=UTF-8',
      'User-Agent': 'LiftupSite/1.0.0',
      'Authorization': `Bearer ${localStorage.getItem('tokenlu')}`,
    },
  });
  const data = await responseslidereorder.json();
  // console.log('data1: ', data);
  console.log('данные на добавление слайда отправлены без перезаписи токенов');

  // в случае протухания токена обновляем пару "token - rtoken"
  if (data.loginstatus === 7) {
    // перезапись токенов
    await updateTokens();

    // снова отправляем запрос 
    const responseslidereorder = await fetch(SLIDES_REORDER, {
      method: 'POST',
      body: bodyReorder,
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
        'User-Agent': 'LiftupSite/1.0.0',
        'Authorization': `Bearer ${localStorage.getItem('tokenlu')}`,
      },
    });
    const data = await responseslidereorder.json();
    console.log('data2: ', data);
    console.log('данные на добавление слайда отправлены c перезаписью токенов');
  }
};
