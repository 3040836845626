import { CHANGE_PASS, SHS_POST } from 'src/consts.js';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { updateTokens } from 'src/utils/updateTokens';

// получаем токены и юзера отправив логин и пароль по кнопке "Войти"
export const fetchChangePassThunk = createAsyncThunk(
  'changePassThunk/fetchChangePassThunk',

  async (dataBody) => {

    const responseshc = await fetch(SHS_POST, {
      method: 'POST',
      body: JSON.stringify(dataBody[0]),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
        'User-Agent': 'LiftupSite/1.0.0',
      }
    });

    const datashc = await responseshc.json();

    if (responseshc.status === 200) {
      // запрос на смену пароля
      const responseaddpass = await fetch(CHANGE_PASS, {
        method: 'POST',
        body: JSON.stringify(dataBody[1]),
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'User-Agent': 'LiftupSite/1.0.0',
          'Authorization': `Bearer ${localStorage.getItem('tokenlu')}`,
          'shc': datashc.shc,
        },
      });

      const dataaddpass = await responseaddpass.json();

      // если токен просрочен
      if (responseaddpass.status === 401 || dataaddpass.loginstatus === 7) {

        // перезапись токенов
        await updateTokens();

        // повторный запрос на смену пароля после перезаписи токенов
        const responseaddpass = await fetch(CHANGE_PASS, {
          method: 'POST',
          body: JSON.stringify(dataBody[1]),
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
            'User-Agent': 'LiftupSite/1.0.0',
            'Authorization': `Bearer ${localStorage.getItem('tokenlu')}`,
            'shc': datashc.shc,
          },
        });

        const dataaddpass = await responseaddpass.json();

        console.log('запрос на смену пароля успешно отправлен с перезаписью токенов');

        return dataaddpass;

      }

      console.log('запрос на смену пароля успешно отправлен без перезаписи токенов');

      return dataaddpass;

    } else {

      console.log('responseshc.status: ', responseshc.status);
      console.log('запрос на смену пароля не отправлен, ошибка запроса shc');

      return responseshc;

    }
  }
);

const initialState = {
  status: 'idle',
  dataChangePass: {
    'loginstatus': 99
  },
  error: null,
};

const changePassSlice = createSlice({
  name: 'changePassThunk',
  initialState,
  reducers: {
    removeChangePassData: (state) => {
      state.dataChangePass = {
        'loginstatus': 99
      }
    }
  },

  extraReducers: (builder) => {
    builder

      .addCase(fetchChangePassThunk.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(fetchChangePassThunk.fulfilled, (state, action) => {
        state.status = 'success';
        state.dataChangePass = action.payload;
      })
      .addCase(fetchChangePassThunk.rejected, (state, action) => {
        state.status = 'failed';
        state.dataChangePass = action.error.message;
      })

  }
});

export const { removeChangePassData } = changePassSlice.actions;

export default changePassSlice.reducer;
