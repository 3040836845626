import st from './Error404.module.css';

export const Error404 = () => {
  
  return (
      <main className={`${st.error404} container`}>
        <div className={st.image}></div>
        <h4>Страница с указанным адресом отсутствует!</h4>
      </main>
    )
};
