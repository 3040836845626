import st from './Fias1.module.css';
import { v4 as uuidv4 } from 'uuid';
import { useEffect, useState } from 'react';
import { handleInputChangeStop } from 'src/utils/handleInputChange';
import { fetchGetDataFias, fetchGetRegionFias } from 'src/fetch/fetchFIAS';
import { indexСhange, removeFocuse, searchInArrayElement, setElem } from '../utils';

export const Fias1 = ({ setFiasDataObj1, dataForFields1 }) => {
  // пременные для упрощения написания вызова функции "indexСhange"
  const typeOfDistrictStatic = dataForFields1?.hierarchy[1]?.full_name_short;
  // эта при клике по элементу выбора муниципального района
  const [typeOfDistrictDinamic, setTypeOfDistrictDinamic] = useState('');

  // паттерны валидации
  const patternPostalIndex = /^(\d){0,8}$/g;
  const patternNameCity = /^[а-яА-Я- .]*$/;

  // данные для запроса в ФИАС на получение РБ > Муниципальный район/округ 
  // const [requestObjectMunicipalAreas1, setRequestObjectMunicipalAreas1] = useState({ "address_type": 2, "path": "5705" });
  const [requestObjectMunicipalAreas1, setRequestObjectMunicipalAreas1] = useState({});
  const [requestObjectRuralUrbanSettlement1, setRequestObjectRuralUrbanSettlement1] = useState({});
  const [requestObjectPopulatedPoint1, setRequestObjectPopulatedPoint1] = useState({});
  const [requestObjectStrit1, setRequestObjectStrit1] = useState({});
  const [requestObjectHouseNumber1, setRequestObjectHouseNumber1] = useState({});
  const [requestObjectApartmentNumber1, setRequestObjectApartmentNumber1] = useState({});

  // это поле для того чтобы отображать либо населенный пункт, при выборе "г.о." 
  // либо сельское/городское поселение (следующее за этим, поле), при выборе "м. р-н"
  // а также для корректного отображения данных всех нижних элементов 
  const [operationIdMunicipalAreas1, setOperationIdMunicipalAreas1] = useState(null);

  // ======== выбор субъекта РФ района
  // ввод в инпут поиска субъекта РФ
  const [subjectRF1, setSubjectRF1] = useState(
    dataForFields1?.hierarchy[0]?.full_name_short ?
      dataForFields1.hierarchy[0]?.full_name_short : ''
  );

  // выбранные из списка данные для отправки на сервер
  const [subjectRFToSend1, setSubjectRFToSend1] = useState('');

  // две нижние строки идут в паре с одинаковыми данными - первые - изменяемые, вторые - нет
  const [subjectRFArr1, setSubjectRFArr1] = useState([]);
  const [dataSubjectRFArr1, setDataSubjectRFArr1] = useState([]);

  // ======== выбор муниципального района
  // ввод в инпут поиска муниципального района
  const [munAreas1, setMunAreas1] = useState(
    dataForFields1?.hierarchy[1]?.full_name_short ?
      dataForFields1.hierarchy[1]?.full_name_short : ''
  );
  // выбранные из списка данные для отправки на сервер
  const [munAreasToSend1, setMunAreasToSend1] = useState('');

  // две нижние строки идут в паре с одинаковыми данными - первые - изменяемые, вторые - нет
  const [municipalAreasArr1, setMunicipalAreasArr1] = useState([]);
  const [dataMunicipalAreasArr1, setDataMunicipalAreasArr1] = useState([]);

  // ======== выбор сельское/городское поселение при выбранном "м. р-н"
  // ввод в инпут поиска
  const [ruralUrbanSettlement1, setRuralUrbanSettlement1] = useState(
    dataForFields1?.hierarchy[2]?.full_name_short ?
      dataForFields1.hierarchy[2]?.full_name_short : 'нет данных'
  );
  // выбранные из списка данные для отправки на сервер
  const [ruralUrbanSettlementToSend1, setRuralUrbanSettlementToSend1] = useState('');

  // две нижние строки идут в паре с одинаковыми данными - первые - изменяемые, вторые - нет
  const [ruralUrbanSettlementArr1, setRuralUrbanSettlementArr1] = useState([]);
  const [dataRuralUrbanSettlementArr1, setDataRuralUrbanSettlementArr1] = useState([]);

  // ======== выбор населенного пункта г.о.
  // ввод в инпут поиска населенного пункта
  const [popPoint1, setPopPoint1] = useState(
    dataForFields1?.hierarchy[indexСhange(typeOfDistrictStatic, 2, 3)]?.full_name_short ?
      dataForFields1.hierarchy[indexСhange(typeOfDistrictStatic, 2, 3)]?.full_name_short : ''
  );
  // выбранные из списка данные для отправки на сервер
  const [popPointToSend1, setPopPointToSend1] = useState('');

  // две нижние строки идут в паре с одинаковыми данными - первые - изменяемые, вторые - нет
  const [populatedPointArr1, setPopulatedPointArr1] = useState([]);
  const [dataPopulatedPointArr1, setDataPopulatedPointArr1] = useState([]);

  // ======== выбор улицы
  // ввод в инпут поиска улицы
  const [strit1, setStrit1] = useState(
    dataForFields1?.hierarchy[indexСhange(typeOfDistrictStatic, 3, 4)]?.full_name_short ?
      dataForFields1.hierarchy[indexСhange(typeOfDistrictStatic, 3, 4)]?.full_name_short : ''
  );

  // выбранные из списка данные для отправки на сервер
  const [stritToSend1, setStritToSend1] = useState('');

  // две нижние строки идут в паре с одинаковыми данными - первые - изменяемые, вторые - нет
  const [stritArr1, setStritArr1] = useState([]);
  const [dataStritArr1, setDataStritArr1] = useState([]);

  // ======== выбор номера дома
  // ввод в инпут поиска номера дома
  const [houseNumber1, setHouseNumber1] = useState(
    dataForFields1?.hierarchy[indexСhange(typeOfDistrictStatic, 4, 5)]?.full_name_short ?
      dataForFields1.hierarchy[indexСhange(typeOfDistrictStatic, 4, 5)]?.full_name_short : ''
  );
  // выбранные из списка данные для отправки на сервер
  const [houseNumberToSend1, setHouseNumberToSend1] = useState('');

  // две нижние строки идут в паре с одинаковыми данными - первые - изменяемые, вторые - нет
  const [houseNumberArr1, setHouseNumberArr1] = useState([]);
  const [dataHouseNumberArr1, setDataHouseNumberArr1] = useState([]);

  // ======== выбор помещения
  // ввод в инпут поиска помещения
  const [apartmentNumber1, setApartmentNumber1] = useState(
    dataForFields1?.hierarchy[indexСhange(typeOfDistrictStatic, 5, 6)]?.full_name_short ?
      dataForFields1.hierarchy[indexСhange(typeOfDistrictStatic, 5, 6)]?.full_name_short : ''
  );
  // выбранные из списка данные для отправки на сервер
  const [apartmentNumberToSend1, setApartmentNumberToSend1] = useState('');

  // две нижние строки идут в паре с одинаковыми данными - первые - изменяемые, вторые - нет
  const [apartmentNumberArr1, setApartmentNumberArr1] = useState([]);
  const [dataApartmentNumberArr1, setDataApartmentNumberArr1] = useState([]);

  // тип адреса
  const addresstype1 = 'JUR';

  // это guids всех уровней иерархии, для добавления в отправляемые данные
  const [guids1, setGuids1] = useState(
    {
      guid0subjectrf: '',
      guid1municipalareas: '',
      guid2ruralurbansettlement: '',
      guid23populatedpoint: '',
      guid34street: '',
      guid45housenumber: '',
      guid56apartmentnumber: ''
    }
  );

  // при первой загрузке страницы в зависимости от значения в условии присваиваем значение  переменной в зависимости от которой показывается или скрывается инпут "сельское/городское поселение"
  useEffect(() => {
    if (dataForFields1?.hierarchy[1]?.full_name_short.startsWith('г')) setOperationIdMunicipalAreas1(0);
    if (dataForFields1?.hierarchy[1]?.full_name_short.startsWith('м')) setOperationIdMunicipalAreas1(20);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // помещаем и обновляем новый массив guids1 в объект данных для отправки
  useEffect(() => {
    setFiasDataObj1(prevState => ({ ...prevState, guids: guids1 }));
    setFiasDataObj1(prevState => ({ ...prevState, addresstype: addresstype1 }));
    setFiasDataObj1(prevState => ({ ...prevState, operation_id_municipal_areas: operationIdMunicipalAreas1 }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [guids1]);


  return (
    <div className={st.fias_address_group}>
      {/* инпут субъект РФ */}
      <div className={`${st.wrap_title} ${st.mb30}`}>
        <p className={subjectRF1 === '' ? st.wrap_title_p_hide : st.wrap_title_p}>
          Выбор субъекта РФ</p>

        <div className={st.input_search_wrap}>
          <input
            className={st.input_search}
            type='text'
            name='subjectRF1'
            value={subjectRF1}
            onClick={() => {
              // отправляем запрос на получение регионов
              fetchGetRegionFias(setDataSubjectRFArr1, setSubjectRFArr1)
            }}
            onChange={(e) => {
              // запрет ввода нетого
              handleInputChangeStop(e, patternNameCity, setSubjectRF1);
              // поиск по списку данных
              searchInArrayElement(e, dataSubjectRFArr1, 0, setSubjectRFArr1);
              // очистка поля для отправки
              setSubjectRFToSend1('');
            }}
            placeholder='Введите субъекта РФ'
          // readOnly
          />

          <div
            className={st.input_reset}
            key={uuidv4()}
            // здесь и ниже onMouseDown используется вместо onClick потому что onClick в хроме не срабатывает
            onMouseDown={() => {
              // очищаем текущие поля инпута и отправки
              setSubjectRF1('');
              setSubjectRFToSend1('');

              // восстанавливаем список данных после поиска
              setSubjectRFArr1(dataSubjectRFArr1);

              // очищаем поля параметров запрсов вниз по иерархии
              setRequestObjectMunicipalAreas1({});
              setRequestObjectPopulatedPoint1({});
              setRequestObjectRuralUrbanSettlement1({});
              setRequestObjectStrit1({});
              setRequestObjectHouseNumber1({});
              setRequestObjectApartmentNumber1({});

              // очищаем поля вниз по иерархии
              // ======================
              setMunicipalAreasArr1([]);
              setMunAreas1('');
              // ======================
              setPopulatedPointArr1([]);
              setPopPoint1('');
              // ======================
              setRuralUrbanSettlementArr1([]);
              setRuralUrbanSettlement1('');
              // ======================
              setStritArr1([]);
              setStrit1('');
              // ======================
              setHouseNumberArr1([]);
              setHouseNumber1('');
              // ======================
              setApartmentNumberArr1([]);
              setApartmentNumber1('');
              // ======================
              setFiasDataObj1({});
              // ======================

              // очистка guids
              setGuids1(
                {
                  guid0subjectrf: '',
                  guid1municipalareas: '',
                  guid2ruralurbansettlement: '',
                  guid23populatedpoint: '',
                  guid34street: '',
                  guid45housenumber: '',
                  guid56apartmentnumber: ''
                }
              );
            }}
          >
            Сброс ввода
          </div>

          <ul className={st.list_search}>
            {
              subjectRFArr1 ? subjectRFArr1.map(item => (
                // временное условие пока работает только Башкортостан
                item.hierarchy[0].full_name_short === "Республика Башкортостан" ?
                  <li
                    className={st.item_search}
                    key={uuidv4()}
                    // здесь и ниже onMouseDown используется вместо onClick потому что onClick в хроме не срабатывает
                    onMouseDown={(e) => {
                      // получаем выбранное значение в инпуте и в поле для отправки
                      setElem(e, setSubjectRF1);
                      setElem(e, setSubjectRFToSend1);

                      // помещаем сюда данные для отправки
                      setFiasDataObj1(item);

                      // GUIDS
                      // предварительная очистка нижних по иерархии
                      setGuids1(
                        {
                          guid1municipalareas: '',
                          guid2ruralurbansettlement: '',
                          guid23populatedpoint: '',
                          guid34street: '',
                          guid45housenumber: '',
                          guid56apartmentnumber: ''
                        }
                      );

                      // GUIDS
                      // помещаем item.object_guid в новый массив guids
                      setGuids1(prevState => ({ ...prevState, guid0subjectrf: item.object_guid }));

                      // восстанавливаем список данных после поиска
                      setSubjectRFArr1(dataSubjectRFArr1);

                      // формируем объект для тела запроса данных
                      setRequestObjectMunicipalAreas1({ "address_type": 2, "path": `${item.path}` });

                      // очищаем поля вниз по иерархии
                      // ======================
                      setMunicipalAreasArr1([]);
                      setMunAreas1('');
                      // ======================
                      setPopulatedPointArr1([]);
                      setPopPoint1('');
                      // ======================
                      setRuralUrbanSettlementArr1([]);
                      setRuralUrbanSettlement1('');
                      // ======================
                      setStritArr1([]);
                      setStrit1('');
                      // ======================
                      setHouseNumberArr1([]);
                      setHouseNumber1('');
                      // ======================
                      setApartmentNumberArr1([]);
                      setApartmentNumber1('');
                      // ======================

                      removeFocuse();
                    }}
                  >
                    {item.hierarchy ? item.hierarchy[0].full_name_short : 'нет данных'}
                  </li> : null
              )) : <li className={st.item_search} key={uuidv4()}>ошибка получения данных</li>
            }
          </ul>
        </div>
      </div>

      {/* инпут муниципального района/округа (м.р-н или г.о.) */}
      <div className={`${st.wrap_title} ${st.mb30}`}>
        <p className={munAreas1 === '' ? st.wrap_title_p_hide : st.wrap_title_p}>
          Выбор муниципального района</p>

        <div className={st.input_search_wrap}>
          <input
            className={st.input_search}
            type='text'
            name='munAreas1'
            value={munAreas1}
            onClick={() => {
              // отправляем запрос на получение данных этого поля и вставляем полученное в отображающийся список
              if (Object.keys(requestObjectMunicipalAreas1).length)
                fetchGetDataFias(requestObjectMunicipalAreas1, setDataMunicipalAreasArr1, setMunicipalAreasArr1)
            }}
            onChange={(e) => {
              // запрет ввода нетого
              handleInputChangeStop(e, patternNameCity, setMunAreas1);
              // поиск по списку данных
              searchInArrayElement(e, dataMunicipalAreasArr1, 1, setMunicipalAreasArr1);
              // очистка поля для отправки
              setMunAreasToSend1('');
            }}
            placeholder='Введите название района'
          />

          <div
            className={st.input_reset}
            key={uuidv4()}
            onMouseDown={() => {
              // очищаем текущие поля инпута и отправки
              setMunAreas1('');
              setMunAreasToSend1('');

              // восстанавливаем список данных после поиска
              setMunicipalAreasArr1(dataMunicipalAreasArr1);

              // очищаем поля параметров запрсов вниз по иерархии
              setRequestObjectRuralUrbanSettlement1({});
              setRequestObjectPopulatedPoint1({});
              setRequestObjectStrit1({});
              setRequestObjectHouseNumber1({});
              setRequestObjectApartmentNumber1({});

              // очищаем поля вниз по иерархии
              // ======================
              setPopulatedPointArr1([]);
              setPopPoint1('');
              // ======================
              setRuralUrbanSettlementArr1([]);
              setRuralUrbanSettlement1('');
              // ======================
              setStritArr1([]);
              setStrit1('');
              // ======================
              setHouseNumberArr1([]);
              setHouseNumber1('');
              // ======================
              setApartmentNumberArr1([]);
              setApartmentNumber1('');
              // ======================
              setFiasDataObj1({});
              // ======================

              // очистка всех guids кроме верхних по иерархии
              setGuids1(prevState => (
                {
                  ...prevState,
                  guid1municipalareas: '',
                  guid2ruralurbansettlement: '',
                  guid23populatedpoint: '',
                  guid34street: '',
                  guid45housenumber: '',
                  guid56apartmentnumber: ''
                }
              ));
            }}>
            Сброс ввода
          </div>

          <ul className={st.list_search}>
            {
              municipalAreasArr1 ? municipalAreasArr1.map(item =>
                <li
                  className={st.item_search}
                  key={uuidv4()}
                  onMouseDown={(e) => {
                    // меняем значение динамической строки при выборе элемента (нужно для использования в поиске значений при наборе)
                    setTypeOfDistrictDinamic(item.hierarchy[1]?.full_name_short);

                    // получаем выбранное значение в инпуте и в поле для отправки
                    setElem(e, setMunAreas1);
                    setElem(e, setMunAreasToSend1);

                    // помещаем сюда данные для отправки
                    setFiasDataObj1(item);

                    // GUIDS
                    // предварительная очистка нижних по иерархии
                    setGuids1(prevState => (
                      {
                        ...prevState,
                        guid2ruralurbansettlement: '',
                        guid23populatedpoint: '',
                        guid34street: '',
                        guid45housenumber: '',
                        guid56apartmentnumber: ''
                      }
                    ));

                    // GUIDS
                    // помещаем item.object_guid в новый массив guids
                    setGuids1(prevState => ({ ...prevState, guid1municipalareas: item.object_guid }));

                    // восстанавливаем список данных после поиска
                    setMunicipalAreasArr1(dataMunicipalAreasArr1);

                    // формируем объект для тела запроса данных
                    // как для запроса из насаленный пункт 
                    setRequestObjectPopulatedPoint1({ "address_type": 2, "path": `${item.path}` });
                    // так и для запроса из сельское/городское поселение
                    setRequestObjectRuralUrbanSettlement1({ "address_type": 2, "path": `${item.path}` });

                    // OperationId для отображения либо населенный пункт, либо сельское/городское поселение
                    setOperationIdMunicipalAreas1(item.operation_type_id);

                    // очищаем поля параметров запрсов вниз по иерархии
                    // setRequestObjectRuralUrbanSettlement1({ });
                    // setRequestObjectPopulatedPoint1({ });
                    setRequestObjectStrit1({});
                    setRequestObjectHouseNumber1({});
                    setRequestObjectApartmentNumber1({});
                    // ======================

                    // очищаем поля вниз по иерархии
                    setPopulatedPointArr1([]);
                    setPopPoint1('');
                    // ======================
                    setRuralUrbanSettlementArr1([]);
                    setRuralUrbanSettlement1('');
                    // ======================
                    setStritArr1([]);
                    setStrit1('');
                    // ======================
                    setHouseNumberArr1([]);
                    setHouseNumber1('');
                    // ======================
                    setApartmentNumberArr1([]);
                    setApartmentNumber1('');
                    // ======================

                    removeFocuse();
                  }}>
                  {item.hierarchy[1] ? item.hierarchy[1].full_name_short : 'нет данных'}
                </li>
              ) : <li
                className={st.item_search}
                key={uuidv4()}>ошибка получения данных</li>
            }
          </ul>
        </div>
      </div>

      {/* инпут сельское/городское поселение показываем при выборе м.р-н */}
      {
        operationIdMunicipalAreas1 === 20 ?
          <div className={`${st.wrap_title} ${st.mb30} ${st.pop_point1}`}>
            <p className={ruralUrbanSettlement1 === '' ? st.wrap_title_p_hide : st.wrap_title_p}>
              Выбор сельское/городское поселение</p>

            <div className={st.input_search_wrap}>
              <input
                className={st.input_search}
                type='text'
                name='ruralUrbanSettlement1'
                value={ruralUrbanSettlement1}
                onClick={() => {
                  // отправляем запрос на получение данных этого поля и вставляем полученное в отображающийся список
                  if (Object.keys(requestObjectRuralUrbanSettlement1).length)
                    fetchGetDataFias(requestObjectRuralUrbanSettlement1, setDataRuralUrbanSettlementArr1, setRuralUrbanSettlementArr1)
                }}
                onChange={(e) => {
                  // запрет ввода нетого
                  handleInputChangeStop(e, patternNameCity, setRuralUrbanSettlement1);
                  // поиск по списку данных
                  searchInArrayElement(e, dataRuralUrbanSettlementArr1, 2, setRuralUrbanSettlementArr1);
                  // очистка поля для отправки
                  setPopPointToSend1('');
                }}
                placeholder='Введите название поселения'
              />

              <div
                className={st.input_reset}
                key={uuidv4()}
                onMouseDown={() => {
                  // очищаем текущие поля инпута и отпраки
                  setRuralUrbanSettlement1('');
                  setRuralUrbanSettlementToSend1('');

                  // восстанавливаем список данных после поиска
                  setRuralUrbanSettlementArr1(dataRuralUrbanSettlementArr1);

                  // очищаем поля параметров запрсов вниз по иерархии
                  setRequestObjectPopulatedPoint1({});
                  setRequestObjectStrit1({});
                  setRequestObjectHouseNumber1({});
                  setRequestObjectApartmentNumber1({});
                  // ======================

                  // очищаем поля вниз по иерархии
                  setPopulatedPointArr1([]);
                  setPopPoint1('');
                  // ======================
                  setStritArr1([]);
                  setStrit1('');
                  // ======================
                  setHouseNumberArr1([]);
                  setHouseNumber1('');
                  // ======================
                  setApartmentNumberArr1([]);
                  setApartmentNumber1('');
                  // ======================
                  setFiasDataObj1({});
                  // ======================

                  // очистка всех guids кроме верхних по иерархии
                  setGuids1(prevState => (
                    {
                      ...prevState,
                      guid2ruralurbansettlement: '',
                      guid23populatedpoint: '',
                      guid34street: '',
                      guid45housenumber: '',
                      guid56apartmentnumber: ''
                    }
                  ));
                }}>
                Сброс ввода
              </div>

              <ul className={st.list_search}>
                {
                  ruralUrbanSettlementArr1.map(item =>
                    <li
                      className={st.item_search}
                      key={uuidv4()}
                      onMouseDown={(e) => {
                        // получаем выбранное значение в инпуте и в поле для отправки
                        setElem(e, setRuralUrbanSettlement1);
                        setElem(e, setRuralUrbanSettlementToSend1);

                        // помещаем сюда данные для отправки
                        setFiasDataObj1(item);

                        // GUIDS
                        // предварительная очистка нижних по иерархии
                        setGuids1(prevState => (
                          {
                            ...prevState,
                            guid23populatedpoint: '',
                            guid34street: '',
                            guid45housenumber: '',
                            guid56apartmentnumber: ''
                          }
                        ));

                        // GUIDS
                        // помещаем item.object_guid в новый массив guids
                        setGuids1(prevState => ({ ...prevState, guid2ruralurbansettlement: item.object_guid }));

                        // восстанавливаем список данных после поиска
                        setRuralUrbanSettlementArr1(dataRuralUrbanSettlementArr1);

                        // формируем объект для тела запроса данных
                        setRequestObjectPopulatedPoint1({ "address_type": 2, "path": `${item.path}` });

                        // очищаем поля параметров запрсов вниз по иерархии
                        setRequestObjectStrit1({});
                        setRequestObjectHouseNumber1({});
                        setRequestObjectApartmentNumber1({});
                        // ======================

                        // очищаем поля вниз по иерархии
                        setPopulatedPointArr1([]);
                        setPopPoint1('');
                        // ======================
                        setStritArr1([]);
                        setStrit1('');
                        // ======================
                        setHouseNumberArr1([]);
                        setHouseNumber1('');
                        // ======================
                        setApartmentNumberArr1([]);
                        setApartmentNumber1('');
                        // ======================

                        removeFocuse();
                      }}>
                      {item.hierarchy ? item.hierarchy[2].full_name_short : 'нет данных'}
                    </li>
                  )
                }
              </ul>
            </div>
          </div> : null
      }

      {/* инпут населенного пункта г.о. или м.р-н */}
      <div className={`${st.wrap_title} ${st.mb30}`}>
        <p className={popPoint1 === '' ? st.wrap_title_p_hide : st.wrap_title_p}>
          Выбор населенного пункта м.р-н</p>

        <div className={st.input_search_wrap}>
          <input
            className={st.input_search}
            type='text'
            name='popPoint1'
            value={popPoint1}
            onClick={() => {
              // отправляем запрос на получение данных этого поля и вставляем полученное в отображающийся список
              if (Object.keys(requestObjectPopulatedPoint1).length)
                fetchGetDataFias(requestObjectPopulatedPoint1, setDataPopulatedPointArr1, setPopulatedPointArr1)
            }}
            onChange={
              (e) => {
                // запрет ввода нетого
                handleInputChangeStop(e, patternNameCity, setPopPoint1);
                // поиск по списку данных
                searchInArrayElement(e, dataPopulatedPointArr1, indexСhange(typeOfDistrictDinamic, 2, 3), setPopulatedPointArr1);
                // очистка поля для отправки
                setPopPointToSend1('');
              }
            }
            placeholder='Введите название населенного пункта'
          />

          <div
            className={st.input_reset}
            key={uuidv4()}
            onMouseDown={() => {
              // очищаем текущие поля инпута и отпраки
              setPopPoint1('');
              setPopPointToSend1('');

              // восстанавливаем список данных после поиска
              setPopulatedPointArr1(dataPopulatedPointArr1);

              // очищаем поля параметров запрсов вниз по иерархии
              setRequestObjectStrit1({});
              setRequestObjectHouseNumber1({});
              setRequestObjectApartmentNumber1({});
              // ======================

              // очищаем поля вниз по иерархии
              setStritArr1([]);
              setStrit1('');
              // ======================
              setHouseNumberArr1([]);
              setHouseNumber1('');
              // ======================
              setApartmentNumberArr1([]);
              setApartmentNumber1('');
              // ======================
              setFiasDataObj1({});
              // ======================

              // очистка всех guids кроме верхних по иерархии
              setGuids1(prevState => (
                {
                  ...prevState,
                  guid23populatedpoint: '',
                  guid34street: '',
                  guid45housenumber: '',
                  guid56apartmentnumber: ''
                }
              ));
            }}>
            Сброс ввода
          </div>

          <ul className={st.list_search}>
            {
              populatedPointArr1.map(item =>
                <li
                  className={st.item_search}
                  key={uuidv4()}
                  onMouseDown={(e) => {
                    // получаем выбранное значение в инпуте и в поле для отправки
                    setElem(e, setPopPoint1);
                    setElem(e, setPopPointToSend1);

                    // помещаем сюда данные для отправки
                    setFiasDataObj1(item);

                    // GUIDS
                    // предварительная очистка нижних по иерархии
                    setGuids1(prevState => (
                      {
                        ...prevState,
                        guid34street: '',
                        guid45housenumber: '',
                        guid56apartmentnumber: ''
                      }
                    ));

                    // GUIDS
                    // помещаем item.object_guid в новый массив guids
                    setGuids1(prevState => ({ ...prevState, guid23populatedpoint: item.object_guid }));

                    // восстанавливаем список данных после поиска
                    setPopulatedPointArr1(dataPopulatedPointArr1);

                    // формируем объект для тела запроса данных
                    setRequestObjectStrit1({ "address_type": 2, "path": `${item.path}` });

                    // очищаем поля параметров запрсов вниз по иерархии
                    setRequestObjectHouseNumber1({});
                    setRequestObjectApartmentNumber1({});
                    // ======================

                    // очищаем поля вниз по иерархии
                    setStritArr1([]);
                    setStrit1('');
                    // ======================
                    setHouseNumberArr1([]);
                    setHouseNumber1('');
                    // ======================
                    setApartmentNumberArr1([]);
                    setApartmentNumber1('');
                    // ======================

                    removeFocuse();
                  }}>
                  {
                    operationIdMunicipalAreas1 === 0 ?
                      item.hierarchy[2] ? item.hierarchy[2].full_name_short : 'нет данных'
                      :
                      item.hierarchy[3] ? item.hierarchy[3].full_name_short : 'нет данных'
                  }
                </li>
              )
            }
          </ul>
        </div>
      </div>

      {/* инпут улица */}
      <div className={`${st.wrap_title} ${st.mb30}`}>
        <p className={strit1 === '' ? st.wrap_title_p_hide : st.wrap_title_p}>
          Выбор улицы</p>

        <div className={st.input_search_wrap}>
          <input
            className={st.input_search}
            type='text'
            name='strit1'
            value={strit1}
            onClick={() => {
              // отправляем запрос на получение данных этого поля и вставляем полученное в отображающийся список
              if (Object.keys(requestObjectStrit1).length)
                fetchGetDataFias(requestObjectStrit1, setDataStritArr1, setStritArr1)
            }}
            onChange={
              (e) => {
                // запрет ввода нетого
                handleInputChangeStop(e, patternNameCity, setStrit1);
                // поиск по списку данных
                searchInArrayElement(e, dataStritArr1, indexСhange(typeOfDistrictDinamic, 3, 4), setStritArr1);
                // очистка поля для отправки
                setStritToSend1('');
              }
            }
            placeholder='Введите название улицы'
          />

          <div
            className={st.input_reset}
            key={uuidv4()}
            onMouseDown={() => {
              // очищаем текущие поля инпута и отпраки
              setStrit1('');
              setStritToSend1('');

              // восстанавливаем список данных после поиска
              setStritArr1(dataStritArr1);

              // очищаем поля параметров запрсов вниз по иерархии
              setRequestObjectHouseNumber1({});
              setRequestObjectApartmentNumber1({});
              // ======================

              // очищаем поля вниз по иерархии
              setHouseNumberArr1([]);
              setHouseNumber1('');
              // ======================
              setApartmentNumberArr1([]);
              setApartmentNumber1('');
              // ======================
              setFiasDataObj1({});
              // ======================

              // очистка всех guids кроме верхних по иерархии
              setGuids1(prevState => (
                {
                  ...prevState,
                  guid34street: '',
                  guid45housenumber: '',
                  guid56apartmentnumber: ''
                }
              ));
            }}>
            Сброс ввода
          </div>

          <ul className={st.list_search}>
            {
              stritArr1.map(item =>
                <li
                  className={st.item_search}
                  key={uuidv4()}
                  onMouseDown={(e) => {
                    // получаем выбранное значение в инпуте и в поле для отправки
                    setElem(e, setStrit1);
                    setElem(e, setStritToSend1);

                    // помещаем сюда данные для отправки
                    setFiasDataObj1(item);

                    // GUIDS
                    // предварительная очистка нижних по иерархии
                    setGuids1(prevState => (
                      {
                        ...prevState,
                        guid45housenumber: '',
                        guid56apartmentnumber: ''
                      }
                    ));

                    // GUIDS
                    // помещаем item.object_guid в новый массив guids
                    setGuids1(prevState => ({ ...prevState, guid34street: item.object_guid }));

                    // восстанавливаем список данных после поиска
                    setStritArr1(dataStritArr1);

                    // формируем объект для тела запроса данных
                    setRequestObjectHouseNumber1({ "address_levels": [10], "address_type": 2, "path": `${item.path}` });

                    // очищаем поля параметров запрсов вниз по иерархии
                    setRequestObjectApartmentNumber1({});
                    // ======================

                    // очищаем поля вниз по иерархии
                    setHouseNumberArr1([]);
                    setHouseNumber1('');
                    // ======================
                    setApartmentNumberArr1([]);
                    setApartmentNumber1('');
                    // ======================

                    removeFocuse();
                  }}>
                  {
                    operationIdMunicipalAreas1 === 0 ?
                      item.hierarchy[3] ? item.hierarchy[3].full_name_short : 'нет данных' // показываем при выборе г.о.
                      :
                      item.hierarchy[4] ? item.hierarchy[4].full_name_short : 'нет данных' // показываем при выборе м.р-н
                  }
                </li>
              )
            }
          </ul>
        </div>
      </div>

      {/* инпут номер дома (здание (строение), сооружение) */}
      <div className={`${st.wrap_title} ${st.mb30}`}>
        <p className={houseNumber1 === '' ? st.wrap_title_p_hide : st.wrap_title_p}>
          Выбор номер дома</p>

        <div className={st.input_search_wrap}>
          <input
            className={st.input_search}
            type='text'
            name='houseNumber1'
            value={houseNumber1}
            onClick={() => {
              // отправляем запрос на получение данных этого поля и вставляем полученное в отображающийся список
              if (Object.keys(requestObjectHouseNumber1).length)
                fetchGetDataFias(requestObjectHouseNumber1, setDataHouseNumberArr1, setHouseNumberArr1)
            }}

            onChange={(e) => {
              // запрет ввода нетого
              handleInputChangeStop(e, patternPostalIndex, setHouseNumber1);
              // поиск по списку данных
              searchInArrayElement(e, dataHouseNumberArr1, indexСhange(typeOfDistrictDinamic, 4, 5), setHouseNumberArr1);
              // очистка поля для отправки
              setHouseNumberToSend1('');
            }}
            placeholder='Введите номер дома'
          />

          <div
            className={st.input_reset}
            key={uuidv4()}
            onMouseDown={() => {
              // очищаем текущие поля инпута и отправки
              setHouseNumber1('');
              setHouseNumberToSend1('');

              // восстанавливаем список данных после поиска
              setHouseNumberArr1(dataHouseNumberArr1);

              // очищаем поля параметров запрсов вниз по иерархии
              setRequestObjectApartmentNumber1({});

              // очищаем поля вниз по иерархии
              setApartmentNumberArr1([]);
              setApartmentNumber1('');
              // ======================
              setFiasDataObj1({});
              // ======================

              // очистка всех guids кроме верхних по иерархии
              setGuids1(prevState => (
                {
                  ...prevState,
                  guid45housenumber: '',
                  guid56apartmentnumber: ''
                }
              ));
            }}>
            Сброс ввода
          </div>

          <ul className={st.list_search}>
            {
              houseNumberArr1.map(item =>
                <li
                  className={st.item_search}
                  key={uuidv4()}
                  onMouseDown={(e) => {
                    // получаем выбранное значение в инпуте и в поле для отправки
                    setElem(e, setHouseNumber1);
                    setElem(e, setHouseNumberToSend1);

                    // помещаем сюда данные для отправки
                    setFiasDataObj1(item);

                    // GUIDS
                    // предварительная очистка нижних по иерархии
                    setGuids1(prevState => (
                      {
                        ...prevState,
                        guid56apartmentnumber: ''
                      }
                    ));

                    // GUIDS
                    // помещаем item.object_guid в новый массив guids
                    setGuids1(prevState => ({ ...prevState, guid45housenumber: item.object_guid }));

                    // восстанавливаем список данных после поиска
                    setHouseNumberArr1(dataHouseNumberArr1);

                    // формируем объект для тела запроса данных
                    setRequestObjectApartmentNumber1({ "address_type": 2, "path": `${item.path}` });

                    // очищаем поля вниз по иерархии
                    setApartmentNumberArr1([]);
                    setApartmentNumber1('');
                    // ======================

                    removeFocuse();
                  }}>
                  {
                    operationIdMunicipalAreas1 === 0 ?
                      (item.hierarchy[4] ? item.hierarchy[4].full_name_short : 'нет данных') // показываем при выборе г.о.
                      :
                      (item.hierarchy[5] ? item.hierarchy[5].full_name_short : 'нет данных') // показываем при выборе м.р-н
                  }
                </li>
              )
            }
          </ul>
        </div>
      </div>

      {/* инпут помещения */}
      <div className={`${st.wrap_title}`}>
        <p className={apartmentNumber1 === '' ? st.wrap_title_p_hide : st.wrap_title_p}>
          Выбор помещения</p>

        <div className={st.input_search_wrap}>
          <input
            className={st.input_search}
            type='text'
            name='apartmentNumber1'
            value={apartmentNumber1}
            onClick={() => {
              // отправляем запрос на получение данных этого поля и вставляем полученное в отображающийся список
              if (Object.keys(requestObjectApartmentNumber1).length)
                fetchGetDataFias(requestObjectApartmentNumber1, setDataApartmentNumberArr1, setApartmentNumberArr1)
            }}
            onChange={(e) => {
              // запрет ввода нетого
              handleInputChangeStop(e, patternPostalIndex, setApartmentNumber1);
              // поиск по списку данных
              searchInArrayElement(e, dataApartmentNumberArr1, indexСhange(typeOfDistrictDinamic, 5, 6), setApartmentNumberArr1);
              // очистка поля для отправки
              setApartmentNumberToSend1('');
            }}
            placeholder='Введите номер помещения'
          />

          <div
            className={st.input_reset}
            key={uuidv4()}
            onMouseDown={() => {
              // очищаем текущие поля инпута и отправки
              setApartmentNumber1('');
              setApartmentNumberToSend1('');

              // восстанавливаем список данных после поиска
              setApartmentNumberArr1(dataApartmentNumberArr1);

              // очищаем поля параметров запрсов вниз по иерархии
              // setRequestObjectНизшийУровеньОтсутствует({});

              // очищаем поля вниз по иерархии
              // setНизшийУровеньОтсутствуетArr1([]);
              // setНизшийУровеньОтсутствует1('');
              // ======================

              // очистка всех guids кроме верхних по иерархии
              setGuids1(prevState => (
                {
                  ...prevState,
                  guid56apartmentnumber: ''
                }
              ));
            }}>
            Сброс ввода
          </div>

          <ul className={st.list_search}>
            {
              apartmentNumberArr1.length ? apartmentNumberArr1.map(item =>
                <li
                  className={st.item_search}
                  key={uuidv4()}
                  onMouseDown={(e) => {
                    // получаем выбранное значение в инпуте и в поле для отправки
                    setElem(e, setApartmentNumber1);
                    setElem(e, setApartmentNumberToSend1);

                    // помещаем сюда данные для отправки
                    setFiasDataObj1(item);

                    // GUIDS
                    // помещаем item.object_guid в новый массив guids
                    setGuids1(prevState => ({ ...prevState, guid56apartmentnumber: item.object_guid }));

                    // восстанавливаем список данных после поиска
                    setApartmentNumberArr1(dataApartmentNumberArr1);

                    // формируем объект для тела запроса данных
                    // формировать объект для тела запроса данных не нужно т.к. следущего запроса не будет

                    // очищаем поля вниз по иерархии
                    // setНизшийУровеньОтсутствуетArr1([]);
                    // setНизшийУровеньОтсутствует1('');
                    // ======================

                    removeFocuse();
                  }}>
                  {
                    operationIdMunicipalAreas1 === 0 ?
                      item.hierarchy[5] ? item.hierarchy[5].full_name_short : 'нет данных' // показываем при выборе г.о.
                      :
                      item.hierarchy[6] ? item.hierarchy[6].full_name_short : 'нет данных' // показываем при выборе м.р-н
                  }
                </li>
              ) : <li className={st.item_search} key={uuidv4()}>Помещений нет</li>
            }
          </ul>
        </div>
      </div>
    </div>
  )
};
