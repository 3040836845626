import st from './ClBaseAdd.module.css';
import { ReactComponent as BtnClose } from '../../../../../../img/btn_close.svg';
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { fetchClBaseAdd } from '../../fetches/fetchAdd.js';
import { fetchClientbaseList } from 'src/store/clientbase/getClientbaseList';
import hashPassword from 'src/utils/hashPassword';
import handleInputChange from 'src/utils/handleInputChange';
import passwordShowHide from 'src/utils/passwordShowHide';
import phoneNumberMask from 'src/utils/phoneNumberMask';
import { Fias1 } from 'src/modules/FIAS/Fias1/Fias1';
import { Fias2 } from 'src/modules/FIAS/Fias2/Fias2';

export const ClBaseAdd = ({ closeModal, organizationList, status }) => {
  const dispatch = useDispatch();

  // поля ввода "Добавление клиента"
  const [clTitle, setСlTitle] = useState('');
  const [clName, setСlName] = useState('');
  const [clPhone, setСlPhone] = useState('');
  const [clMail, setСlMail] = useState('');
  const [regPassword, setRegPassword] = useState('');
  const [regPassword2, setRegPassword2] = useState('');

  // уведомления о неправильном вводе телефона и майла
  const [showNotePhone, setShowNotePhone] = useState('');
  const [showNoteMail, setShowNoteMail] = useState('');

  // уведомления верности пароля
  const [showNotePass, setShowNotePass] = useState('');
  const [showNotePass2, setShowNotePass2] = useState('');

  // скрываем показываем пароль
  const [passwordVisibility, setPasswordVisibility] = useState('password');

  // поля ввода "Реквизиты клиента"
  const [clFullTitle, setClbaseFullTitle] = useState('');
  const [clShortTitle, setСlbaseShortTitle] = useState('');
  const [clInn, setСlbaseInn] = useState('');
  const [clOgrn, setClOgrn] = useState('');
  const [clKpp, setСlbaseKpp] = useState('');
  // const [clUrAddress, setСlbaseUrAddress] = useState('');
  // const [clFactAddress, setClbaseFactAddress] = useState('');
  const [clGenDir, setClbaseGenDir] = useState('');
  const [clBook, setClbaseBook] = useState('');

  // паттерны валидации
  // const patternNameCompany = /^((?!\s{2}).){3,28}$/;
  // const patternINN = /^(([0-9]{12})|([0-9]{10}))?$/;
  // const patternFIO = /^[А-ЯA-Z][а-яa-zА-ЯA-Z-]{0,}\s[А-ЯA-Z][а-яa-zА-ЯA-Z-]{1,}(\s[А-ЯA-Z][а-яa-zА-ЯA-Z-]{1,})?$/;
  // const patternMail = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
  const patternPassword = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[ -/:-@[-`{-~]).{8,64}$/;
  // const patternPostalIndex = /^(\d){0,8}$/g;
  // const patternNameCity = /^[а-яА-Я- .]*$/;

  // поля ввода "Банковские реквизиты клиента"
  const [clBankAccount, setClbaseBankAccount] = useState('');
  const [clCorrAccount, setСlbaseCorrAccount] = useState('');
  const [clBank, setСlbaseBank] = useState('');
  const [clBIK, setСlbaseBIK] = useState('');

  // показываем модалки по очереди -"Добавление клиента", "Реквизиты клиента", "Банковские реквизиты клиента"
  const [fieldset1, setFieldset1] = useState(true);
  const [fieldset2, setFieldset2] = useState(false);
  const [fieldset3, setFieldset3] = useState(false);

  // для определения максимального порядкового номена и определения следующего
  const [nextOrder, setNextOrder] = useState(0);


  // ==============================================
  // это объект данных ФИАС юрадрес для отправки на сервер он начинается с "населенного пункта" 
  // и дальше вниз только обновляется (кажды раз добавляется одна иерархия)
  const [fiasDataObj1, setFiasDataObj1] = useState({});
  // console.log('fiasDataObj1: ', fiasDataObj1);

  // это объект данных ФИАС фактадрес для отправки на сервер он начинается с "населенного пункта" 
  // и дальше вниз только обновляется (кажды раз добавляется одна иерархия)
  const [fiasDataObj2, setFiasDataObj2] = useState({});
  // console.log('fiasDataObj2: ', fiasDataObj2);
  // ==============================================


  // при перезагрузке или открытии прямой ссылки чтобы исключить ошибку undefined
  useEffect(() => {
    if (status === 'success' && organizationList && organizationList.length) {
      const maxOrder = Array.from(organizationList).reduce((previous, current) => {
        if (+current.id > +previous.id) {
          return current;
        } else {
          return previous;
        }
      });
      setNextOrder(maxOrder.id + 1);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationList]);

  // данные для body запроса на добавление новой организации через панель администратора
  const dataBodyAddOrg = {
    'organization': {
      'uuid': '', // при добавлении новой организации не заполняется
      'id': nextOrder, // порядковый номер организации присваивается автоматически при добавлении

      'name': clTitle,
      'respusername': clName,
      'phone': `7${clPhone.replace(/\D/g, '')}`,
      'email': clMail,
      'status': 'VERIFIED',

      // Реквизиты клиента
      'fullname': clFullTitle,
      'shortname': clShortTitle,
      'inn': clInn, // инн организации
      'serial': clOgrn, // clOgrn ОГРН организации (поле есть, но ТЗ не предусмотренно)
      'sclientfnsinn': clKpp, // кпп ("код причины постановки на учет") организации
      //'jaddress': clUrAddress, // юрадрес организации
      // 'faddress': clFactAddress, // фактадрес организации
      'jaddressextfias': fiasDataObj1,
      'faddressextfias': fiasDataObj2,
      'directorname': clGenDir, // генеральный директор организации
      'bookername': clBook, // бухгалтер организации
    },
    'bank': {
      'id': '', // id организации когда заполняется не определено ПОД ВОПРОСОМ???
      // Банковские реквизиты клиента
      'accountnumber': clBankAccount, // расчетный. счет
      'bankaccountnumber': clCorrAccount, // корреспондентский счет
      'bankname': clBank, // наименование банка
      'bankid': clBIK, // БИК банка
    }
  };

  // тело запроса для получения shc
  const bodySHC = {
    'login': clMail
  }

  // данные для body запроса на добавление пароля для новой организации
  const dataBodyAddPass = {
    'loginName': clMail,
    'oldpassHash': '',
    'passHash': hashPassword(regPassword),
    'smscode': ''
  };

  // отправляет форму
  const handleAddNewOrg = async (e) => {
    e.preventDefault();

    await fetchClBaseAdd(dataBodyAddOrg, bodySHC, dataBodyAddPass);

    // console.log('dataBodyAddPass: ', dataBodyAddPass);
    // console.log('bodySHC: ', bodySHC);

    dispatch(fetchClientbaseList());

    closeModal();

    // console.log('dataBodyAddOrg: ', dataBodyAddOrg);

    console.log('форма отправлена');
  };

  // меняет значение в инпуте телефона на отформатированное
  const phoneNumberFormatter = (e) => {
    const formattedTargetValue = phoneNumberMask(e.target.value);

    e.target.value = formattedTargetValue;

    setСlPhone(e.target.value);

    if (e.target.value.length === 13) {
      setShowNotePhone(true);
    } else {
      setShowNotePhone(false);
    }
  };

  return (
    <div className={st.clientsadd_wrap}>
      <button className={st.btn_close} onClick={closeModal}>
        <BtnClose />
      </button>

      <div className={st.clientsadd}>
        <form className={st.form}>
          {/* окно добавление клиента */}
          <fieldset className={fieldset1 ? st.fieldset1 : st.hidden}>
            <div className={st.title_wrap}>
              <h3 className={st.title}>Добавление клиента</h3>
              <div className={st.title_img1}></div>
            </div>

            {/* инпут ввода наименования клиента */}
            <div className={st.wrap_title}>
              <p className={clTitle !== '' ? st.wrap_title_p : st.wrap_title_p_hide}>
                Наименование</p>

              <div className={st.input_wrap}>
                <label className={st.label_title}>
                  <input
                    className={st.input_title}
                    type='text'
                    name='clTitle'
                    value={clTitle}
                    placeholder='Наименование'
                    onChange={(e) => setСlTitle(e.target.value)}
                  />
                </label>
              </div>
            </div>

            {/* инпут ввода ФИО ответственного лица клиента */}
            <div className={st.wrap_title}>
              <p className={clName !== '' ? st.wrap_title_p : st.wrap_title_p_hide}>ФИО</p>

              <div className={st.input_wrap}>
                <label className={st.label_title}>
                  <input
                    className={st.input_title}
                    type='text'
                    name='clName'
                    value={clName}
                    placeholder='ФИО'
                    onChange={(e) => setСlName(e.target.value)}
                  />
                </label>
              </div>
            </div>

            {/* инпут ввода телефона нового клиента */}
            <div className={st.wrap_title}>
              <p className={clPhone !== '' ? st.wrap_title_p : st.wrap_title_p_hide}>
                Телефон</p>

              {/* <div className={st.input_wrap}> */}
              <div className={st.note_wrap}>
                <p className={
                  showNotePhone === '' ? st.note_empty : showNotePhone ? st.note_hide : st.note_mail
                }>Введите корректный номер телефона</p>

                <label className={st.label}>
                  <p className={st.select}>+7</p>
                  <input
                    className={st.input_title}
                    type='text'
                    name='clPhone'
                    value={clPhone}
                    placeholder='Телефон'
                    // onChange={(e) => setСlPhone(e.target.value)}
                    onChange={(e) => phoneNumberFormatter(e)}
                  />
                </label>
              </div>
            </div>

            {/* инпут ввода e-mail нового клиента */}
            <div className={st.wrap_title}>
              <p className={clMail !== '' ? st.wrap_title_p : st.wrap_title_p_hide}>
                E-mail</p>

              <div className={st.input_wrap}>
                <label className={st.label_title}>
                  <input
                    className={st.input_title}
                    type='text'
                    name='clMail'
                    value={clMail}
                    placeholder='E-mail'
                    onChange={(e) => setСlMail(e.target.value)}
                  />
                </label>
              </div>
            </div>

            {/* инпут ввода пароля */}
            <div className={st.note_wrap}>
              <p className={showNotePass === '' ? st.note_empty : showNotePass ? st.note_hide : st.note_pass}>
                Пароль должен содержать не менее 8 символов и состоять из больших латинских букв, маленьких латинских букв, цифр и символов
              </p>

              <div className={st.input_wrap_pass}>
                <input
                  className={st.input}
                  type={passwordVisibility}
                  name='regpassword'
                  value={regPassword}
                  onChange={(e) => handleInputChange(e, patternPassword, setShowNotePass, setRegPassword)}
                  // onChange={(e) => setRegPassword(e.target.value)}
                  placeholder='Пароль'
                />
                <button
                  className={passwordVisibility === 'password' ? st.input_btn_closed : st.input_btn_open}
                  onClick={(e) => passwordShowHide(e, passwordVisibility, setPasswordVisibility)}
                ></button>
              </div>
            </div>

            {/* инпут повторного ввода пароля */}
            <div className={st.note_wrap}>
              <p className={showNotePass2 === '' ? st.note_empty : regPassword === regPassword2 ? st.note_hide : st.note_mail}>
                Пароли не совпадают
              </p>

              <input
                className={st.input}
                type={passwordVisibility}
                name='regpassword2'
                value={regPassword2}
                onChange={(e) => handleInputChange(e, patternPassword, setShowNotePass2, setRegPassword2)}
                placeholder='Повторный ввод пароля'
              />
            </div>

            {/* кнопка "далее" */}
            <button
              className={st.btn_change}
              onClick={(e) => {
                e.preventDefault();
                setFieldset1(false);
                setFieldset2(true);
              }}
            // disabled={
            //   clTitle === '' ||
            //     clName === '' ||
            //     clPhone === '' ||
            //     // showNotePass === '' ||
            //     // showNotePass2 === '' ||
            //     // !showNotePass ||
            //     // !showNotePass2 ||
            //     clMail === '' ? true : false
            // }
            >Далее</button>
          </fieldset>

          {/* окно реквизиты клиента */}
          <fieldset className={fieldset2 ? st.fieldset2 : st.hidden}>
            <div className={st.title_wrap}>
              <h3 className={st.title}>Реквизиты клиента</h3>
              <div className={st.title_img2}></div>
            </div>

            {/* инпут ввода полного наименования клиента */}
            <div className={st.wrap_title}>
              <p className={clFullTitle !== '' ? st.wrap_title_p : st.wrap_title_p_hide}>
                Полное наименование</p>

              <div className={st.input_wrap}>
                <label className={st.label_title}>
                  <input
                    className={st.input_title}
                    type='text'
                    name='clFullTitle'
                    value={clFullTitle}
                    placeholder='Полное наименование'
                    onChange={(e) => setClbaseFullTitle(e.target.value)}
                  />
                </label>
              </div>
            </div>

            {/* инпут ввода краткого наименования клиента */}
            <div className={st.wrap_title}>
              <p className={clShortTitle !== '' ? st.wrap_title_p : st.wrap_title_p_hide}>
                Краткое наименование</p>

              <div className={st.input_wrap}>
                <label className={st.label_title}>
                  <input
                    className={st.input_title}
                    type='text'
                    name='clShortTitle'
                    value={clShortTitle}
                    placeholder='Краткое наименование'
                    onChange={(e) => setСlbaseShortTitle(e.target.value)}
                  />
                </label>
              </div>
            </div>

            {/* инпут ввода инн нового клиента */}
            <div className={st.wrap_title}>
              <p className={clInn !== '' ? st.wrap_title_p : st.wrap_title_p_hide}>
                ИНН</p>

              <div className={st.input_wrap}>
                <label className={st.label_title}>
                  <input
                    className={st.input_title}
                    type='text'
                    name='clInn'
                    value={clInn}
                    placeholder='ИНН'
                    onChange={(e) => setСlbaseInn(e.target.value)}
                  />
                </label>
              </div>
            </div>

            {/* инпут ввода "огрн" нового клиента */}
            <div className={st.wrap_title}>
              <p className={clOgrn !== '' ? st.wrap_title_p : st.wrap_title_p_hide}>
                ОГРН</p>

              <div className={st.input_wrap}>
                <label className={st.label_title}>
                  <input
                    className={st.input_title}
                    type='text'
                    name='clOgrn'
                    value={clOgrn}
                    placeholder='ОГРН'
                    onChange={(e) => setClOgrn(e.target.value)}
                  />
                </label>
              </div>
            </div>

            {/* инпут ввода кпп нового клиента */}
            <div className={st.wrap_title}>
              <p className={clKpp !== '' ? st.wrap_title_p : st.wrap_title_p_hide}>
                КПП</p>

              <div className={st.input_wrap}>
                <label className={st.label_title}>
                  <input
                    className={st.input_title}
                    type='text'
                    name='clKpp'
                    value={clKpp}
                    placeholder='КПП'
                    onChange={(e) => setСlbaseKpp(e.target.value)}
                  />
                </label>
              </div>
            </div>

            {/* юр. адрес */}
            <div className={st.wrap_title}>
              <div className={st.wrap_title}>
                <h6 className={st.wrap_title_h6}>Юридический адрес:</h6>
              </div>

              <Fias1 setFiasDataObj1={setFiasDataObj1} />
            </div>

            {/* факт. адрес */}
            <div className={st.wrap_title}>
              <div className={st.wrap_title}>
                <h6 className={st.wrap_title_h6}>Фактический адрес:</h6>
              </div>

              <Fias2 setFiasDataObj2={setFiasDataObj2} />
            </div>

            {/* инпут ввода генерального директора нового клиента */}
            <div className={st.wrap_title}>
              <p className={clGenDir !== '' ? st.wrap_title_p : st.wrap_title_p_hide}>
                Генеральный директор</p>

              <div className={st.input_wrap}>
                <label className={st.label_title}>
                  <input
                    className={st.input_title}
                    type='text'
                    name='clGenDir'
                    value={clGenDir}
                    placeholder='Генеральный директор'
                    onChange={(e) => setClbaseGenDir(e.target.value)}
                  />
                </label>
              </div>
            </div>

            {/* инпут ввода главного бухгалтера нового клиента */}
            <div className={st.wrap_title}>
              <p className={clBook !== '' ? st.wrap_title_p : st.wrap_title_p_hide}>
                Главный бухгалтер</p>

              <div className={st.input_wrap}>
                <label className={st.label_title}>
                  <input
                    className={st.input_title}
                    type='text'
                    name='clBook'
                    value={clBook}
                    placeholder='Главный бухгалтер'
                    onChange={(e) => setClbaseBook(e.target.value)}
                  />
                </label>
              </div>
            </div>

            {/* кнопка "далее" */}
            <div className={st.btn_change_wrap}>
              <div
                className={st.btn_back}
                onClick={() => {
                  setFieldset2(false);
                  setFieldset1(true);
                }}
              ></div>

              <button
                className={st.btn_change}
                onClick={(e) => {
                  e.preventDefault();
                  setFieldset2(false);
                  setFieldset3(true);
                }}
              // disabled={
              //   clFullTitle === '' ||
              //     clShortTitle === '' ||
              //     clInn === '' ||
              //     clKpp === '' ||
              //     // clUrAddress === '' ||
              //     clFactAddress === '' ||
              //     clGenDir === '' ||
              //     clBook === '' ? true : false
              // }
              >Далее</button>
            </div>
          </fieldset>

          {/* окно банковские реквизиты клиента */}
          <fieldset className={fieldset3 ? st.fieldset3 : st.hidden}>
            <div className={st.title_wrap}>
              <h3 className={st.title}>Банковские реквизиты клиента</h3>
              <div className={st.title_img3}></div>
            </div>

            {/* инпут ввода банковского счета клиента */}
            <div className={st.wrap_title}>
              <p className={clBankAccount !== '' ? st.wrap_title_p : st.wrap_title_p_hide}>
                Расчетный счет</p>

              <div className={st.input_wrap}>
                <label className={st.label_title}>
                  <input
                    className={st.input_title}
                    type='text'
                    name='clBankAccount'
                    value={clBankAccount}
                    placeholder='Расчетный счет'
                    onChange={(e) => setClbaseBankAccount(e.target.value)}
                  />
                </label>
              </div>
            </div>

            {/* инпут ввода корреспондентского счета клиента */}
            <div className={st.wrap_title}>
              <p className={clCorrAccount !== '' ? st.wrap_title_p : st.wrap_title_p_hide}>
                Корреспондентский счет</p>

              <div className={st.input_wrap}>
                <label className={st.label_title}>
                  <input
                    className={st.input_title}
                    type='text'
                    name='clCorrAccount'
                    value={clCorrAccount}
                    placeholder='Корреспондентский счет'
                    onChange={(e) => setСlbaseCorrAccount(e.target.value)}
                  />
                </label>
              </div>
            </div>

            {/* инпут ввода наименования Банка нового клиента */}
            <div className={st.wrap_title}>
              <p className={clBank !== '' ? st.wrap_title_p : st.wrap_title_p_hide}>
                Банк</p>

              <div className={st.input_wrap}>
                <label className={st.label_title}>
                  <input
                    className={st.input_title}
                    type='text'
                    name='clBank'
                    value={clBank}
                    placeholder='Банк'
                    onChange={(e) => setСlbaseBank(e.target.value)}
                  />
                </label>
              </div>
            </div>

            {/* инпут ввода e-mail нового клиента */}
            <div className={st.wrap_title}>
              <p className={clBIK !== '' ? st.wrap_title_p : st.wrap_title_p_hide}>
                БИК</p>

              <div className={st.input_wrap}>
                <label className={st.label_title}>
                  <input
                    className={st.input_title}
                    type='text'
                    name='clBIK'
                    value={clBIK}
                    placeholder='БИК'
                    onChange={(e) => setСlbaseBIK(e.target.value)}
                  />
                </label>
              </div>
            </div>

            <div className={st.btn_change_wrap}>
              <div
                className={st.btn_back}
                onClick={() => {
                  setFieldset3(false);
                  setFieldset2(true);
                }}
              ></div>

              <button
                className={st.btn_change}
                onClick={handleAddNewOrg}
                disabled={
                  clBankAccount === '' ||
                    clCorrAccount === '' ||
                    clBank === '' ||
                    clBIK === '' ? true : false
                }
              >Сохранить</button>
            </div>
          </fieldset>

        </form>
      </div>
    </div>
  )
};
