import st from './LkAddress.module.css';
import { useEffect, useState } from 'react';

export const LkAddress = ({
  closeModal,
  installationaddress,
}) => {
  // поля адреса
  const [fieldAddressRegion, setFieldAddressRegion] = useState('');
  const [fieldAddressCity, setFieldAddressCity] = useState('');
  const [fieldAddressStreet, setFieldAddressStreet] = useState('');
  const [fieldAddressHousenumber, setFieldAddressHousenumber] = useState('');
  const [fieldAddressEntrancenumber, setFieldAddressEntrancenumber] = useState('');


  useEffect(() => {
    // поля адреса
    setFieldAddressRegion(installationaddress.region);
    setFieldAddressCity(installationaddress.city);
    setFieldAddressStreet(installationaddress.street);
    setFieldAddressHousenumber(installationaddress.house);
    setFieldAddressEntrancenumber(installationaddress.entrance);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={st.clientsedit_wrap}>
      <button
        className={st.btn_close}
        onClick={closeModal}>
      </button>

      <div className={st.clientsedit}>
        <div className={st.form}>

          <div className={st.ft_edit_requisites}>
            <h3 className={st.edit_title}>Адрес установки</h3>

            {/* поле "регион установки" */}
            <div className={st.edit_input}>
              <div className={st.edit_field_wrap}>
                <p className={st.edit_field_title}>Регион</p>

                <p className={st.edit_field_text}>
                  {fieldAddressRegion}
                </p>
              </div>
            </div>

            {/* поле "город установки */}
            <div className={st.edit_input}>
              <div className={st.edit_field_wrap}>
                <p className={st.edit_field_title}>Город</p>

                <p className={st.edit_field_text}>
                  {fieldAddressCity}
                </p>
              </div>
            </div>

            {/* поле "улица установки */}
            <div className={st.edit_input}>
              <div className={st.edit_field_wrap}>
                <p className={st.edit_field_title}>Улица</p>

                <p className={st.edit_field_text}>
                  {fieldAddressStreet}
                </p>
              </div>
            </div>

            {/* поле "номер дома установки */}
            <div className={st.edit_input}>
              <div className={st.edit_field_wrap}>
                <p className={st.edit_field_title}>Номер дома</p>

                <p className={st.edit_field_text}>{fieldAddressHousenumber}</p>
              </div>
            </div>

            {/* поле "номер подъезда установки */}
            <div className={st.edit_input}>
              <div className={st.edit_field_wrap}>
                <p className={st.edit_field_title}>Номер подъезда</p>

                <p className={st.edit_field_text}>{fieldAddressEntrancenumber}</p>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  );
};
