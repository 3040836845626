import st from './ClBaseEdit.module.css';
import { ReactComponent as BtnClose } from '../../../../../../img/btn_close.svg';
import { ReactComponent as BtnRequisites } from '../../../../../../img/icon_requisites.svg';
import { ReactComponent as BtnRequisitesbank } from '../../../../../../img/icon_requisitesbank.svg';
import { useEffect, useState } from 'react';
import { fetchClBaseEdit } from '../../fetches/fetchEdit.js';
import { fetchClientbaseList } from 'src/store/clientbase/getClientbaseList';
import { useDispatch } from 'react-redux';
import phoneNumberMask from 'src/utils/phoneNumberMask';
import { Fias1 } from 'src/modules/FIAS/Fias1/Fias1';
import { Fias2 } from 'src/modules/FIAS/Fias2/Fias2';

export const ClBaseEdit = ({
  closeModal,

  id,
  uuid,

  nameorg,
  respusername,
  phone,
  email,
  statusorg,

  fullname,
  shortname,
  inn,
  serialOGRN,
  fnsinnKPP,
  // legaladdress,
  // physicaladdress,
  legaladdressextfias,
  physicaladdressextfias,
  directorname,
  bookername,

  accountnumber,
  bankaccountnumber,
  bankname,
  bankid,

  openClient,
  openRequisit,
  openRequisitBank,
}) => {
  const dispatch = useDispatch();
  // окно реквизиты - открытие - закрытие инпутов редактирования
  const [inputFullTitleShow, setInputFullTitleShow] = useState(false);
  const [inputShortTitleShow, setInputShortTitleShow] = useState(false);
  const [inputInnShow, setInputInnShow] = useState(false);
  const [inputOgrnShow, setInputOgrnShow] = useState(false);
  const [inputKppShow, setInputKppShow] = useState(false);
  const [inputUrAddressShow, setInputUrAddressShow] = useState(false);
  const [inputFactAddressShow, setInputFactAddressShow] = useState(false);
  const [inputGenDirShow, setInputGenDirShow] = useState(false);
  const [inputBookShow, setInputBookShow] = useState(false);

  // окно банковские реквизиты - открытие - закрытие инпутов редактирования
  const [inputBankAccountShow, setInputBankAccountShow] = useState(false);
  const [inputCorrAccountShow, setInputCorrAccountShow] = useState(false);
  const [inputBankShow, setInputBankShow] = useState(false);
  const [inputBIKShow, setInputBIKShow] = useState(false);

  // окно редактирования клиента
  const [clTitle, setClTitle] = useState('');
  const [clName, setСlName] = useState('');
  const [clPhone, setСlPhone] = useState('');
  const [clMail, setClMail] = useState('');
  const [clStatusOrg, setClStatusOrg] = useState('');

  // уведомления о неправильном вводе телефона и майла
  const [showNotePhone, setShowNotePhone] = useState('');
  const [showNoteMail, setShowNoteMail] = useState('');

  // окно редактирования реквизитов клиента
  const [clFullTitle, setClFullTitle] = useState('');
  const [clShortTitle, setClShortTitle] = useState('');
  const [clInn, setClInn] = useState('');
  const [clOgrn, setClOgrn] = useState('');
  const [clKpp, setClKpp] = useState('');
  const [clUrAddress, setClUrAddress] = useState('');
  const [clFactAddress, setClFactAddress] = useState('');
  const [clGenDir, setClGenDir] = useState('');
  const [clBook, setClBook] = useState('');

  // окно редактирования банковских реквизитов клиента
  const [clBankAccount, setClBankAccount] = useState('');
  const [clCorrAccount, setClCorrAccount] = useState('');
  const [clBank, setClBank] = useState('');
  const [clBIK, setClBIK] = useState('');

  // открывание закрывание окон редактирования
  const [openClientWin, setOpenClientWin] = useState(false);
  const [openRequisitWin, setOpenRequisitWin] = useState(false);
  const [openRequisitBankWin, setOpenRequisitBankWin] = useState(false);

  // ==============================================
  // это объект данных ФИАС юрадрес для отправки на сервер он начинается с "населенного пункта" 
  // и дальше вниз только обновляется (кажды раз добавляется одна иерархия)
  const [fiasDataObj1, setFiasDataObj1] = useState({});
  // console.log('fiasDataObj1: ', fiasDataObj1);

  // это объект данных ФИАС фактадрес для отправки на сервер он начинается с "населенного пункта" 
  // и дальше вниз только обновляется (кажды раз добавляется одна иерархия)
  const [fiasDataObj2, setFiasDataObj2] = useState({});
  // console.log('fiasDataObj2: ', fiasDataObj2);
  // ==============================================

  useEffect(() => {
    // редактирование клиента
    setClTitle(nameorg);
    setСlName(respusername);
    setСlPhone(phone.slice(1));
    setClMail(email);
    setClStatusOrg(statusorg);

    // редактирование реквизитов клиента
    setClFullTitle(fullname);
    setClShortTitle(shortname);
    setClInn(inn);
    setClOgrn(serialOGRN);
    setClKpp(fnsinnKPP);
    // setClUrAddress(legaladdress);
    // setClFactAddress(physicaladdress);
    setClUrAddress(legaladdressextfias);
    setClFactAddress(physicaladdressextfias);


    setClGenDir(directorname);
    setClBook(bookername);

    // редактирование банковских реквизитов клиента
    setClBankAccount(accountnumber ? accountnumber : '');
    setClCorrAccount(bankaccountnumber ? bankaccountnumber : '');
    setClBank(bankname ? bankname : '');
    setClBIK(bankid ? bankid : '');

    setOpenClientWin(openClient);
    setOpenRequisitWin(openRequisit)
    setOpenRequisitBankWin(openRequisitBank);
  }, [nameorg, respusername, phone, email, statusorg, fullname, shortname, inn, serialOGRN, fnsinnKPP, legaladdressextfias, physicaladdressextfias, directorname, bookername, accountnumber, bankaccountnumber, bankname, bankid, openClient, openRequisit, openRequisitBank]);

  // данные для отправки запроса на изменение данных организации через панель администратора
  const clBaseDataBody = {
    'organization': {
      'uuid': uuid,
      'id': id,

      'name': clTitle,
      'respusername': clName,
      'phone': `7${clPhone.replace(/\D/g, '')}`,
      'email': clMail,
      'status': clStatusOrg,

      'fullname': clFullTitle,
      'shortname': clShortTitle,
      'inn': clInn,
      'serial': clOgrn,
      'sclientfnsinn': clKpp,

      // 'jaddress': clUrAddress,
      // 'faddress': clFactAddress,

      // 'jaddressextfias': fiasDataObj1,
      'jaddressextfias': fiasDataObj1.hierarchy ? fiasDataObj1 : {},
      // 'faddressextfias': fiasDataObj2,
      'faddressextfias': fiasDataObj2.hierarchy ? fiasDataObj2 : {},

      'directorname': clGenDir,
      'bookername': clBook,
    },
    'bank': {
      'id': '',

      'accountnumber': clBankAccount,
      'bankaccountnumber': clCorrAccount,
      'bankname': clBank,
      'bankid': clBIK
    }
  };

  // отправляет форму
  const handleFormSubmit = async (e) => {
    e.preventDefault();

    await fetchClBaseEdit(clBaseDataBody);
    dispatch(fetchClientbaseList());

    closeModal();

    // console.log('clBaseDataBody: ', clBaseDataBody);
    console.log('форма отправлена');
  };

  // меняет значение в инпуте телефона на отформатированное
  const phoneNumberFormatter = (e) => {
    const formattedTargetValue = phoneNumberMask(e.target.value);

    e.target.value = formattedTargetValue;

    setСlPhone(e.target.value);

    if (e.target.value.length === 13) {
      setShowNotePhone(true);
    } else {
      setShowNotePhone(false);
    }
  };


  return (
    <div className={st.clientsedit_wrap}>

      {/* просто закрывает модалку */}
      <button className={openClientWin ? st.btn_close : st.hidden} onClick={closeModal}>
        <BtnClose />
      </button>

      {/* возвращает на предыдущее окно с кнопкой "сохранить" */}
      <button className={!openClientWin ? st.btn_close_back : st.hidden} onClick={() => {
        setOpenClientWin(true);
        setOpenRequisitWin(false);
        setOpenRequisitBankWin(false);
        // setBtnCloseBack(false);
      }}>
        <BtnClose />
      </button>

      <div className={st.clientsedit}>
        <form className={st.form}>

          <fieldset className={openClientWin ? st.ft_edit_client : st.hidden}>
            <h3 className={st.title}>Редактирование клиента</h3>

            {/* инпут редактирования наименования клиента */}
            <div className={st.wrap_title}>
              <p className={clTitle !== '' ? st.wrap_title_p : st.wrap_title_p_hide}>
                Наименование</p>

              <div className={st.fieldset_title}>
                <label className={st.label_title}>
                  <input
                    className={st.input_title}
                    type='text'
                    name='clTitle'
                    value={clTitle}
                    placeholder='Наименование'
                    onChange={(e) => setClTitle(e.target.value)}
                  />
                </label>
              </div>
            </div>

            {/* инпут редактирования ФИО ответственного лица выбранного клиента */}
            <div className={st.wrap_title}>
              <p className={clName !== '' ? st.wrap_title_p : st.wrap_title_p_hide}>ФИО</p>

              <div className={st.fieldset_title}>
                <label className={st.label_title}>
                  <input
                    className={st.input_title}
                    type='text'
                    name='clName'
                    value={clName}
                    placeholder='ФИО'
                    onChange={(e) => setСlName(e.target.value)}
                  />
                </label>
              </div>
            </div>

            {/* инпут редактирования телефона клиента */}
            <div className={st.wrap_title}>
              <p className={clPhone !== '' ? st.wrap_title_p : st.wrap_title_p_hide}>
                Телефон</p>

              <div className={st.note_wrap}>
                <p className={
                  showNotePhone === '' ? st.note_empty : showNotePhone ? st.note_hide : st.note_mail
                }>Введите корректный номер телефона</p>

                {/* <label className={st.label_title}> */}
                <label className={st.label}>
                  <p className={st.select}>+7</p>
                  <input
                    className={st.input_phone}
                    type='text'
                    name='clPhone'
                    value={clPhone}
                    placeholder='Телефон'
                    // onChange={(e) => setСlPhone(e.target.value)}
                    onChange={(e) => phoneNumberFormatter(e)}
                  />
                </label>
              </div>
            </div>

            {/* инпут редактирования e-mail клиента */}
            <div className={st.wrap_title}>
              <p className={clMail !== '' ? st.wrap_title_p : st.wrap_title_p_hide}>
                E-mail</p>

              <div className={st.fieldset_title}>
                <label className={st.label_title}>
                  <input
                    className={st.input_title}
                    type='text'
                    name='clMail'
                    value={clMail}
                    placeholder='E-mail'
                    onChange={(e) => setClMail(e.target.value)}
                  />
                </label>
              </div>
            </div>

            {/* инпут редактирования статуса организации */}
            <div className={st.wrap_title}>
              <p className={clStatusOrg !== '' ? st.wrap_title_p : st.wrap_title_p_hide}>
                Статус</p>

              <div className={st.fieldset_title}>
                <label className={st.label_title}>
                  <select
                    className={st.filter_select}
                    name='clStatusOrg'
                    value={clStatusOrg}
                    onChange={(e) => setClStatusOrg(e.target.value)}
                  >
                    <option value='VERIFIED'>Проверен</option>
                    <option value='NOTVERIFIED'>Саморегистрация</option>
                  </select>
                </label>
              </div>
            </div>

            <div className={st.btns_requisites}>
              <button
                className={st.button_requisites}
                onClick={(e) => {
                  e.preventDefault();
                  setOpenClientWin(false);
                  setOpenRequisitWin(true);
                  // setBtnCloseBack(true);
                }}>
                <BtnRequisites />
                Реквизиты
              </button>

              <button
                className={st.button_requisites}
                onClick={(e) => {
                  e.preventDefault();
                  setOpenClientWin(false);
                  setOpenRequisitBankWin(true);
                  // setBtnCloseBack(true);
                }}>
                <BtnRequisitesbank />
                Банковские&nbsp;реквизиты</button>
            </div>

            <button
              className={st.btn_submit}
              onClick={handleFormSubmit}
              disabled={
                clTitle === '' ? true : false
                // clName === '' ||
                // clPhone === '' ||
                // clMail === '' ? true : false
              }
            >Сохранить</button>
          </fieldset>

          {/* реквизиты */}
          <fieldset className={openRequisitWin ? st.ft_edit_requisites : st.hidden}>
            <h3 className={st.edit_title}>Реквизиты</h3>

            {/* инпут редактирования полного наименования клиента */}
            <div className={st.edit_input}>
              <div className={st.edit_field_wrap}>
                <p className={st.edit_field_title}>Полное наименование</p>

                <p className={!inputFullTitleShow ? st.edit_field_text : st.edit_field_text_hidden}>
                  {clFullTitle}</p>

                <div className={st.wrap_p}>
                  <p
                    className={!inputFullTitleShow ? st.edit_field_btn : st.edit_field_btn_hide}
                    onClick={() => setInputFullTitleShow(!inputFullTitleShow)}>Изменить</p>

                  <p
                    className={inputFullTitleShow ? st.save_field_btn : st.save_field_btn_hide}
                    onClick={() => setInputFullTitleShow(false)}>Сохранить</p>
                </div>
              </div>

              <input
                className={inputFullTitleShow ? st.edit_entry : st.edit_entry_hidden}
                type='text'
                name='clFullTitle'
                value={clFullTitle}
                placeholder='Полное наименование'
                onChange={(e) => setClFullTitle(e.target.value)}
              />
            </div>

            {/* инпут редактирования краткого наименования клиента */}
            <div className={st.edit_input}>
              <div className={st.edit_field_wrap}>
                <p className={st.edit_field_title}>Краткое наименование</p>

                <p className={!inputShortTitleShow ?
                  st.edit_field_text :
                  st.edit_field_text_hidden}>{clShortTitle}</p>

                <div className={st.wrap_p}>
                  <p
                    className={!inputShortTitleShow ? st.edit_field_btn : st.edit_field_btn_hide}
                    onClick={() => setInputShortTitleShow(!inputShortTitleShow)}>Изменить</p>

                  <p
                    className={inputShortTitleShow ? st.save_field_btn : st.save_field_btn_hide}
                    onClick={() => setInputShortTitleShow(false)}>Сохранить</p>
                </div>
              </div>

              <input
                className={inputShortTitleShow ? st.edit_entry : st.edit_entry_hidden}
                type='text'
                name='clShortTitle'
                value={clShortTitle}
                placeholder='Краткое наименование'
                onChange={(e) => setClShortTitle(e.target.value)}
              />
            </div>

            {/* инпут редактирования ИНН клиента */}
            <div className={st.edit_input}>
              <div className={st.edit_field_wrap}>
                <p className={st.edit_field_title}>ИНН</p>

                <p
                  className={!inputInnShow ? st.edit_field_text : st.edit_field_text_hidden}>
                  {clInn}</p>

                <div className={st.wrap_p}>
                  <p
                    className={!inputInnShow ? st.edit_field_btn : st.edit_field_btn_hide}
                    onClick={() => setInputInnShow(!inputInnShow)}>Изменить</p>

                  <p
                    className={inputInnShow ? st.save_field_btn : st.save_field_btn_hide}
                    onClick={() => setInputInnShow(false)}>Сохранить</p>
                </div>
              </div>

              <input
                className={inputInnShow ? st.edit_entry : st.edit_entry_hidden}
                type='text'
                name='clInn'
                value={clInn}
                placeholder='ИНН'
                onChange={(e) => setClInn(e.target.value)}
              />
            </div>

            {/* инпут редактирования ОГРН клиента */}
            <div className={st.edit_input}>
              <div className={st.edit_field_wrap}>
                <p className={st.edit_field_title}>ОГРН</p>

                <p
                  className={!inputOgrnShow ? st.edit_field_text : st.edit_field_text_hidden}>
                  {clOgrn}</p>

                <div className={st.wrap_p}>
                  <p
                    className={!inputOgrnShow ? st.edit_field_btn : st.edit_field_btn_hide}
                    onClick={() => setInputOgrnShow(!inputOgrnShow)}>Изменить</p>

                  <p
                    className={inputOgrnShow ? st.save_field_btn : st.save_field_btn_hide}
                    onClick={() => setInputOgrnShow(false)}>Сохранить</p>
                </div>
              </div>

              <input
                className={inputOgrnShow ? st.edit_entry : st.edit_entry_hidden}
                type='text'
                name='clOgrn'
                value={clOgrn}
                placeholder='ОГРН'
                onChange={(e) => setClOgrn(e.target.value)}
              />
            </div>

            {/* инпут редактирования КПП клиента */}
            <div className={st.edit_input}>
              <div className={st.edit_field_wrap}>
                <p className={st.edit_field_title}>КПП</p>

                <p
                  className={!inputKppShow ? st.edit_field_text : st.edit_field_text_hidden}>
                  {clKpp}</p>

                <div className={st.wrap_p}>
                  <p
                    className={!inputKppShow ? st.edit_field_btn : st.edit_field_btn_hide}
                    onClick={() => setInputKppShow(!inputKppShow)}>Изменить</p>

                  <p
                    className={inputKppShow ? st.save_field_btn : st.save_field_btn_hide}
                    onClick={() => setInputKppShow(false)}>Сохранить</p>
                </div>
              </div>

              <input
                className={inputKppShow ? st.edit_entry : st.edit_entry_hidden}
                type='text'
                name='clKpp'
                value={clKpp}
                placeholder='КПП'
                onChange={(e) => setClKpp(e.target.value)}
              />
            </div>

            {/* инпут редактирования юридического адреса клиента */}
            <div className={st.edit_input}>
              <div className={st.edit_field_wrap}>
                <p className={`${st.edit_field_title} ${st.mb20}`}>Юридический адрес</p>

                <p className={st.edit_field_text}>{clUrAddress.full_name}</p>

                <div className={st.wrap_p}>
                  <p
                    className={!inputUrAddressShow ? st.edit_field_btn : st.edit_field_btn_hide}
                    onClick={() => setInputUrAddressShow(!inputUrAddressShow)}>Изменить</p>

                  <p
                    className={inputUrAddressShow ? st.save_field_btn : st.save_field_btn_hide}
                    onClick={() => setInputUrAddressShow(false)}>Сохранить</p>
                </div>
              </div>

              {/* формирование юридического адреса */}
              <div className={inputUrAddressShow ? st.address_group : st.address_group_hidden}>
                <Fias1 setFiasDataObj1={setFiasDataObj1} />
              </div>
            </div>

            {/* инпут редактирования фактического адреса клиента */}
            <div className={st.edit_input}>
              <div className={st.edit_field_wrap}>
                <p className={`${st.edit_field_title} ${st.mb20}`}>Фактический адрес</p>

                <p className={st.edit_field_text}>{clFactAddress.full_name}</p>

                <div className={st.wrap_p}>
                  <p
                    className={!inputFactAddressShow ? st.edit_field_btn : st.edit_field_btn_hide}
                    onClick={() => setInputFactAddressShow(!inputFactAddressShow)}>Изменить</p>

                  <p
                    className={inputFactAddressShow ? st.save_field_btn : st.save_field_btn_hide}
                    onClick={() => setInputFactAddressShow(false)}>Сохранить</p>
                </div>
              </div>

              {/* формирование фактического адреса */}
              <div className={inputFactAddressShow ? st.address_group : st.address_group_hidden}>
                <Fias2 setFiasDataObj2={setFiasDataObj2} />
              </div>
            </div>

            {/* инпут редактирования генерального директора клиента */}
            <div className={st.edit_input}>
              <div className={st.edit_field_wrap}>
                <p className={st.edit_field_title}>Генеральный директор</p>

                <p
                  className={!inputGenDirShow ? st.edit_field_text : st.edit_field_text_hidden}>
                  {clGenDir}</p>

                <div className={st.wrap_p}>
                  <p
                    className={!inputGenDirShow ? st.edit_field_btn : st.edit_field_btn_hide}
                    onClick={() => setInputGenDirShow(!inputGenDirShow)}>Изменить</p>

                  <p
                    className={inputGenDirShow ? st.save_field_btn : st.save_field_btn_hide}
                    onClick={() => setInputGenDirShow(false)}>Сохранить</p>
                </div>
              </div>

              <input
                className={inputGenDirShow ? st.edit_entry : st.edit_entry_hidden}
                type='text'
                name='clGenDir'
                value={clGenDir}
                placeholder='Генеральный директор'
                onChange={(e) => setClGenDir(e.target.value)}
              />
            </div>

            {/* инпут редактирования главного бухгалтера клиента */}
            <div className={st.edit_input}>
              <div className={st.edit_field_wrap}>
                <p className={st.edit_field_title}>Главный бухгалтер</p>

                <p
                  className={!inputBookShow ? st.edit_field_text : st.edit_field_text_hidden}>
                  {clBook}</p>

                <div className={st.wrap_p}>
                  <p
                    className={!inputBookShow ? st.edit_field_btn : st.edit_field_btn_hide}
                    onClick={() => setInputBookShow(!inputBookShow)}>Изменить</p>

                  <p
                    className={inputBookShow ? st.save_field_btn : st.save_field_btn_hide}
                    onClick={() => setInputBookShow(false)}>Сохранить</p>
                </div>
              </div>

              <input
                className={inputBookShow ? st.edit_entry : st.edit_entry_hidden}
                type='text'
                name='clBook'
                value={clBook}
                placeholder='Главный бухгалтер'
                onChange={(e) => setClBook(e.target.value)}
              />
            </div>
          </fieldset>

          {/* банковские реквизиты */}
          <fieldset className={openRequisitBankWin ? st.ft_edit_requisites : st.hidden}>
            <h3 className={st.edit_title}>Банковские реквизиты</h3>

            {/* инпут редактирования расчетного счета клиента */}
            <div className={st.edit_input}>
              <div className={st.edit_field_wrap}>
                <p className={st.edit_field_title}>Расчетный счет</p>

                <p
                  className={!inputBankAccountShow ? st.edit_field_text : st.edit_field_text_hidden}>
                  {clBankAccount}</p>

                <div className={st.wrap_p}>
                  <p
                    className={!inputBankAccountShow ? st.edit_field_btn : st.edit_field_btn_hide}
                    onClick={() => setInputBankAccountShow(!inputBankAccountShow)}>Изменить</p>

                  <p
                    className={inputBankAccountShow ? st.save_field_btn : st.save_field_btn_hide}
                    onClick={() => setInputBankAccountShow(false)}>Сохранить</p>
                </div>
              </div>

              <input
                className={inputBankAccountShow ? st.edit_entry : st.edit_entry_hidden}
                type='text'
                name='clBankAccount'
                value={clBankAccount}
                placeholder='Расчетный счет'
                onChange={(e) => setClBankAccount(e.target.value)}
              />
            </div>

            {/* инпут редактирования корреспондентского счета клиента */}
            <div className={st.edit_input}>
              <div className={st.edit_field_wrap}>
                <p className={st.edit_field_title}>Корреспондентский счет</p>

                <p
                  className={!inputCorrAccountShow ? st.edit_field_text : st.edit_field_text_hidden}>{clCorrAccount}</p>

                <div className={st.wrap_p}>
                  <p
                    className={!inputCorrAccountShow ? st.edit_field_btn : st.edit_field_btn_hide}
                    onClick={() => setInputCorrAccountShow(!inputCorrAccountShow)}>Изменить</p>

                  <p
                    className={inputCorrAccountShow ? st.save_field_btn : st.save_field_btn_hide}
                    onClick={() => setInputCorrAccountShow(false)}>Сохранить</p>
                </div>
              </div>

              <input
                className={inputCorrAccountShow ? st.edit_entry : st.edit_entry_hidden}
                type='text'
                name='clCorrAccount'
                value={clCorrAccount}
                placeholder='Корреспондентский счет'
                onChange={(e) => setClCorrAccount(e.target.value)}
              />
            </div>

            {/* инпут редактирования наименования банка клиента */}
            <div className={st.edit_input}>
              <div className={st.edit_field_wrap}>
                <p className={st.edit_field_title}>Банк</p>

                <p
                  className={!inputBankShow ? st.edit_field_text : st.edit_field_text_hidden}>
                  {clBank}</p>

                <div className={st.wrap_p}>
                  <p
                    className={!inputBankShow ? st.edit_field_btn : st.edit_field_btn_hide}
                    onClick={() => setInputBankShow(!inputBankShow)}>Изменить</p>

                  <p
                    className={inputBankShow ? st.save_field_btn : st.save_field_btn_hide}
                    onClick={() => setInputBankShow(false)}>Сохранить</p>
                </div>
              </div>

              <input
                className={inputBankShow ? st.edit_entry : st.edit_entry_hidden}
                type='text'
                name='clBank'
                value={clBank}
                placeholder='Банк'
                onChange={(e) => setClBank(e.target.value)}
              />
            </div>

            {/* инпут редактирования БИК клиента */}
            <div className={st.edit_input}>
              <div className={st.edit_field_wrap}>
                <p className={st.edit_field_title}>БИК</p>

                <p
                  className={!inputBankShow ? st.edit_field_text : st.edit_field_text_hidden}>
                  {clBIK}</p>

                <div className={st.wrap_p}>
                  <p
                    className={!inputBIKShow ? st.edit_field_btn : st.edit_field_btn_hide}
                    onClick={() => setInputBIKShow(!inputBIKShow)}>Изменить</p>

                  <p
                    className={inputBIKShow ? st.save_field_btn : st.save_field_btn_hide}
                    onClick={() => setInputBIKShow(false)}>Сохранить</p>
                </div>
              </div>

              <input
                className={inputBIKShow ? st.edit_entry : st.edit_entry_hidden}
                type='text'
                name='clBIK'
                value={clBIK}
                placeholder='БИК'
                onChange={(e) => setClBIK(e.target.value)}
              />
            </div>
          </fieldset>

        </form>
      </div>
    </div>
  )
};
