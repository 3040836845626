import st from './SectionExtendLife.module.css';
import arrow_right from '../../img/arrow_right.svg';
import { useInView } from 'react-intersection-observer';

export const SectionExtendLife = () => {
  const { ref, inView } = useInView({
    threshold: 0.7
  });

  return (
    <section className={st.sectionextendlife}>
      <div ref={ref} className={`${st.block_background} outer_container`}>
        <div className={st.illuminat}></div>
        <div className={!inView ? st.decorcircles : st.animation_statrt}></div>
        <div className={st.container}>
          <div className={st.flex_content}>
            <div className={st.wrap_content}>
              <h2>Продлите службу&nbsp;вашего оборудования</h2>

              <p className={st.wrap_content_p}>
                Наша гарантия дает вам уверенность и&nbsp;защиту от&nbsp;неожиданных расходов на&nbsp;ремонт, а&nbsp;также гарантирует быстрое и&nbsp;качественное обслуживание в&nbsp;случае поломки.
              </p>

              <a className={st.btn_order} href='/#сonsultation'>
                <p>Оставить заявку</p>
                <img className={st.btn_img} src={arrow_right} alt='' />
              </a>
            </div>

            <div className={st.wrap_stat}>
              <div className={st.stat_details}>
                <p>Деталей&nbsp;в&nbsp;фонде: <br /><span>1&nbsp;350</span></p>
                <p>на&nbsp;сумму: <br /><span>25&nbsp;545&nbsp;250&nbsp;₽</span></p>
              </div>

              <div className={st.stat_wrapper}>
                <div className={st.stat_clients}>
                  <p>Заказчиков: <br /><span>35</span></p>
                </div>
                <div className={st.stat_clients}>
                  <p>Количество&nbsp;лифтов: <br /><span>337</span></p>
                </div>
              </div>
            </div>

            <a className={st.btn_order_mob} href='/#сonsultation'>
              <p>Оставить заявку</p>
              <img className={st.btn_img} src={arrow_right} alt='' />
            </a>
          </div>
        </div>
      </div>
    </section>
  )
};
