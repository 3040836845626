// import st from './Overlay.module.css';
import ReactDOM from 'react-dom';

const overlay = document.getElementById('overlay');

export const Overlay = ({ className, onClick, children }) => {

  return (
    ReactDOM.createPortal(
      <div className={className} onClick={onClick}>
        {children}
      </div>,
      overlay
    )
  )
};
