import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  PHONE_CHECKW,
  // ORG_ADD, 
  // USER_VERIFY_SMS 
} from '../../consts.js';


export const fetchPhoneConfirm = createAsyncThunk(
  'reg/fetchPhoneConfirm',

  async (bodyObj) => {
    const response = await fetch(PHONE_CHECKW, {
      method: 'POST',
      body: JSON.stringify(bodyObj),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
        'User-Agent': 'LiftupSite/1.0.0',
      }
    });

    const data = await response.json();
    console.log('data: ', data);

    return data;
  }
);

// export const fetchCodeConfirm = createAsyncThunk(
//   'reg/fetchCodeConfirm',

//   async (bodyObj) => {
//     const response = await fetch(USER_VERIFY_SMS, {
//       method: 'POST',
//       body: JSON.stringify(bodyObj),
//       headers: {
//         'Content-type': 'application/json; charset=UTF-8',
//       }
//     });

//     const data = await response.json();
//     console.log('data: ', data);

//     return data;
//   }
// );

// export const fetchAddOrg = createAsyncThunk(
//   'reg/fetchAddOrg',

//   async (bodyObj) => {
//     const response = await fetch(ORG_ADD, {
//       method: 'POST',
//       body: JSON.stringify(bodyObj),
//       headers: {
//         'Content-type': 'application/json; charset=UTF-8',
//       }
//     });

//     const data = await response.json();
//     console.log('data: ', data);

//     return data;
//   }
// );



const initialState = {
  status: 'idle',
  dataReg: {},
  newOrg: {},
  phoneCheck: null,
  codeCheck: null,
  error: null,
};

const regSlice = createSlice({
  name: 'reg',
  initialState,
  reducers: {
    addReg: (state, action) => {
      state.dataReg = action.payload;
    },
    removeReg: (state) => {
      state.dataReg = {};
    }
  },


  extraReducers: (builder) => {
    builder

      // отправка номера телефона для получения кода
      .addCase(fetchPhoneConfirm.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(fetchPhoneConfirm.fulfilled, (state, action) => {
        state.status = 'success';
        state.phoneCheck = action.payload;
      })
      .addCase(fetchPhoneConfirm.rejected, (state, action) => {
        state.status = 'failed';
        state.phoneCheck = action.error.message;
      })

    // отправка кода полученного в смс на сервер для проверки
    // .addCase(fetchCodeConfirm.pending, (state) => {
    //   state.status = 'loading'
    // })
    // .addCase(fetchCodeConfirm.fulfilled, (state, action) => {
    //   state.status = 'success';
    //   state.codeCheck = action.payload;
    // })
    // .addCase(fetchCodeConfirm.rejected, (state, action) => {
    //   state.status = 'failed';
    //   state.codeCheck = action.error.message;
    // })

    // отправка введенных данных новой организации
    // .addCase(fetchAddOrg.pending, (state) => {
    //   state.status = 'loading'
    // })
    // .addCase(fetchAddOrg.fulfilled, (state, action) => {
    //   state.status = 'success';
    //   state.newOrg = action.payload;
    // })
    // .addCase(fetchAddOrg.rejected, (state, action) => {
    //   state.status = 'failed';
    //   state.newOrg = action.error.message;
    // })

  }
});

export const { addReg, removeReg } = regSlice.actions;

export default regSlice.reducer;
