import st from './PrivacyPolicy.module.css';

export const PrivacyPolicy = () => {

  return (
    <div className={`${st.privacypolicy} container ${st.privacypolicy_container}`}>
      <h1 className={st.title1}>Политика конфиденциальности</h1>

      <p className={st.paragraph}>
        Настоящая политика конфиденциальности (далее — «Политика») подготовлена в соответствии с Федеральным законом от 27.07.2006 №152-ФЗ «О персональных данных» и устанавливает правила использования сайтом liftup.pro персональной информации, получаемой от пользователей сайта liftup.pro (далее — «Пользователи»)
      </p>

      <p className={st.paragraph}>
        Пользователь дает свое согласие Администратору на обработку персональных данных Пользователя, предоставляемых при заполнении любой регистрационной формы на Сайте и в ходе дальнейшего взаимодействия с Сайтом, в том числе на передачу таких персональных данных третьим лицам во исполнение соглашения между Администратором и Пользователем и между Администратором и третьим лицом, даже когда такая передача осуществляется на территорию иных государств (трансграничная передача).
      </p>

      <h2 className={st.title2}>Информация, собираемая Администратором</h2>

      <p className={st.paragraph}>
        Администратор собирает, получает доступ и использует в определенных Политикой целях персональные данные Пользователя, техническую и иную информацию, связанную с Пользователем.
      </p>

      <p className={st.paragraph}>
        Техническая информация не является персональными данными. Администратор использует файлы cookies и аналогичные технологии, которые позволяют идентифицировать устройство Пользователя. Файлы cookies – это текстовые файлы, доступные Администратору для обработки информации об активности Пользователя, включая информацию о том, какие страницы Сайта посещал Пользователь и о времени, которое Пользователь провел на странице Сайта. Пользователь может отключить возможность использования файлов cookies в настройках браузера. Также под технической информацией понимается информация, которая автоматически передается Администратору в процессе использования Сайта с помощью установленного на устройстве Пользователя программного обеспечения.
      </p>

      <p className={st.paragraph}>
        Под персональными данными Пользователя понимается информация, которую Пользователь предоставляет Администратору при заполнении формы на Сайте и последующем взаимодействии с Администратором, в том числе:
      </p>

      <p className={st.list}>
        - фамилия, имя, отчество; <br />
        - адрес электронной почты; <br />
        - контактный телефон.
      </p>

      <p className={st.paragraph}>
        Администратор обрабатывает персональные данные, техническую информацию и иную информацию Пользователя в течение всего срока действия заключенного с Пользователем соглашения, а в случае отсутствия такого соглашения – в течение 5 (пяти) лет с момента предоставления указанной информации.
      </p>

      <h2 className={st.title2}>Цели использования информации</h2>

      <p className={st.paragraph}>
        Информация, предоставленная Пользователем, используется Администратором исключительно в целях:
      </p>

      <p className={st.paragraph2}>
        - заключения между Администратором и Пользователем соглашения, а также исполнения Администратором такого соглашения;
      </p>

      <p className={st.paragraph2}>
        - рассмотрения обращений Пользователя;
      </p>

      <p className={st.paragraph2}>
        - направления Пользователю рекламных и/или информационных материалов, включая материалы третьих лиц.
      </p>

      <h2 className={st.title2}>Способы обработки</h2>

      <p className={st.paragraph}>
        Обработка персональных данных Пользователя означает запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение персональных данных Пользователя, не подпадающих под специальные категории, на обработку которых Администратору, согласно действующему законодательству Российской Федерации, требуется письменное согласие Пользователя.
      </p>

      <p className={st.paragraph}>
        Обработка персональных данных Пользователя производится Администратором с использованием баз данных на территории Российской Федерации.
      </p>

      <p className={st.paragraph}>
        Пользователь признает, что использование сайта расценивается Администратором и Пользователем как письменное согласие на обработку персональных данных. Использование сайта без принятия условий настоящей Политики не допускается.
      </p>

      <h2 className={st.title2}>Меры, принимаемые для защиты предоставляемой Пользователем информации, и гарантии Администратором</h2>

      <p className={st.paragraph}>
        Администратор принимает необходимые и достаточные правовые, организационные и технические меры для защиты информации, предоставляемой Пользователями, от неправомерного или случайного доступа, уничтожения, изменения, блокирования, копирования, распространения, а также от иных неправомерных действий с ней третьих лиц, путем ограничения доступа к такой информации иных Пользователей Сайта Администратора, сотрудников и партнеров Администратора, третьих лиц (за исключением предоставления Администратором информации, необходимой для исполнения Администратором обязательств перед Пользователем и требований российского законодательства), а также наложения на таких лиц санкций за нарушение режима конфиденциальности в отношении таких данных.
      </p>

      <h2 className={st.title2}>Новые редакции Политики</h2>

      <p className={st.paragraph}>
        Администратор оставляет за собой право вносить изменения в Политику. На Пользователе лежит обязанность при каждом обращении к Сайту знакомиться с текстом Политики.
      </p>

      <p className={st.paragraph}>
        Владелец сайта (ранее— «Администратор»):
      </p>

      <p className={st.paragraph}>
        ООО «Лифтап»
      </p>

      <p className={st.paragraph}>
        Адрес электронной почты: astro-lift@mail.ru
      </p>

      <p className={st.paragraph}>
        Адрес местонахождения: город Уфа, ул. З. Биишевой, д. 2
      </p>
    </div>
  )
};
