import st from './Home.module.css';
import { SectionHero } from 'src/modules/SectionHero/SectionHero.jsx';
import { SectionWarranty } from 'src/modules/SectionWarranty/SectionWarranty.jsx';
import { SectionBrands } from 'src/modules/SectionBrands/SectionBrands.jsx';
import { SectionExtendLife } from 'src/modules/SectionExtendLife/SectionExtendLife.jsx';
import { SectionNews } from 'src/modules/SectionNews/SectionNews.jsx';
import { SectionClients } from 'src/modules/SectionClients/SectionClients.jsx';
import { SectionConsultation } from 'src/modules/SectionConsultation/SectionConsultation.jsx';

export const Home = () => {

  return (
    <main className={st.main}>
      <SectionHero />
      <SectionWarranty />
      <SectionBrands />
      <SectionExtendLife />
      <SectionNews />
      <SectionClients />
      <SectionConsultation />
    </main>
  )
};
