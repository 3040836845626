import st from './SectionHero.module.css';
import { v4 as uuidv4 } from 'uuid';
import arrow_right from '../../img/arrow_right.svg';
// import slide_hero1 from '../../img/slide_hero1.svg';
// import slide_hero2 from '../../img/slide_hero2.svg';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay, A11y } from 'swiper';
import 'swiper/css';
// import 'swiper/css/navigation';
// import 'swiper/css/pagination';
import { slide_list } from './data/slideL_list.js'
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { fetchSlidesList } from 'src/store/slides/getSlides';
import { Link } from 'react-router-dom';

export const SectionHero = () => {
  // сортированный массив слайдов
  const [slideListSort, setSlideListSort] = useState([]);
  // console.log('slideListSort: ', slideListSort);

  const { slideList } = useSelector(state => state.slidesListReducer.dataSlidesList);
  const { status } = useSelector(state => state.slidesListReducer);

  const dispatch = useDispatch();

  // сортируем  массив по ключу "slideOrder" (по порядковому номеру слайда)
  slide_list.slideList.sort((a, b) => {
    return (Object.values(a)[3] > Object.values(b)[3]) - 0.5;
  });

  useEffect(() => {
    if (!slideList && status !== 'failed') {
      dispatch(fetchSlidesList());
    }

    if (status === 'success') {
      // сортируем  массив по ключу "slideOrder" (по порядковому номеру слайда)
      setSlideListSort(Array.from(slideList).sort((a, b) => {
        return (Object.values(a)[3] > Object.values(b)[3]) - 0.5;
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, status]);
  // }, [dispatch]);

  return (
    <section className={`${st.sectionhero} container`} id='hero'>
      <div className={st.wrapper_content}>
        <h1 className={st.hero_title}>
          Техническая гарантия на&nbsp;25&nbsp;лет
        </h1>

        <div className={st.desctop}>
          <p className={st.hero_description}>
            Наша дополнительная гарантия на&nbsp;<nobr>подъемно-транспортное</nobr> оборудование дает вам уверенность и&nbsp;защиту от&nbsp;неожиданных расходов на&nbsp;ремонт, а&nbsp;также гарантирует быстрое и&nbsp;качественное обслуживание в&nbsp;случае поломки.
          </p>

          <a className={st.btn_order} href='/#сonsultation'>
            <p>Оставить заявку</p>
            <img className={st.btn_img} src={arrow_right} alt='' />
          </a>
        </div>
      </div>

      {status === 'success' ?
        <div className={st.wrapper_slider}>
          <Swiper
            className='hero_swiper'
            modules={[Navigation, Pagination, Autoplay, A11y]}
            spaceBetween={10}
            slidesPerView={1}
            onSlideChange={() => { }}
            onSwiper={(swiper) => { }}
            // grabCursor={true}
            direction={'horizontal'}
            loop='true'
            navigation
            allowTouchMove={true}
            autoplay={
              { delay: 6000 }
            }
            breakpoints={{
              650: {
                direction: 'vertical',
              },
            }}
          // pagination={{ clickable: true }}
          // initialSlide='1'
          >
            {
              // slide_list.slideList.map(item =>
              slideListSort.map(item =>
                <SwiperSlide key={uuidv4()}>
                  {
                    item.slideLink ?
                      <Link to={item.slideLink} target='_blank'>
                        <div className={st.wrap_slide_hero} style={{ backgroundImage: `url(${item.slideimagelink})` }}>
                          {/* <img className={st.slide_hero} src={item.slideimagelink} alt='' /> */}
                        </div>
                      </Link> :
                      <div className={st.wrap_slide_hero} style={{ backgroundImage: `url(${item.slideimagelink})` }}>
                        {/* <img className={st.slide_hero} src={item.slideimagelink} alt='' /> */}
                      </div>
                  }
                </SwiperSlide>)
            }
          </Swiper>
        </div> :
        <div className={st.slide_preload}><p>загрузка слайдов...</p></div>
      }

      <div className={st.mob}>
        <p className={st.hero_description}>
          Наша дополнительная гарантия на&nbsp;<nobr>подъемно-транспортное</nobr> оборудование дает вам уверенность и&nbsp;защиту от&nbsp;неожиданных расходов на&nbsp;ремонт, а&nbsp;также гарантирует быстрое и&nbsp;качественное обслуживание в&nbsp;случае поломки.
        </p>

        <a className={st.btn_order} href='/#сonsultation'>
          <p>Оставить заявку</p>
          <img className={st.btn_img} src={arrow_right} alt='' />
        </a>
      </div>
    </section>
  )
};
