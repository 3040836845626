import { CHANGE_PASS, ORG_UPDV2, SHS_POST } from "src/consts.js";
import { updateTokens } from "src/utils/updateTokens.js";

// запрос отправляет данные на добавление организации из панели администратора
export const fetchClBaseAdd = async (bodyData1, bodyData2, bodyData3) => {
  // запрос на добавление без пароля
  const response = await fetch(ORG_UPDV2, {
    method: 'POST',
    body: JSON.stringify(bodyData1),
    headers: {
      'Content-type': 'application/json; charset=UTF-8',
      'User-Agent': 'LiftupSite/1.0.0',
      'Authorization': `Bearer ${localStorage.getItem('tokenlu')}`,
    },
  });
  const data = await response.json();

  // ==================================================
  if (response.status === 401 || data.loginstatus === 7) {

    // перезапись токенов
    await updateTokens();

    // запрос после перезаписи токенов
    const response = await fetch(ORG_UPDV2, {
      method: 'POST',
      body: JSON.stringify(bodyData1),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
        'User-Agent': 'LiftupSite/1.0.0',
        'Authorization': `Bearer ${localStorage.getItem('tokenlu')}`,
      },
    });
    const data = await response.json();

    if (data.status === true) {

      // запрос на получение SHC
      const responseshc = await fetch(SHS_POST, {
        method: 'POST',
        body: JSON.stringify(bodyData2),
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'User-Agent': 'LiftupSite/1.0.0',
        },
      });

      const datashc = await responseshc.json();

      if (responseshc.status === 200) {

        // запрос на добавление пароля
        const responseaddpass = await fetch(CHANGE_PASS, {
          method: 'POST',
          body: JSON.stringify(bodyData3),
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
            'User-Agent': 'LiftupSite/1.0.0',
            'Authorization': `Bearer ${localStorage.getItem('tokenlu')}`,
            'shc': datashc.shc,
          },
        });

        const dataaddpass = await responseaddpass.json();
        console.log('dataaddpass.status: ', dataaddpass.status);

      } else { console.log('responseshc.status: ', responseshc.status) }

    } else { console.log('data.status: ', data.status) }

    console.log('добавление организации произошло c перезаписью токенов');
    return data;
  }
  // ==================================================

  if (data.status === true) {

    // запрос на получение SHC
    const responseshc = await fetch(SHS_POST, {
      method: 'POST',
      body: JSON.stringify(bodyData2),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
        'User-Agent': 'LiftupSite/1.0.0',
      },
    });

    const datashc = await responseshc.json();

    if (responseshc.status === 200) {

      // запрос на добавление пароля
      const responseaddpass = await fetch(CHANGE_PASS, {
        method: 'POST',
        body: JSON.stringify(bodyData3),
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'User-Agent': 'LiftupSite/1.0.0',
          'Authorization': `Bearer ${localStorage.getItem('tokenlu')}`,
          'shc': datashc.shc,
        },
      });

      const dataaddpass = await responseaddpass.json();
      console.log('dataaddpass.status: ', dataaddpass.status);

    } else { console.log('responseshc.status: ', responseshc.status) }

  } else { console.log('data.status: ', data.status) }

  console.log('добавление организации произошло без перезаписи токенов');
  return data;
};
