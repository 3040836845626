import st from './NewsPreview.module.css';
import { useEffect, useRef, useState } from 'react';
import { Overlay } from 'src/components/Overlay/Overlay';
import { NewsEdit } from '../NewsEdit/NewsEdit';
import { useDispatch } from 'react-redux';
import { fetchNewsList } from 'src/store/news/getNewsList';
import nophoto from '../../../../../../img/nophoto.svg';
import { fetchNewsDel } from '../../fetches/fetchDel';

export const NewsPreview = ({
  newsid,
  imagelink,
  newsname,
  newsdate,
  newspreview,
  newsvalue
}) => {
  // откр, закр модалки редактирование новости
  const [modalEdit, setModalEdit] = useState(false);

  // данные для передачи в модальное окно "редактирование новости"
  const [targetId, setTargetId] = useState('');
  const [targetImagelink, setTargetImagelink] = useState('');
  const [targetNewsname, setTargetNewsname] = useState('');
  const [targetNewspreview, setTargetNewspreview] = useState('');
  const [targetNewsvalue, setTargetNewsvalue] = useState('');
  const [targetNewsdate, setTargetNewsdate] = useState('');

  const refEditOrDel = useRef(null);

  const dispatch = useDispatch();

  // модальное меню "редактировать удалить"
  const [menuEditOrDel, setMenuEditOrDel] = useState(false);

  // закрываем меню по клику вне элемента
  useEffect(() => {
    let handler = (e) => {
      if (!refEditOrDel.current.contains(e.target)) {
        setMenuEditOrDel(false);
      }
    };

    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    }
  });


  // удаляем новость, закрываем меню, обновляем список новостей
  const newsDel = async () => {
    // закрываем меню "редактировать удалить"
    setMenuEditOrDel(false);
    // ждем выполнения удаления
    await fetchNewsDel(newsid);
    // обновляем список новостей
    dispatch(fetchNewsList());
  };

  // показывает меню "редактировать удалить"
  const editOrDel = (e) => {
    e.preventDefault();
    console.log('menuEditOrDel: ', menuEditOrDel);
    setMenuEditOrDel(!menuEditOrDel);
  };

  // открывает окно "редактировать новость" выбранного элемента
  const editTargetElem = () => {
    setTargetId(newsid);

    setTargetImagelink(imagelink);

    setTargetNewsname(newsname);

    setTargetNewsdate(newsdate.slice(0, -9));

    setTargetNewspreview(newspreview);

    setTargetNewsvalue(newsvalue);

    setModalEdit(!modalEdit);
  };

  return (
    <>
      <li className={st.news_item} id={newsid}>
        <img
          className={st.item_img}
          src={imagelink && !imagelink?.endsWith('unknown') ? imagelink : nophoto}
          alt={newsname} />

        <h4 className={st.news_title}>{newsname}</h4>

        <p className={st.item_date}>{newsdate.slice(0, -9).split('-').reverse().join('-')}</p>

        <div className={st.item_edit_del} ref={refEditOrDel}>
          <div className={st.item_btn} onClick={editOrDel}></div>

          <ul
            className={!menuEditOrDel ? st.btn_menu : st.btn_menu_show}

            onClick={(e) => { e.preventDefault() }}>
            <li className={st.btn_menu_item} onClick={editTargetElem}>Редактировать</li>

            <li className={st.btn_menu_item} onClick={newsDel}>Удалить</li>
          </ul>
        </div>
      </li>

      <Overlay className={!modalEdit ? st.overlay_hide : st.overlay_show}>
        <NewsEdit
          closeModalEdit={() => setModalEdit(false)}
          targetId={targetId}
          targetImagelink={targetImagelink}
          targetNewsname={targetNewsname}
          targetNewsdate={targetNewsdate}
          targetNewspreview={targetNewspreview}
          targetNewsvalue={targetNewsvalue}
        />
      </Overlay>
    </>
  )
};
