// import st from './RouteForAuthUsers.module.css';
import { Navigate, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';

// export const RouteForAuthUsers = ({ children }) => {
export const RouteForAuthUsers = () => {
  const userroles = useSelector(store => store.authReducer.dataAuth.user?.userroles) || null;
  // console.log('userroles: ', userroles);

  return (
    // userroles === 1 ? <Outlet /> : <Navigate to={0} />
    userroles === 1 ? <Outlet /> : <Navigate to='/signin/author' />
    // userroles !== null && userroles === 1 ? <Outlet /> : <Navigate to='/signin/author' />
  )
};
