import { ORG_DEL } from "src/consts";
import { updateTokens } from "src/utils/updateTokens";

// удаляем организацию
export const fetchOrgDel = async (organizationid) => {
  try {
    // отправляем запрос на удаление организации
    const response = await fetch(`${ORG_DEL}${organizationid}`, {
      method: 'GET',
      headers: {
        'User-Agent': 'LiftupSite/1.0.0',
        'Authorization': `Bearer ${localStorage.getItem('tokenlu')}`,
      },
    });
    const data = await response.json();
    console.log('data: ', data);

    // в случае просроченного токена обновляем токены
    if (response.status === 401 || data.loginstatus === 7) {
      // перезапись токенов
      await updateTokens();

      // снова отправляем запрос 
      const responsetwo = await fetch(`${ORG_DEL}${organizationid}`, {
        method: 'GET',
        headers: {
          'User-Agent': 'LiftupSite/1.0.0',
          'Authorization': `Bearer ${localStorage.getItem('tokenlu')}`,
        },
      });
      const datatwo = await responsetwo.json();
      console.log('datatwo: ', datatwo);
    }
  } catch (err) {
    console.log(`сработал try-cetch ${err.message}`);
  }
};
