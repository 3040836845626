import st from './SlideAdd.module.css';
import { ReactComponent as BtnClose } from '../../../../../../img/btn_close.svg';
import { ReactComponent as BtnLoad } from '../../../../../../img/btn_folder.svg';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { fetchSlidesList } from 'src/store/slides/getSlides';
import { fetchSlideAddEdit } from '../../fetches/fetchAddEdit';

export const SlideAdd = ({ modalClose, slideList, status }) => {
  // для определения максимального slideOrder
  const [nextOrder, setNextOrder] = useState(0);

  useEffect(() => {
    // при перезагрузке или открытии прямой ссылки чтобы исключить ошибку undefined
    if (status === 'success' && slideList && slideList.length) {
      const maxOrder = Array.from(slideList).reduce((previous, current) => {
        if (+current.slideOrder > +previous.slideOrder) {
          return current;
        } else {
          return previous;
        }
      });
      // console.log('maxOrder: ', maxOrder.slideOrder);
      setNextOrder(maxOrder.slideOrder + 1);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slideList]);

  // превью выбранного файла
  const [imagePreview, setImagePreview] = useState(null);
  // инпут ввода ссылки для слайда
  const [slideLink, setSlideLink] = useState('');

  // ссылка на инпут с кнопкой для выбора файла для загрузки на ПК
  const fileSelection = useRef(null);

  // данные выбранного для отправки файла
  const [selectedFileDouble, setSelectedFileDouble] = useState('');

  const dispatch = useDispatch();

  // данные для второго запроса
  const slidesDataBody = {
    'slideID': 0,
    'slideLink': slideLink,
    'slideimagelink': '',
    'slideOrder': nextOrder
  };

  // отправляет форму
  const handleFormSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('uploadedFile', selectedFileDouble);
    console.log(formData.uploadedFile);

    await fetchSlideAddEdit(formData, slidesDataBody);

    dispatch(fetchSlidesList());

    modalClose();

    setImagePreview(null);

    console.log('форма добавления слайда отправлена');
  };

  // выбирает новый файл на ПК и вставляет в превью
  const previewFile = (e) => {
    const reader = new FileReader();

    const selectedFile = e.target.files[0];

    setSelectedFileDouble(selectedFile);

    if (selectedFile) {
      reader.readAsDataURL(selectedFile);
    }

    reader.onload = (readerEvent) => {
      if (selectedFile.type.includes('image')) {
        setImagePreview(readerEvent.target.result);
      }
    };
  };

  // удаляем поле "превью файла"
  const clearPreviewFile = (e) => {
    e.preventDefault();
    setImagePreview(null);
    setSelectedFileDouble('');
  };

  return (
    <div className={st.addslideadd}>
      <button className={st.btn_close} onClick={modalClose}>
        <BtnClose />
      </button>

      <h3 className={st.title}>Добавление слайда</h3>

      <form className={st.form}>

        {/* блок с превью выбранного для загрузки нового слайда */}
        <div className={imagePreview !== null ? st.image_preview_show : st.image_preview_hide}>
          <p className={st.image_preview_show_p}>Картинка слайда</p>

          <div className={st.image_preview}>
            {imagePreview !== null && <img src={imagePreview} alt='' />}
            <button className={st.btn_delete} onClick={clearPreviewFile}></button>
          </div>
        </div>

        {/* инпут с кнопкой для выбора файла для загрузки на ПК */}
        <fieldset className={imagePreview === null ? st.fieldset_show : st.fieldset_hide}>
          <label className={st.label_image}>
            <input
              ref={fileSelection}
              className={st.input_image}
              type='file'
              name='image'
              accept='image/*,image/jpeg,image/png,image/svg'
              onChange={previewFile}
            />
            <p>Картинка слайда...</p>
            <BtnLoad />
          </label>
        </fieldset>

        {/* инпут ввода ссылки для слайда */}
        <div className={st.wrap_link}>
          <p className={slideLink !== '' ? st.wrap_link_p : st.wrap_link_p_hide}>Ссылка</p>

          <fieldset className={st.fieldset_link}>
            <label className={st.label_link}>
              <input
                className={st.input_link}
                type='text'
                name='slideLink'
                value={slideLink}
                placeholder='Ссылка'
                onChange={(e) => setSlideLink(e.target.value)}
              />
            </label>
          </fieldset>
        </div>

        <button
          className={st.btn_submit}
          onClick={handleFormSubmit}
          disabled={selectedFileDouble !== '' ? false : true}
        >Добавить</button>
      </form>
    </div>
  )
};
