// import st from './RouteForAdmin.module.css';
import { Navigate, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';

export const RouteForAdmin = () => {
  const userroles = useSelector(store => store.authReducer.dataAuth.user?.userroles) || null;
  // console.log('userroles: ', userroles);

  return (
    // userroles === 999 ? <Outlet /> : <Navigate to={0} />
    userroles === 999 ? <Outlet /> : <Navigate to='/signin/author' />
    // <Outlet />
    // userroles !== null && userroles === 999 ? <Outlet /> : <Navigate to='/signin/author' />
  )
};
