import st from './YandexMaps.module.css';
import { YMaps, Map, Placemark } from '@pbe/react-yandex-maps';

// типы
type props = {
  name: string,
  city: string,
  address: string,
  center: number[],
  coordinates: any
};

export const YandexMaps = ({ name, city, address, coordinates }: props) => {
  // console.log('coordinates: ', coordinates[0].latitude);
  // console.log('coordinates: ', coordinates[0].longitude);

  // let center: number[] = [55.618806, 37.212297];

  let center: number[] = [+coordinates[0].longitude, +coordinates[0].latitude];

  return (
    <div className={st.yandexmaps}>
      <YMaps>
        <Map
          className={st.yandexmap}
          defaultState={{
            center: center,
            zoom: 16,
            // controls: ["zoomControl", "fullscreenControl"],
          }}
        // modules={["control.ZoomControl", "control.FullscreenControl"]}
        >
          <Placemark
            defaultGeometry={center}
            modules={["geoObject.addon.balloon"]}
            properties={{
              balloonContentBody: name,
            }}
          />
        </Map>
      </YMaps>
    </div>
  )
};
