// функция форматирует ввод номера телефона
const phoneNumberMask = (value) => {
  if (!value) return value;

  let phoneNumber = value.replace(/[^\d]/g, '');

  if (phoneNumber[0] === '8' && phoneNumber.length > 1) {
    phoneNumber = phoneNumber.replace('8', '');
    console.log('phoneNumber: ', phoneNumber);
  }

  if (phoneNumber.length < 4) return `${phoneNumber}`;

  if (phoneNumber.length < 7) {
    return `${phoneNumber.slice(0, 3)} ${phoneNumber.slice(3)}`;
  }

  if (phoneNumber.length < 9) {
    return `${phoneNumber.slice(0, 3)} ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 8)}`;
  }

  return `${phoneNumber.slice(0, 3)} ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 8)}-${phoneNumber.slice(8, 10)}`;
};

export default phoneNumberMask;
