import { SLIDES_ADD } from "src/consts";
import { updateTokens } from "src/utils/updateTokens";

// одинарный запросна добавление всего кроме самого слайда
export const fetchEditWithoutImg = async (bodySlidesData) => {
  bodySlidesData.slideimage = '';

  // запрос на добавление всего кроме самого слайда
  const responseslideadd = await fetch(SLIDES_ADD, {
    method: 'POST',
    body: JSON.stringify(bodySlidesData),
    headers: {
      'Content-type': 'application/json; charset=UTF-8',
      'User-Agent': 'LiftupSite/1.0.0',
      'Authorization': `Bearer ${localStorage.getItem('tokenlu')}`,
    },
  });
  const dataslideadd = await responseslideadd.json();

  console.log('dataslideadd: ', dataslideadd);
  console.log('данные на добавление всего, кроме слайда, отправлены без перезаписи токенов');

  // в случае протухания токена обновляем пару "token - rtoken"
  if (dataslideadd.loginstatus === 7) {
    // перезапись токенов
    await updateTokens();

    bodySlidesData.slideimage = '';

    // запрос на добавление всего кроме самого слайда
    const responseslideadd = await fetch(SLIDES_ADD, {
      method: 'POST',
      body: JSON.stringify(bodySlidesData),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
        'User-Agent': 'LiftupSite/1.0.0',
        'Authorization': `Bearer ${localStorage.getItem('tokenlu')}`,
      },
    });
    const dataslideadd = await responseslideadd.json();

    console.log('dataslideadd: ', dataslideadd);
    console.log('данные на добавление всего, кроме слайда, отправлены c перезаписью токенов');
  }
};
