import st from './Geography.module.css';

export const Geography = () => {

  return (
    <section className={`${st.section_geography} container`}>

      <div className={st.text_block}>
        <h3 className={st.title}>
          На сегодняшний день у нас уже открыты сервисные центры в таких городах, как Москва, Санкт-Петербург, Казань, Уфа, Омск, Екатеринбург, Тюмень, Новосибирск.
        </h3>

        <p className={st.description}>
          Выбирайте &laquo;Лифтап&raquo; и&nbsp;наслаждайтесь всеми преимуществами работы с&nbsp;надежным партнером, обеспечивающим безопасность, комфорт и&nbsp;экономию. Мы&nbsp;рады видеть вас среди наших клиентов и гарантируем качественный сервис и&nbsp;индивидуальный подход к&nbsp;каждому заказчику.
        </p>
      </div>

      <div className={st.image_container}>
        <div className={st.image}></div>
      </div>
    </section>
  )
};
