import st from './DeptService.module.css';
import { useEffect, useState } from 'react';
import { ReactComponent as IconSetting } from '../../../../img/icon_setting.svg';
// import { service_list } from './data/service_list_json.js';
import { v4 as uuidv4 } from 'uuid';
import { Overlay } from 'src/components/Overlay/Overlay';
import { ServiceAdd } from './components/ServiceAdd/ServiceAdd.jsx';
import { ServicePreview } from './components/ServicePreview/ServicePreview.jsx';
import { useDispatch, useSelector } from 'react-redux';
import { fetchServicecentersList } from 'src/store/servicecenters/getServicecentersList';

export const DeptService = () => {
  // откр закр модалка
  const [modalAdd, setModalAdd] = useState(false);
  // сортированный массив документов
  const [serviceCentersListSort, setServiceCentersListSort] = useState([]);

  // список сервисных центров в store
  const { serviceCentres } = useSelector(state => state.servicecentersListReducer.dataServicecentersList);
  // статус  выполнения запроса на получение списка сервисных центров
  const { status } = useSelector(state => state.servicecentersListReducer);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!serviceCentres) {
      dispatch(fetchServicecentersList());
    }

    if (status === 'success') {
      // сортируем  массив по ключу "slideOrder" (по порядковому номеру слайда)
      setServiceCentersListSort(Array.from(serviceCentres).sort((a, b) => {
        return (a.countnum > b.countnum) - 0.5;
      }))
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, status]);


  // ===== пагинация =====
  // номер текущей страницы
  const [currentPageNumber, setCurrentPageNumber] = useState(1);

  // количество сервисных центров на странице
  const statementsQuantity = 4;

  // индекс последней заявки
  const lastStatementIndex = currentPageNumber * statementsQuantity;

  // индекс первой заявки
  const firstStatementIndex = lastStatementIndex - statementsQuantity;

  // список элементов текущей страницы
  const statementsCurrentList = serviceCentersListSort.slice(firstStatementIndex, lastStatementIndex);

  // общее количество сервисных центров
  const totalStatementsQuantity = serviceCentersListSort.length;

  // список номеров-кнопок страниц
  let pages = [];
  for (let i = 1; i <= Math.ceil(totalStatementsQuantity / statementsQuantity); i++) {
    pages.push(i);
  }

  // функция кнопки следующая страница
  const nextPage = () => {
    setCurrentPageNumber(currentPageNumber !== pages.length ? prev => prev + 1 : prev => prev);
  };

  // функция кнопки предыдущая страница
  const prevPage = () => {
    setCurrentPageNumber(currentPageNumber > 1 ? prev => prev - 1 : prev => prev);
  };
  // ===== пагинация =====

  return (
    <>
      <div className={st.deptservice}>
        <div className={st.title_and_btn}>
          <h3 className={st.title}>Сервисные центры</h3>

          <button className={st.add_news} onClick={() => setModalAdd(!modalAdd)}>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M9.5 1.75v16.5M17.75 10H1.25" stroke="#fff" strokeWidth="2" strokeLinecap="round"
                strokeLinejoin="round" />
            </svg>
            Добавить сервис
          </button>
        </div>

        {/* заголовки таблицы */}
        <ul className={st.table_header}>
          <li className={st.header_item}>ID</li>
          <li className={st.header_item}>Наименование</li>
          <li className={st.header_item}>Город</li>
          <li className={st.header_item}>Адрес</li>
          <li className={st.header_item}><IconSetting /></li>
        </ul>

        {/* список сервисных центров */}
        <ul className={st.service_list}>
          {statementsCurrentList.map(item =>
            <ServicePreview
              key={uuidv4()}
              uid={item.uid}
              logo={item.logo}
              name={item.name}
              note={item.note}
              city={item.city}
              address={item.address}
              jobschedule={item.jobschedule}
              email={item.email}
              phone={item.phone}
              countnum={item.countnum}
            />
          )}
        </ul>

        {/* пагинация */}
        <div className={st.pagination}>
          <button
            className={statementsQuantity <= totalStatementsQuantity ? st.pagination_btn_prev : st.hidden}
            onClick={() => {
              prevPage();
              window.scrollTo(0, 0);
            }}
          >
          </button>
          {
            pages.map((item) => {
              return (
                <button
                  key={uuidv4()}
                  onClick={() => {
                    setCurrentPageNumber(item);
                    window.scrollTo(0, 0);
                  }}
                  className={
                    pages.length === 1 ? st.hidden :
                      item === currentPageNumber
                        ? st.pagination_item_active
                        : st.pagination_item
                  }>
                  {item}
                </button>
              );
            })
          }
          <button
            className={statementsQuantity <= totalStatementsQuantity ? st.pagination_btn_next : st.hidden}
            onClick={() => {
              nextPage();
              window.scrollTo(0, 0);
            }}
          >
          </button>
        </div>
      </div>

      <Overlay className={!modalAdd ? st.overlay_hide : st.overlay_show}>
        <ServiceAdd
          closeModal={() => setModalAdd(false)}
          serviceCentres={serviceCentres}
          status={status}
        />
      </Overlay>
    </>
  )
};
