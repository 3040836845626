import st from './NewsAdd.module.css';
import { ReactComponent as BtnClose } from '../../../../../../img/btn_close.svg';
import { ReactComponent as BtnLoad } from '../../../../../../img/btn_folder.svg';
import { useRef, useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { useDispatch } from 'react-redux';
import { fetchNewsList } from 'src/store/news/getNewsList.js';
import { antigoblin } from 'src/utils/antigoblin.js';
import { fetchNewsNoPhotoAddEdit, fetchNewsWithPhotoAddEdit } from '../../fetches/fetchAddEdit';

export const NewsAdd = ({ closeModalAdd }) => {
  // инпуты ввода "фото новости", "заголовок новости", "анонс новости", "дата новости"
  const [imagePreview, setImagePreview] = useState(null);
  const [title, setTitle] = useState('');
  const [announce, setAnnounce] = useState('');
  const [newsdate, setNewsdate] = useState('');

  // ссылка на инпут с кнопкой для выбора файла для загрузки на ПК
  const fileSelected = useRef(null);

  // инпут ввода самой новости с помощью редактора TinyMCE
  const initialText = '';
  const [editorText, setEditorText] = useState(initialText);
  // console.log('editorText: ', editorText); // editorText нигде больше не используется, но нужен
  const [editorValue, setEditorValue] = useState('');

  const dispatch = useDispatch();

  // данные выбранного для отправки файла
  const [selectedFileDouble, setSelectedFileDouble] = useState('');

  // данные для второго запроса
  const newsDataBody = {
    'newsid': 0,
    'newsname': title,
    'newsvalue': editorValue,
    'newsdate': antigoblin(newsdate),
    'newsactive': true,
    'newspreview': announce,
  };

  // отправляет форму
  const handleFormSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('uploadedFile', selectedFileDouble);
    console.log(formData.uploadedFile);

    // первое это отправка с фото второе - отправка без фото
    if (selectedFileDouble !== '') {
      await fetchNewsWithPhotoAddEdit(formData, newsDataBody);
    } else {
      await fetchNewsNoPhotoAddEdit(newsDataBody);
    }

    dispatch(fetchNewsList());

    closeModalAdd();

    console.log('форма отправлена');
  };


  // выбирает новый файл на ПК, сохраняет его данные и показывает его превью
  const previewFile = (e) => {
    const reader = new FileReader();

    const selectedFile = e.target.files[0];

    setSelectedFileDouble(selectedFile);

    if (selectedFile) {
      reader.readAsDataURL(selectedFile);
    }

    reader.onload = (readerEvent) => {
      if (selectedFile.type.includes('image')) {
        setImagePreview(readerEvent.target.result);
      }
    };
  };

  // удаляем поле "превью файла" и данные для formData
  const clearPreviewFile = (e) => {
    e.preventDefault();
    setImagePreview(null);
    setSelectedFileDouble('');
  };


  return (
    <div className={st.newsadd_wrap}>
      <button className={st.btn_close} onClick={closeModalAdd}>
        <BtnClose />
      </button>

      <div className={st.newsadd}>
        <h3 className={st.title}>Добавление новости</h3>

        <form className={st.form}>

          {/* блок с превью выбранного для загрузки нового слайда */}
          <div className={imagePreview !== null ? st.image_preview_show : st.image_preview_hide}>
            <p className={st.image_preview_show_p}>Фото новости</p>

            <div className={st.image_preview}>
              {imagePreview !== null && <img src={imagePreview} alt='' />}
              <button className={st.btn_delete} onClick={clearPreviewFile}></button>
            </div>
          </div>

          {/* инпут с кнопкой для выбора файла для загрузки на ПК */}
          <fieldset className={imagePreview === null ? st.fieldset_show : st.hidden}>
            <label className={st.label_image}>
              <input
                ref={fileSelected}
                className={st.input_image}
                type='file'
                name='newsimage'
                accept='image/*,image/jpeg,image/png,image/svg'
                onChange={previewFile}
              />

              <p>Фото новости...</p>

              <BtnLoad />
            </label>
          </fieldset>

          {/* инпут ввода заголовка новости */}
          <div className={st.wrap_title}>
            <p className={title !== '' ? st.wrap_title_p : st.wrap_title_p_hide}>Заголовок</p>

            <fieldset className={st.fieldset_title}>
              <label className={st.label_title}>
                <input
                  className={st.input_title}
                  type='text'
                  name='title'
                  value={title}
                  placeholder='Заголовок новости'
                  onChange={(e) => setTitle(e.target.value)}
                />
              </label>
            </fieldset>
          </div>

          {/* инпут ввода анонса новости */}
          <div className={st.wrap_title}>
            <p className={announce !== '' ? st.wrap_title_p : st.wrap_title_p_hide}>Анонс</p>

            <fieldset className={st.fieldset_title}>
              <label className={st.label_title}>
                <input
                  className={st.input_title}
                  type='text'
                  name='announce'
                  value={announce}
                  placeholder='Анонс новости'
                  onChange={(e) => setAnnounce(e.target.value)}
                />
              </label>
            </fieldset>
          </div>

          {/* инпут ввода новости */}
          <div className={st.wrap_title}>
            <p className={editorValue !== '' ? st.wrap_title_p : st.wrap_title_p_hide}>Текст</p>


            <Editor
              apiKey='4wtzqhvlph75vbd3kofhrprf5cx68r4ujrf59ujf8hujuer1'

              initialValue={initialText}
              outputFormat='text'

              onEditorChange={(newValue, editor) => {
                setEditorValue(newValue);
                setEditorText(editor.getContent({ format: 'text' }));
              }}

              onInit={(evt, editor) => {
                setEditorText(editor.getContent({ format: 'text' }));
              }}

              value={editorValue}

              init={{
                // plugins: 'anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount checklist mediaembed casechange export formatpainter pageembed linkchecker a11ychecker tinymcespellchecker permanentpen powerpaste advtable advcode editimage tableofcontents footnotes mergetags autocorrect typography inlinecss',
                menubar: false,
                plugins: 'link',
                toolbar: 'undo redo blocks | bold italic underline forecolor backcolor | link unlink | alignleft aligncenter alignright alignjustify lineheight | checklist bullist numlist indent outdent | removeformat',
                height: '280px',
                language: 'ru',
                skin: 'oxide-dark',
                content_css: 'dark'
              }}
            />
          </div>

          {/* инпут ввода даты новости */}
          <div className={st.wrap_title}>
            <p className={newsdate !== '' ? st.wrap_title_p : st.wrap_title_p_hide}>Дата публикации</p>

            <fieldset className={st.fieldset_date}>
              <label className={st.label_title}>
                <input
                  className={st.input_date}
                  type='date'
                  name='newsdate'
                  value={newsdate}
                  placeholder='Дата публикации'
                  onChange={(e) => setNewsdate(e.target.value)}
                />

                <div className={st.input_after}></div>
              </label>
            </fieldset>
          </div>

          <button
            className={st.btn_submit}
            onClick={handleFormSubmit}
            disabled={title !== '' && announce !== '' && newsdate !== '' && editorValue !== '' ? false : true}
          >Добавить</button>
        </form>
      </div>
    </div>
  )
};
