import st from './DeptLiftDown.module.css';
import { useEffect, useState } from 'react';
import { ReactComponent as IconSetting } from '../../../../img/icon_setting.svg';
// import { liftDown_list } from './data/liftDown_list_json.js';
import { v4 as uuidv4 } from 'uuid';
import { LiftDownPreview } from './components/LiftDownPreview/LiftDownPreview.jsx';
import { useDispatch, useSelector } from 'react-redux';
import { fetchLiftDownList } from 'src/store/liftdown/getLiftDownList';

export const DeptLiftDown = () => {
  const dispatch = useDispatch();

  // список заявок в store
  let { requestDocList } = useSelector(state => state.liftDownListReducer.dataLiftDownList);
  console.log('requestDocList: ', requestDocList);
  // статус  выполнения запроса на получение списка заявок
  const { status } = useSelector(state => state.liftDownListReducer);

  // состояние списка для функций фильтрации (возможно не понадобится)
  const [requestDocListToFilter, setRequestDocListToFilter] = useState([]);

  // массивы для формирования списков с открытыми (всеми) и закрытыми заявками
  let listClosedStatement = [];
  let listOpenedStatement = [];

  // кнопки "открытые" "закрытые" заявки - активн и неактивн
  const [openClosedBtn, setOpenClosedBtn] = useState(false);
  // показывает скрывает панель фильтров
  const [filter_list_show, setFilter_list_show] = useState(false);
  // состояние списка - все заявки или только закрытые
  const [listStatements, setListStatements] = useState(requestDocListToFilter || []);

  // фильтры поиска
  const [filterForIdStatement, setFilterForIdStatement] = useState('');
  const [filterForNameClient, setFilterForNameClient] = useState('');
  const [filterForFactorynum, setFilterForFactorynum] = useState('');
  const [filterForEquip, setFilterForEquip] = useState('');
  const [filterForAddressInstall, setFilterForAddressInstall] = useState('');
  const [filterForStatusStatment, setFilterForStatusStatment] = useState('');
  const [filterForDateStatment, setFilterForDateStatment] = useState('');

  // функция очистки всех полей поиска
  const clearFields = () => {
    setFilterForIdStatement('');
    setFilterForNameClient('');
    setFilterForFactorynum('');
    setFilterForEquip('');
    setFilterForAddressInstall('');
    setFilterForStatusStatment('');
    setFilterForDateStatment('');
  }

  // функция очистки фильтров и возврат к исходному списку (для крестика внутри инпута фильтра)
  const clearFilter = () => {
    clearFields();

    if (openClosedBtn) {
      setListStatements(listClosedStatement);
    }

    if (!openClosedBtn) {
      setListStatements(listOpenedStatement);
    }
  };

  // функция получает массив с открытыми заявками
  const getOpenedStatement = () => {
    requestDocListToFilter?.forEach(item => {
      if (item.status !== 'CLOSED') {
        listOpenedStatement.push(item);
      }
    })
  };

  // функция получает массив с закрытыми заявками
  const getClosedStatement = () => {
    requestDocListToFilter?.forEach(item => {
      if (item.status === 'CLOSED') {
        listClosedStatement.push(item);
      }
    })
  };

  // функция отдает список заявок со статусом все кроме "Закрыта"
  const listOpened = () => {
    setListStatements(listOpenedStatement);
    setCurrentPageNumber(1);
    setOpenClosedBtn(false);

    // очистка полей фильтров
    clearFields();
  };

  // функция отдает список заявок со статусом "Закрыта"
  const listClosed = () => {
    setListStatements(listClosedStatement);
    // console.log('listClosedStatement: ', listClosedStatement);
    setCurrentPageNumber(1);
    setOpenClosedBtn(true);

    // очистка полей фильтров
    clearFields();
  };


  // ФИЛЬТРЫ
  // функция поиска по uid
  const searchId = (e) => {
    setFilterForIdStatement(e.target.value);
    let newListStatements = [];

    setFilterForNameClient('');
    setFilterForFactorynum('');
    setFilterForEquip('');
    setFilterForAddressInstall('');
    setFilterForStatusStatment('');
    setFilterForDateStatment('');

    if (openClosedBtn) {
      listClosedStatement.forEach(item => {
        if (item.uid?.includes(e.target.value)) {
          newListStatements.push(item);
        }
      })
    }

    if (!openClosedBtn) {
      listOpenedStatement.forEach(item => {
        if (item.uid?.includes(e.target.value)) {
          newListStatements.push(item);
        }
      })
    }

    setListStatements(newListStatements);

    // это условие добавлено на случай если нет item.... вообще / очередность важна
    if (e.target.value === '') clearFilter()

    setCurrentPageNumber(1);
  };

  // функция поиска по organization.name
  const searchShortTitle = (e) => {
    setFilterForNameClient(e.target.value);
    let newListStatements = [];

    setFilterForIdStatement('');
    setFilterForFactorynum('');
    setFilterForEquip('');
    setFilterForAddressInstall('');
    setFilterForStatusStatment('');
    setFilterForDateStatment('');



    if (openClosedBtn) {
      listClosedStatement.forEach(item => {
        if (item.organization?.name?.includes(e.target.value)) {
          newListStatements.push(item);
        }
      })
    }

    if (!openClosedBtn) {
      listOpenedStatement.forEach(item => {
        if (item.organization?.name?.includes(e.target.value)) {
          newListStatements.push(item);
        }
      })
    }

    setListStatements(newListStatements);

    // это условие добавлено на случай если нет item.... вообще / очередность важна
    if (e.target.value === '') clearFilter()

    setCurrentPageNumber(1);
  };

  // функция поиска по factorynumber
  const searchFactorynumber = (e) => {
    setFilterForFactorynum(e.target.value);
    let newListStatements = [];

    setFilterForIdStatement('');
    setFilterForNameClient('');
    setFilterForEquip('');
    setFilterForAddressInstall('');
    setFilterForStatusStatment('');
    setFilterForDateStatment('');

    if (openClosedBtn) {
      listClosedStatement.forEach(item => {
        if (item.equipmentOfOrganization?.equipment?.factorynumber?.includes(e.target.value)) {
          newListStatements.push(item);
        }
      })
    }

    if (!openClosedBtn) {
      listOpenedStatement.forEach(item => {
        if (item.equipmentOfOrganization?.equipment?.factorynumber?.includes(e.target.value)) {
          newListStatements.push(item);
        }
      })
    }

    setListStatements(newListStatements);

    // это условие добавлено на случай если нет item.... вообще / очередность важна
    if (e.target.value === '') clearFilter()

    setCurrentPageNumber(1);
  };

  // функция поиска по typeOfEquipment
  const searchEquip = (e) => {
    setFilterForEquip(e.target.value);
    let newListStatements = [];

    setFilterForIdStatement('');
    setFilterForNameClient('');
    setFilterForFactorynum('');
    setFilterForAddressInstall('');
    setFilterForStatusStatment('');
    setFilterForDateStatment('');

    if (openClosedBtn) {
      listClosedStatement.forEach(item => {
        if (item.equipmentOfOrganization?.equipment?.typeOfEquipment?.includes(e.target.value)) {
          newListStatements.push(item);
        }
        if (e.target.value === 'Все') {
          newListStatements.push(item);
        }
      })
    }

    if (!openClosedBtn) {
      listOpenedStatement.forEach(item => {
        if (item.equipmentOfOrganization?.equipment?.typeOfEquipment?.includes(e.target.value)) {
          newListStatements.push(item);
        }
        if (e.target.value === 'Все') {
          newListStatements.push(item);
        }
      })
    }

    setListStatements(newListStatements);

    // это условие добавлено на случай если нет item.... вообще / очередность важна
    if (e.target.value === '') clearFilter()

    setCurrentPageNumber(1);
  };

  // функция поиска по installationaddress
  const searchAddressinstall = (e) => {
    setFilterForAddressInstall(e.target.value);
    let newListStatements = [];

    setFilterForIdStatement('');
    setFilterForNameClient('');
    setFilterForFactorynum('');
    setFilterForEquip('');
    setFilterForStatusStatment('');
    setFilterForDateStatment('');

    if (openClosedBtn) {
      listClosedStatement.forEach(item => {
        if (
          item.equipmentOfOrganization?.installationaddress ?
            Object.values(item.equipmentOfOrganization?.installationaddress).join('').includes(e.target.value) : null
        ) {
          newListStatements.push(item);
        }
      })
    }

    if (!openClosedBtn) {
      listOpenedStatement.forEach(item => {
        if (
          item.equipmentOfOrganization?.installationaddress ?
            Object.values(item.equipmentOfOrganization?.installationaddress).join('').includes(e.target.value) : null
        ) {
          newListStatements.push(item);
        }
      })
    }

    setListStatements(newListStatements);

    // это условие добавлено на случай если нет item.... вообще / очередность важна
    if (e.target.value === '') clearFilter()

    setCurrentPageNumber(1);
  };

  // функция поиска по statusstate
  const searchStatementstatus = (e) => {
    setFilterForStatusStatment(e.target.value);
    let newListStatements = [];

    setFilterForIdStatement('');
    setFilterForNameClient('');
    setFilterForFactorynum('');
    setFilterForEquip('');
    setFilterForAddressInstall('');
    setFilterForDateStatment('');

    if (openClosedBtn) {
      listClosedStatement.forEach(item => {
        if (item.status?.includes(e.target.value)) {
          newListStatements.push(item);
        }
        if (e.target.value === 'Все') {
          newListStatements.push(item);
        }
      })
    }

    if (!openClosedBtn) {
      listOpenedStatement.forEach(item => {
        if (item.status?.includes(e.target.value)) {
          newListStatements.push(item);
        }
        if (e.target.value === 'Все') {
          newListStatements.push(item);
        }
      })
    }

    setListStatements(newListStatements);

    // это условие добавлено на случай если нет item.... вообще / очередность важна
    if (e.target.value === '') clearFilter()

    setCurrentPageNumber(1);
  };

  // функция поиска по dateinit
  const searchStatementdate = (e) => {
    setFilterForDateStatment(e.target.value);
    let newListStatements = [];

    setFilterForIdStatement('');
    setFilterForNameClient('');
    setFilterForFactorynum('');
    setFilterForEquip('');
    setFilterForAddressInstall('');
    setFilterForStatusStatment('');

    if (openClosedBtn) {
      listClosedStatement.forEach(item => {
        if (item.date?.includes(e.target.value)) {
          newListStatements.push(item);
        }
      })
    }

    if (!openClosedBtn) {
      listOpenedStatement.forEach(item => {
        if (item.date?.includes(e.target.value)) {
          newListStatements.push(item);
        }
      })
    }

    setListStatements(newListStatements);

    // это условие добавлено на случай если нет item.... вообще / очередность важна
    if (e.target.value === '') clearFilter()

    setCurrentPageNumber(1);
  };

  // ===== пагинация =====
  // номер текущей страницы
  const [currentPageNumber, setCurrentPageNumber] = useState(1);

  // количество заявок на странице
  const statementsQuantity = 4;

  // индекс последней заявки
  const lastStatementIndex = currentPageNumber * statementsQuantity;

  // индекс первой заявки
  const firstStatementIndex = lastStatementIndex - statementsQuantity;

  // список элементов текущей страницы
  const statementsCurrentList = listStatements?.slice(firstStatementIndex, lastStatementIndex);

  // общее количество заявок
  const totalStatementsQuantity = listStatements?.length;

  // список номеров-кнопок страниц
  let pages = [];
  for (let i = 1; i <= Math.ceil(totalStatementsQuantity / statementsQuantity); i++) {
    pages.push(i);
  }

  // функция кнопки следующая страница
  const nextPage = () => {
    setCurrentPageNumber(currentPageNumber !== pages.length ? prev => prev + 1 : prev => prev);
  };

  // функция кнопки предыдущая страница
  const prevPage = () => {
    setCurrentPageNumber(currentPageNumber > 1 ? prev => prev - 1 : prev => prev);
  };
  // ===== пагинация =====

  // если в store нет списка requestDocList (заявки), то диспатчим его в store
  useEffect(() => {
    if (!requestDocList) {
      dispatch(fetchLiftDownList());
    }

    if (status === 'success') {
      // сортируем  массив по ключу "date"(по порядковому номеру поля)
      setRequestDocListToFilter(Array.from(requestDocList).sort((a, b) => {
        return (a.date > b.date) - 0.5;
      }));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, status]);

  // при первичной загрузке и обновлении или при изменении статуса заявки отображаем 
  // список открытых или закрытых заявок в зависимости от "openClosedBtn"
  useEffect(() => {
    if (!openClosedBtn) setListStatements(listOpenedStatement);
    if (openClosedBtn) setListStatements(listClosedStatement);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, requestDocListToFilter]);


  if (status === 'success') {

    getOpenedStatement();
    getClosedStatement();

    return (
      <div className={st.deptclbase}>
        <div className={st.title_and_btn}>
          <div className={st.title_group}>
            <button
              className={openClosedBtn ? st.title_btn_enab : st.title_btn_disab}
              onClick={listOpened}>Открытые</button>
            <button
              className={openClosedBtn ? st.title_btn_disab : st.title_btn_enab}
              onClick={listClosed}>Закрытые</button>
          </div>

          <div className={st.btn_group}>

            <button
              className={st.btn_filter}
              onClick={() => { setFilter_list_show(!filter_list_show) }}>
              Фильтры

              <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1.5 6.75h21M5.25 12h13.5m-9 5.25h4.5" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </button>
          </div>
        </div>

        {/* заголовки фильтров */}
        <ul className={st.table_header}>
          <li className={st.header_item}>ID</li>
          <li className={st.header_item}>Наименование клиента</li>
          <li className={st.header_item}>Заводской номер</li>
          <li className={st.header_item}>Оборудование</li>
          <li className={st.header_item}>Адрес</li>
          <li className={st.header_item}>Статус</li>
          <li className={st.header_item}>Дата заявки</li>
          <li className={st.header_item}><IconSetting /></li>
        </ul>

        {/* список фильтров */}
        <ul className={!filter_list_show ? st.filter_list_hidden : st.filter_list}>
          <li className={st.filter_list_item}>
            <input
              className={st.filter_input}
              type='text'
              name='filterForIdStatement'
              value={filterForIdStatement}
              onChange={searchId} />
            <span
              className={st.clear_input}
              onMouseDown={clearFilter}
            >
              <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 1L7 7M7 1L1 7" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
              </svg>
            </span></li>

          <li className={st.filter_list_item}>
            <input
              className={st.filter_input}
              type='text'
              name='filterForNameClient'
              value={filterForNameClient}
              onChange={searchShortTitle} />
            <span
              className={st.clear_input}
              onMouseDown={clearFilter}
            >
              <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 1L7 7M7 1L1 7" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
              </svg>
            </span></li>

          <li className={st.filter_list_item}>
            <input
              className={st.filter_input}
              type='text'
              name='filterForFactorynum'
              value={filterForFactorynum}
              onChange={searchFactorynumber} />
            <span
              className={st.clear_input}
              onMouseDown={clearFilter}
            >
              <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 1L7 7M7 1L1 7" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
              </svg>
            </span></li>

          <li className={st.filter_list_select}>
            <select
              className={st.filter_select}
              name='filterForEquip'
              value={filterForEquip}
              onChange={searchEquip}>
              <option value='Все'>Все</option>
              <option value='Elevator'>Лифт</option>
              <option value='Travolator'>Травалатор</option>
              <option value='Escalator'>Эскалатор</option>
            </select>
          </li>

          <li className={st.filter_list_item}>
            <input
              className={st.filter_input}
              type='text'
              name='filterForAddressInstall'
              value={filterForAddressInstall}
              onChange={searchAddressinstall} />
            <span
              className={st.clear_input}
              onMouseDown={clearFilter}
            >
              <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 1L7 7M7 1L1 7" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
              </svg>
            </span></li>

          <li className={st.filter_list_select}>
            <select
              className={st.filter_select}
              name='filterForStatusStatment'
              value={filterForStatusStatment}
              onChange={searchStatementstatus}
              disabled={openClosedBtn ? true : false}>
              <option value='Все'>Все</option>
              <option value='NEW'>Новая</option>
              <option value='INWORK'>В работе</option>
              {/* <option value='CLOSED'>Закрыта</option> */}
              <option value='REJECTED'>Отменена</option>
            </select>
          </li>

          <li className={st.filter_list_item}>
            <input
              className={st.filter_input}
              type='date'
              name='filterForDateStatment'
              value={filterForDateStatment}
              onChange={searchStatementdate} />
            <span
              className={st.clear_input}
              onMouseDown={clearFilter}
            >
              <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 1L7 7M7 1L1 7" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
              </svg>
            </span></li>
        </ul>

        {/* список заявок */}
        <ul className={st.statement_list_open}>
          {
            statementsCurrentList.length ?
              statementsCurrentList.map(item =>
                <LiftDownPreview
                  key={uuidv4()}
                  uid={item.uid} // это id заявки
                  organizationName={item.organization.name}
                  factorynumber={item.equipmentOfOrganization.equipment?.factorynumber}
                  typeOfEquipment={item.equipmentOfOrganization.equipment?.typeOfEquipment}
                  installationaddress={item.equipmentOfOrganization.installationaddress}
                  statusstate={item.status}
                  dateinit={item.date}
                  manufacturer={item.equipmentOfOrganization.equipment?.manufacturer.manufacturername}
                  brand={item.equipmentOfOrganization.equipment?.brand.brandname}
                  partname={item.part.partname}
                  partid={item.part.partid}
                />
              ) : <h3>Заявок не найдено!</h3>
          }
        </ul>

        {/* пагинация */}
        <div className={st.pagination}>
          <button
            className={statementsQuantity <= totalStatementsQuantity ? st.pagination_btn_prev : st.hidden}
            onClick={() => {
              prevPage();
              window.scrollTo(0, 0);
            }}
          >
          </button>
          {
            pages.map((item) => {
              return (
                <button
                  key={uuidv4()}
                  onClick={() => {
                    setCurrentPageNumber(item);
                    window.scrollTo(0, 0);
                  }}
                  className={
                    pages.length === 1 ? st.hidden :
                      item === currentPageNumber
                        ? st.pagination_item_active
                        : st.pagination_item
                  }>
                  {item}
                </button>
              );
            })
          }
          <button
            className={statementsQuantity <= totalStatementsQuantity ? st.pagination_btn_next : st.hidden}
            onClick={() => {
              nextPage();
              window.scrollTo(0, 0);
            }}
          >
          </button>
        </div>
      </div>
    )
  } else {
    return (
      <div className={st.lifdown}>
        <div className={st.title_and_btn}>
          <h3 className={st.title}>Заявки</h3>
        </div>

        <p>загрузка DeptLiftDown...</p>
      </div>
    );
  }
};
