import st from './EquipmentCard.module.css';
import { Overlay } from 'src/components/Overlay/Overlay';
import { useState } from 'react';
import { LkSpecifications } from '../LkSpecifications/LkSpecifications.jsx';
import { Link } from 'react-router-dom';
import { LkAddress } from '../LkAddress/LkAddress';
import { LkPayments } from '../LkPayments/LkPayments';
import { LkDetails } from '../LkDetails/LkDetails';

export const EquipmentCard = ({
  efouid,
  uuid,
  equid,
  efointid,

  status,
  factorynumber,
  typeOfEquipment,
  manufacturername,
  brandname,

  purpose,
  speed,
  loadcapacity,

  dateofacceptanceforwarranty,
  dateofcommissioning,
  passportlink,

  installationaddress,

  partsList,

  schedule,
}) => {
  const [modalLkSpecifications, setModalLkSpecifications] = useState(false);
  const [modalLkDetails, setModalLkDetails] = useState(false);
  const [modalLkAddress, setModalLkAddress] = useState(false);
  const [modalLkPayments, setModalLkPayments] = useState(false);

  return (
    <>
      <li className={st.card}>
        <div className={st.header}>
          <div className={st.wrap_1}>
            <p>{
              typeOfEquipment === 'Elevator' ? 'Лифт' :
                typeOfEquipment === 'Escalator' ? 'Эскалатор' :
                  typeOfEquipment === 'Travolator' ? 'Травалатор' : null
            }</p>
            <p>{efointid}</p>
          </div>

          <h3 className={st.title}>{manufacturername}</h3>
          <h4 className={st.brand}>{brandname}</h4>
        </div>

        <div className={st.body}>
          <ul className={st.list_info}>
            <li className={st.item_info_icon}>
              {
                status === 'Entered'
                  ? <div className={`${st.icon} ${st.icon_load} ${st.opacity1}`}></div>
                  : <div className={`${st.icon} ${st.icon_security} ${st.opacity1}`}></div>
              }
              <p>{
                status === 'Entered' ? 'Заведен' :
                  status === 'UnderWarranty' ? 'На гарантии' :
                    status === 'Extended' ? 'Расширенный' : 'нет данных'
              }</p>
            </li>

            <li className={st.item_info}>
              <span>Дата прозводства</span>
              <p>{dateofcommissioning}</p>
            </li>

            <li className={st.item_info}>
              <span>Дата запуска</span>
              <p>{dateofacceptanceforwarranty}</p>
            </li>

            <li className={st.item_info}>
              <span>Заводской номер</span>
              <p>{factorynumber}</p>
            </li>
          </ul>

          <ul className={st.list_links}>
            <li
              className={st.item_link}
              onClick={() => setModalLkSpecifications(true)}
            >
              <div className={`${st.icon} ${st.specific}`}></div>
              <p>Характеристики</p>
            </li>

            <li className={st.item_link}>
              {
                passportlink !== '' ?
                  <Link className={st.item_link_link} to={passportlink} target='_blank'>
                    <div className={`${st.icon} ${st.passport}`}></div>
                    <p>Паспорт</p>
                  </Link> :
                  <div className={st.item_link_notlink} to={passportlink} target='_blank'>
                    <div className={`${st.icon} ${st.passport_no}`}></div>
                    <p className={st.opacity02}>Паспорт</p>
                  </div>
              }
            </li>

            <li
              className={st.item_link}
              onClick={() => setModalLkDetails(true)}
            >
              <div className={`${st.icon} ${st.details}`}></div>
              <p>Детали</p>
            </li>

            <li
              className={st.item_link}
              onClick={() => setModalLkAddress(true)}
            >
              <div className={`${st.icon} ${st.address}`}></div>
              <p>Адрес</p>
            </li>

            <li
              className={st.item_link}
              onClick={() => setModalLkPayments(true)}
            >
              <div className={`${st.icon} ${st.payments}`}></div>
              <p>Платежи</p>
            </li>
          </ul>
        </div>
      </li>

      <Overlay className={!modalLkSpecifications ? st.overlay_hide : st.overlay_show}>
        <LkSpecifications
          closeModal={() => setModalLkSpecifications(false)}
          appointment={
            purpose === 'Passenger' ? 'Пассажирский' :
              purpose === 'Cargo' ? 'Грузовой' :
                purpose === 'CargoPassenger' ? 'Грузопассажирский' : 'нет данных'
          }
          speed={speed}
          loadcapacity={loadcapacity}
          productdate={dateofcommissioning}
          launchdate={dateofacceptanceforwarranty}
          passportlink={passportlink}
        />
      </Overlay>

      <Overlay className={!modalLkDetails ? st.overlay_hide : st.overlay_show}>
        <LkDetails
          closeModal={() => setModalLkDetails(false)}
          efouid={efouid}
          uuid={uuid}
          equid={equid}
          typeOfEquipment={typeOfEquipment}
          factorynumber={factorynumber}
          manufacturername={manufacturername}
          brandname={brandname}
          partsList={partsList}
        />
      </Overlay>

      <Overlay className={!modalLkAddress ? st.overlay_hide : st.overlay_show}>
        <LkAddress
          closeModal={() => setModalLkAddress(false)}
          installationaddress={installationaddress}
        />
      </Overlay>

      <Overlay className={!modalLkPayments ? st.overlay_hide : st.overlay_show}>
        <LkPayments
          closeModal={() => setModalLkPayments(false)}
          schedule={schedule}
        />
      </Overlay>
    </>
  )
};
