import st from './SignIn.module.css';
import { ReactComponent as Logo } from '../../img/logo.svg';
import { Link, Outlet } from 'react-router-dom';

export const SignIn = () => {

  return (
    <main className={st.main_signin}>
      <Link className={st.background_img} to='/'>
        <Logo className={st.logo} />
      </Link>

      <div className={st.reg_autor}>
        <Outlet />
      </div>
    </main>
  )
};
