import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { OURCLIENTS_LIST } from "src/consts.js";

// получаем список наших клиентов
export const fetchOurclientsList = createAsyncThunk(
  'ourclientsList/fetchOurclientsList',

  async () => {
    const response = await fetch(OURCLIENTS_LIST, {
      method: 'GET',
      headers: {
        'Content-type': 'application/json; charset=UTF8',
        'User-Agent': 'LiftupSite/1.0.0',
      }
    }
    );

    const data = await response.json();
    // console.log('data: ', data);

    return data;
  }
);

const initialState = {
  status: 'idle',
  dataOurclientsList: {},
  error: null,
};

const ourclientsListSlice = createSlice({
  name: 'ourclientsList',
  initialState,
  reducers: {
    removeOurclientsList: (state) => {
      state.dataOurclientsList = {};
    }
  },

  extraReducers: (builder) => {
    builder
      // при загрузке сайта
      .addCase(fetchOurclientsList.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(fetchOurclientsList.fulfilled, (state, action) => {
        state.status = 'success';
        state.dataOurclientsList = action.payload;
      })
      .addCase(fetchOurclientsList.rejected, (state, action) => {
        state.status = 'failed';
        state.dataOurclientsList = action.error.message;
      })
  }
});


export const { removeOurclientsList } = ourclientsListSlice.actions;

export default ourclientsListSlice.reducer;
