import st from './RegCode.module.css';
import store from 'src/store/store';
import { Link, useNavigate } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { fetchConfirmPhone } from './fetches/fetchConfirmPhone';
import { fetchConfirmCode } from './fetches/fetchConfirmCode';


export const RegCode = () => {
  const bodyAddNewOrg = useSelector(state => state.regReducer.dataReg);

  const [isVisible, setIsVisible] = useState(false);
  const [count, setCount] = useState(0);

  // поля ввода кода подтверждения
  const [codeSimbol1, setCodeSimbol1] = useState('');
  const [codeSimbol2, setCodeSimbol2] = useState('');
  const [codeSimbol3, setCodeSimbol3] = useState('');
  const [codeSimbol4, setCodeSimbol4] = useState('');

  const [loginstatus, setLoginstatus] = useState(5);

  const navigate = useNavigate();

  // ссылки на поля ввода кода
  const inputRef1 = useRef();
  const inputRef2 = useRef();
  const inputRef3 = useRef();
  const inputRef4 = useRef();

  // счетчик
  const countPlus = () => {
    setCount(count + 1);
  }

  // обработчик кнопки повторной отправки
  const handleResendButton = () => {
    countPlus();

    setIsVisible(false);

    setTimeout(() => {
      setIsVisible(true)
    }, 6000);
  };

  // при загрузке страницы показываем уведомление о том, что
  // "Новый код для этого номера можно будет запросить через 01:00"
  // затем показываем кнопку "Отправить код ещё раз"
  useEffect(() => {
    handleResendButton();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // включаем возможность повторной отправки через час
  useEffect(() => {
    setTimeout(() => {
      setCount(0);
    }, 3600000);
  }, []);

  // номер телефона для отправки проверочного кода повторно
  const phoneNumObj = {
    'phone': bodyAddNewOrg.userPhone,
  }

  // отправляем номер телефона для полученние проверочного кода повторно
  const handlerСonfirmPhone = (e) => {
    handleResendButton();
    fetchConfirmPhone(phoneNumObj);
  };

  // объект тела запроса по отправке проверочного кода
  const bodyCodeFromSMS = {
    'loginName': bodyAddNewOrg.userPhone,
    'smscode': codeSimbol1 + codeSimbol2 + codeSimbol3 + codeSimbol4,
    'smshash': ''
  }

  // отправляем код подтверждения полученный на телефон
  const handlerСonfirmСodeAndAddNewOrg = (e) => {
    e.preventDefault();

    // этот запрос двойной - сначала код, потом данные добавления организации
    fetchConfirmCode(bodyCodeFromSMS, bodyAddNewOrg);

    navigate('/superlift/main');
  };

  // запрет ввода всего кроме цифр и контроль инпутов
  const handleInputCode = (e, stateInput) => {
    stateInput(e.target.value.replace(/\D/g, ''))
  };

  // перемещаем фокус на соседний инпут
  useEffect(() => {
    if (codeSimbol1.length >= 1) {
      inputRef1.current.nextSibling.focus();
    }

    if (codeSimbol2.length >= 1) {
      inputRef2.current.nextSibling.focus();
    }

    if (codeSimbol3.length >= 1) {
      inputRef3.current.nextSibling.focus();
    }

    if (codeSimbol4.length >= 1) {
      inputRef4.current.blur();
    }
  }, [codeSimbol1, codeSimbol2, codeSimbol3, codeSimbol4]);

  // подписка на изменение значения "loginstatus"
  useEffect(() => {
    store.subscribe(() => setLoginstatus(store.getState().authReducer?.dataAuth?.loginstatus));
    if (loginstatus === undefined) setLoginstatus(5);
  }, [loginstatus]);

  return (
    <>
      <button className={st.btn_back} onClick={() => navigate(-1)}></button>

      <div className={st.container}>
        <h2 className={st.title}>Подтвердите номер телефона</h2>

        <p className={st.description}>Введите код из СМС</p>

        <Link className={st.close_btn} to='/'></Link>

        {/* форма с проверочным кодом из смс */}
        <form className={st.pass_rec_form}>
          <div className={st.note_wrap}>
            <div className={st.input_wrap}>
              <input
                ref={inputRef1}
                className={st.input_code}
                type='text'
                name='codeSimbol1'
                value={codeSimbol1}
                onChange={(e) => handleInputCode(e, setCodeSimbol1)}
                maxLength='1'
              />

              <input
                ref={inputRef2}
                className={st.input_code}
                type='text'
                name='codeSimbol2'
                value={codeSimbol2}
                onChange={(e) => handleInputCode(e, setCodeSimbol2)}
                maxLength='1'
              />

              <input
                ref={inputRef3}
                className={st.input_code}
                type='text'
                name='codeSimbol3'
                value={codeSimbol3}
                onChange={(e) => handleInputCode(e, setCodeSimbol3)}
                maxLength='1'
              />

              <input
                ref={inputRef4}
                className={st.input_code}
                type='text'
                name='codeSimbol4'
                value={codeSimbol4}
                onChange={(e) => handleInputCode(e, setCodeSimbol4)}
                maxLength='1'
              />
            </div>

            <p className={loginstatus !== 5 ? st.note_code : st.note_hide}>Введен неверный код</p>

          </div>

          <button
            className={st.button_submit}
            onClick={handlerСonfirmСodeAndAddNewOrg}
            disabled={
              codeSimbol1 &&
                codeSimbol2 &&
                codeSimbol3 &&
                codeSimbol4 ? false : true
            }
          >Подтвердить</button>
        </form>

        {count >= 6
          ? <p className={st.notice}>Вы&nbsp;превысили количество запросов кода подтверждения. Попробуйте через 55&nbsp;минут</p>
          : !isVisible
            ? <p className={st.notice}>Новый код для этого номера можно будет запросить через 01:00</p>
            : <button
              className={st.button_forgot}
              onClick={handlerСonfirmPhone}
            >Отправить код ещё раз</button>
        }

        {/* вспомогательная кнопка */}
        <button
          className={st.button_submit}
          onClick={() => navigate('/signin/author')}
        >Шаг вперед</button>

      </div>
    </>
  )
};

