import st from './ServiceEdit.module.css';
import { ReactComponent as BtnClose } from '../../../../../../img/btn_close.svg';
import { ReactComponent as BtnLoad } from '../../../../../../img/btn_folder.svg';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { fetchServiceCentersAddEdit } from '../../fetches/fetchAddEdit';
import { fetchServicecentersList } from 'src/store/servicecenters/getServicecentersList';
import { fetchEditWithoutImgServiceCenter } from '../../fetches/fetchEditWithoutImg';

export const ServiceEdit = ({
  closeModal,
  targetUid,
  targetLogo,
  targetName,
  targetNote,
  targetCity,
  targetAddress,
  targetJobschedule,
  targetPhone,
  targetEmail,
  targetCountnum
}) => {
  // инпуты "превью лого"
  const [imagePreviewEdit, setImagePreviewEdit] = useState(true);
  const [imagePreview, setImagePreview] = useState(null);
  // инпут "Наименование"
  const [title, setTitle] = useState('');
  // инпут "Город"
  const [serviceCity, setServiceCity] = useState('');
  // инпут "Адрес"
  const [serviceAddress, setServiceAddress] = useState('');
  // инпут "Режим работы"
  const [serviceJobschedule, setServiceJobschedule] = useState('');
  // инпут "Телефон"
  const [servicePhone, setServicePhone] = useState('');
  // инпут "Почта"
  const [serviceEmail, setServiceEmail] = useState('');

  // ссылка на инпут с кнопкой для выбора лого для загрузки на ПК
  const fileSelection = useRef(null);

  // данные выбранного для отправки файла
  const [selectedFileDouble, setSelectedFileDouble] = useState('');

  const dispatch = useDispatch();

  useEffect(() => {
    setTitle(targetName);
    setServiceCity(targetCity);
    setServiceAddress(targetAddress);
    setServiceJobschedule(targetJobschedule);
    setServicePhone(targetPhone);
    setServiceEmail(targetEmail);

  }, [targetName, targetCity, targetAddress, targetJobschedule, targetPhone, targetEmail]);

  // выбирает новый файл на ПК и вставляет в превью
  const previewFile = (e) => {
    const reader = new FileReader();

    const selectedFile = e.target.files[0];

    setSelectedFileDouble(selectedFile);

    if (selectedFile) {
      reader.readAsDataURL(selectedFile);
    }

    reader.onload = (readerEvent) => {
      if (selectedFile.type.includes('image')) {
        setImagePreview(readerEvent.target.result);
      }
    };
  };

  // удаляем поле "превью добавить файл"
  const clearPreviewFile = (e) => {
    e.preventDefault();
    setImagePreview(null);

    // старый вариант при котором появляется окно со старым файлом для ред-ния
    // setImagePreviewEdit(true);
  };

  // удаляем поле "превью редактировать файл"
  const clearPreviewFileEdit = (e) => {
    e.preventDefault();
    setImagePreviewEdit(false);
  };

  // данные для второго запроса
  const serviceCentersDataBody = {
    'uid': targetUid,
    'logo': '',
    'name': title,
    'note': '',
    'city': serviceCity,
    'address': serviceAddress,
    'jobschedule': serviceJobschedule,
    'email': serviceEmail,
    'phone': servicePhone,
    'countnum': targetCountnum
  };

  // отправляет форму
  const handleFormSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('uploadedFile', selectedFileDouble);

    if (formData.get('uploadedFile') === '') {
      console.log('пустая строка');

      await fetchEditWithoutImgServiceCenter(serviceCentersDataBody);
    }

    if (formData.get('uploadedFile') !== '') {
      console.log('НЕЕЕЕЕ пустая строка: ', formData.get('uploadedFile'));

      await fetchServiceCentersAddEdit(formData, serviceCentersDataBody);
    }

    dispatch(fetchServicecentersList());

    closeModal();

    console.log('форма отправлена');
  };

  return (
    <div className={st.clientsedit_wrap}>
      <button className={st.btn_close} onClick={closeModal}>
        <BtnClose />
      </button>

      <div className={st.clientsedit}>
        <h3 className={st.title}>Редактирование сервисного центра</h3>

        <form className={st.form}>

          {/* блок с превью выбранного для редактирования логотипа сервиса */}
          <div className={imagePreviewEdit === true ? st.image_preview_show : st.image_preview_hide}>
            <p className={st.image_preview_show_p}>Логотип</p>

            <div className={st.image_preview}>
              <img src={targetLogo} alt={targetName} />
              <button className={st.btn_delete} onClick={clearPreviewFileEdit}></button>
            </div>
          </div>

          {/* блок с превью выбранного для загрузки нового лого сервиса */}
          <div className={imagePreview !== null ? st.image_preview_show : st.image_preview_hide}>
            <p className={st.image_preview_show_p}>Логотип сервиса</p>

            <div className={st.image_preview}>
              {imagePreview !== null && <img src={imagePreview} alt={targetName} />}
              <button className={st.btn_delete} onClick={clearPreviewFile}></button>
            </div>
          </div>

          {/* инпут с кнопкой для выбора нового лого сервиса для загрузки с ПК */}
          <fieldset className={
            imagePreview === null && imagePreviewEdit === false
              ? st.fieldset_show : st.fieldset_hide}>
            <label className={st.label_image}>
              <input
                ref={fileSelection}
                className={st.input_image}
                type='file'
                name='image'
                accept='image/*,image/jpeg,image/png,image/svg'
                onChange={previewFile}
              />
              <p>Выбрать логотип</p>
              <BtnLoad />
            </label>
          </fieldset>

          {/* инпут редактирования наименования сервисного центра */}
          <div className={st.wrap_title}>
            <p className={title !== '' ? st.wrap_title_p : st.wrap_title_p_hide}>
              Наименование</p>

            <fieldset className={st.fieldset_title}>
              <label className={st.label_title}>
                <input
                  className={st.input_title}
                  type='text'
                  name='title'
                  value={title}
                  placeholder='Наименование'
                  onChange={(e) => setTitle(e.target.value)}
                />
              </label>
            </fieldset>
          </div>

          {/* инпут редактирования города сервисного центра */}
          {/* <div className={st.wrap_title}>
            <p className={serviceCity !== '' ? st.wrap_title_p : st.wrap_title_p_hide}>Город</p>

            <fieldset className={st.fieldset_title}>
              <label className={st.label_title}>
                <input
                  className={st.input_title}
                  type='text'
                  name='serviceCity'
                  value={serviceCity}
                  placeholder='Город'
                  onChange={(e) => setServiceCity(e.target.value)}
                />
              </label>
            </fieldset>
          </div> */}

          {/* инпут редактирования выбора города сервисного центра */}
          <div className={st.wrap_select}>
            <p className={serviceCity !== '' ? st.hint_show : st.hint_hide}>
              Выбор города</p>

            <div className={st.select_wrap}>
              <label className={st.label}>
                <select
                  className={st.select}
                  name='serviceCity'
                  value={serviceCity}

                  onChange={(e) => setServiceCity(e.target.value)}>
                  <option value=''>Выберите город</option>
                  <option value='Москва'>Москва</option>
                  <option value='Санкт-Петербург'>Санкт-Петербург</option>
                  <option value='Казань'>Казань</option>
                  <option value='Уфа'>Уфа</option>
                  <option value='Омск'>Омск</option>
                  <option value='Екатеринбург'>Екатеринбург</option>
                  <option value='Тюмень'>Тюмень</option>
                  <option value='Новосибирск'>Новосибирск</option>
                </select>
              </label>
            </div>
          </div>

          {/* инпут редактирования адреса сервисного центра */}
          <div className={st.wrap_title}>
            <p className={serviceAddress !== '' ? st.wrap_title_p : st.wrap_title_p_hide}>Адрес</p>

            <fieldset className={st.fieldset_title}>
              <label className={st.label_title}>
                <input
                  className={st.input_title}
                  type='text'
                  name='serviceAddress'
                  value={serviceAddress}
                  placeholder='Адрес'
                  onChange={(e) => setServiceAddress(e.target.value)}
                />
              </label>
            </fieldset>
          </div>

          {/* инпут редактирования графика работы сервисного центра */}
          <div className={st.wrap_title}>
            <p className={serviceJobschedule !== '' ? st.wrap_title_p : st.wrap_title_p_hide}>
              График работы</p>

            <fieldset className={st.fieldset_title}>
              <label className={st.label_title}>
                <input
                  className={st.input_title}
                  type='text'
                  name='serviceJobschedule'
                  value={serviceJobschedule}
                  placeholder='График работы'
                  onChange={(e) => setServiceJobschedule(e.target.value)}
                />
              </label>
            </fieldset>
          </div>

          {/* инпут редактирования номера телефона сервисного центра */}
          <div className={st.wrap_title}>
            <p className={servicePhone !== '' ? st.wrap_title_p : st.wrap_title_p_hide}>
              Номер телефона</p>

            <fieldset className={st.fieldset_title}>
              <label className={st.label_title}>
                <input
                  className={st.input_title}
                  type='text'
                  name='servicePhone'
                  value={servicePhone}
                  placeholder='Номер телефона'
                  onChange={(e) => setServicePhone(e.target.value)}
                />
              </label>
            </fieldset>
          </div>

          {/* инпут редактирования E-mail сервисного центра */}
          <div className={st.wrap_title}>
            <p className={serviceEmail !== '' ? st.wrap_title_p : st.wrap_title_p_hide}>
              E-mail</p>

            <fieldset className={st.fieldset_title}>
              <label className={st.label_title}>
                <input
                  className={st.input_title}
                  type='text'
                  name='serviceEmail'
                  value={serviceEmail}
                  placeholder='E-mail'
                  onChange={(e) => setServiceEmail(e.target.value)}
                />
              </label>
            </fieldset>
          </div>

          <button
            className={st.btn_submit}
            onClick={handleFormSubmit}
          >Сохранить</button>
        </form>
      </div>
    </div>
  )
};
