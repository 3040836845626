import st from './About.module.css';
import { Advantages } from './components/Advantages/Advantages';
import { Geography } from './components/Geography/Geography';
import { RusProduct } from './components/RusProduct/RusProduct';

export const About = () => {

  return (
    <main className={st.main}>

      <div className={st.wrap_sl}>
        <div className={st.signal_line}></div>
      </div>

      <section className={`${st.section_main} container`}>
        <h1 className={st.title}>Добро пожаловать в мир инноваций и надежности LIFTUP!</h1>

        <div className={st.flashlight1}></div>
        <div className={st.flashlight2}></div>

        <div className={st.advantages_list}>
          <div className={st.advantages_item}>
            Мы — передовая компания, разработавшая уникальную платформу для предоставления пожизненной гарантии на основные узлы и агрегаты лифтов и эскалаторов.
          </div>
          <div className={st.wrap_item}>
            <div className={st.advantages_item_2}>
              Наша бизнес-модель основана на оплате гарантии, которая позволяет нам закупать и хранить запчасти на гарантийный срок. Это инновационный подход, который до сегодняшнего момента практически не использовался в индустрии лифтов и эскалаторов.
            </div>
            <div className={st.advantages_item}>
              Наша цель — обеспечить максимальную надежность и безопасность для наших клиентов, предоставляя высококачественный сервис и продукты.
            </div>
          </div>
        </div>
      </section>

      <Advantages />

      <RusProduct />

      <Geography />
    </main>
  )
};
