import st from './SectionNews.module.css';
import { v4 as uuidv4 } from 'uuid';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchNewsList } from 'src/store/news/getNewsList';
import { NewsCardSecNews } from './components/NewsCardSecNews/NewsCardSecNews';
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, A11y, Scrollbar } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';


export const SectionNews = () => {
  // берем список новостей из хранилища
  const { newsList } = useSelector(state => state.newsListReducer.dataNewsList);
  // получаем статус загрузки
  const { status } = useSelector(state => state.newsListReducer);

  // ссылка на список новостей для управления им не через "Swaper"
  // const sliderList = useRef(null);

  // кнопки управления слайдера
  const btnPrevRef = useRef(null);
  const btnNextRef = useRef(null);
  // состояние для включения кнопок, чтобы работали
  const [_, setInit] = useState();

  const dispatch = useDispatch();

  // получаем список новостей с сервера при отсутствии его в хранилище
  useEffect(() => {
    if (!newsList) {
      dispatch(fetchNewsList());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  if (status === 'success') {

    return (
      <section className={`${st.sectionnews} container`}>
        <div className={st.container}>
          <h3 className={st.sectionnews_title}>Новости</h3>
          <div className={st.control_arrows}>
            <button
              ref={btnPrevRef}
            // onClick={() => {
            //   // сдвиг слайдера в крайнюю левую позицию (с анимацией)
            //   sliderList.current.firstChild.style.transform = 'translate3d(0px, 0px, 0px)';
            //   sliderList.current.firstChild.style.transitionDuration = '300ms';
            //   // сдвиг скроллбара в крайнюю левую позицию (с анимацией)
            //   sliderList.current.children[1].firstChild.style.transform = 'translate3d(0px, 0px, 0px)';
            //   sliderList.current.children[1].firstChild.style.transitionDuration = '300ms';
            // }}
            >
              <svg width="28" height="26" viewBox="0 0 28 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M28 13.0186H2M2 13.0186L10.0269 1M2 13.0186L10.0269 25" stroke="white" strokeWidth="2" />
              </svg>
            </button>
            <button
              ref={btnNextRef}
            // onClick={() => {
            //   // условия по ширине экрана
            //   if (window.innerWidth < 1024) {
            //     // сдвиг слайдера в крайнюю правую позицию (с анимацией)
            //     sliderList.current.firstChild.style.transform = 'translate3d(-2890px, 0px, 0px)';
            //   }
            //   if (window.innerWidth < 1280 && window.innerWidth > 1024) {
            //     // сдвиг слайдера в крайнюю правую позицию (с анимацией)
            //     sliderList.current.firstChild.style.transform = 'translate3d(-2030px, 0px, 0px)';
            //     // сдвиг скроллбара в крайнюю правую позицию (с анимацией)
            //     sliderList.current.children[1].firstChild.style.transform = 'translate3d(720px, 0px, 0px)';
            //   }
            //   if (window.innerWidth >= 1280) {
            //     // сдвиг слайдера в крайнюю правую позицию (с анимацией)
            //     sliderList.current.firstChild.style.transform = 'translate3d(-1630px, 0px, 0px)';
            //   }

            //   // условия по ширине экрана
            //   if (window.innerWidth < 1500) {
            //     // сдвиг слайдера в крайнюю правую позицию (с анимацией)
            //     sliderList.current.children[1].firstChild.style.transform = 'translate3d(620px, 0px, 0px)';
            //   }
            //   if (window.innerWidth < 1670 && window.innerWidth > 1500) {
            //     // сдвиг скроллбара в крайнюю правую позицию (с анимацией)
            //     sliderList.current.children[1].firstChild.style.transform = 'translate3d(720px, 0px, 0px)';
            //   }
            //   if (window.innerWidth >= 1670) {
            //     // сдвиг скроллбара в крайнюю правую позицию (с анимацией)
            //     sliderList.current.children[1].firstChild.style.transform = 'translate3d(800px, 0px, 0px)';
            //   }

            //   // плавное движение
            //   sliderList.current.firstChild.style.transitionDuration = '300ms';
            //   sliderList.current.children[1].firstChild.style.transitionDuration = '300ms';
            // }}
            >
              <svg width="28" height="26" viewBox="0 0 28 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 13.019h26m0 0L17.973 1M26 13.019L17.973 25" stroke="#fff" strokeWidth="2" />
              </svg>
            </button>
          </div>
        </div>

        <div className={st.slider_news}>
          <Swiper
            // ref={sliderList}
            className='news_swiper'
            modules={[Navigation, Pagination, A11y, Scrollbar]}
            slidesPerView={4}
            spaceBetween={30}
            onSlideChange={() => { }}
            onSwiper={(swiper) => { }}
            // grabCursor={true}
            direction={'horizontal'}
            // loop='true'

            // это кнопки
            navigation={{
              prevEl: btnPrevRef.current,
              nextEl: btnNextRef.current,
            }}

            // для работы кнопок
            onInit={() => setInit(true)}
            // шаг листания слайдера
            slidesPerGroup={3}

            breakpoints={
              {
                1280: {
                  slidesPerView: 4,
                  spaceBetween: 30
                },
                1024: {
                  slidesPerView: 3,
                  spaceBetween: 20
                },
                600: {
                  slidesPerView: 2,
                  slidesPerGroup: 2
                },
                300: {
                  slidesPerView: 1,
                  slidesPerGroup: 1
                }
              }
            }
            // pagination={{ clickable: true }}
            scrollbar={{ draggable: true }}
          >
            {
              newsList.map((item, i) => (
                i < 7 ?
                  <SwiperSlide key={uuidv4()}>
                    <NewsCardSecNews
                      newsid={item.newsid}
                      newsname={item.newsname}
                      newspreview={item.newspreview}
                    />
                  </SwiperSlide>
                  : null
              ))
            }

            <SwiperSlide key={uuidv4()}>
              <div className={st.all_news}>
                <Link className={st.all_news_link} to={'./news/newslists'}></Link>
                <Link className={st.all_news_btn} to={'./news/newslists'}></Link>
                <h5 className={st.all_news_title}>
                  Смотреть все новости
                </h5>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </section>
    )
  } else {
    return (
      <section className={`${st.sectionnews} container`}>
        <div className={st.container}>
          <h3 className={st.sectionnews_title}>Новости</h3>
          <div className={st.control_arrows}>
            <button disabled>
              <svg width="28" height="26" viewBox="0 0 28 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M28 13.0186H2M2 13.0186L10.0269 1M2 13.0186L10.0269 25" stroke="white" strokeWidth="2" />
              </svg>
            </button>
            <button disabled>
              <svg width="28" height="26" viewBox="0 0 28 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 13.019h26m0 0L17.973 1M26 13.019L17.973 25" stroke="#fff" strokeWidth="2" />
              </svg>
            </button>
          </div>
        </div>

        <div className={st.slider_news}>
          <ul className={st.news_list_nodata}>
            <li className={st.all_news} style={{ width: '280px' }}>
              <h5 className={st.all_news_title}>загрузка данных</h5>
            </li>
          </ul>
        </div>
      </section>
    )
  }
};
