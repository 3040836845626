import st from './LkLiftDown.module.css';
import { useEffect, useState } from 'react';
// import { liftDown_list } from './data/liftDown_list_json.js';
import { v4 as uuidv4 } from 'uuid';
import { LiftDownCard } from './components/LiftDownCard/LiftDownCard.jsx';
import { ReactComponent as Plus } from '../../../../img/plus.svg';
import { Overlay } from 'src/components/Overlay/Overlay';
import { LiftDownAdd } from './components/LiftDownAdd/LiftDownAdd.jsx';
import { useDispatch, useSelector } from 'react-redux';
import { fetchLiftDownListOneOrg } from 'src/store/liftdown/getLiftDownList';


export const LkLiftDown = () => {
  const dispatch = useDispatch();

  // откр закр модалка "Добавить заявку"
  const [modalAdd, setModalAdd] = useState(false);

  // список заявок в store
  const { requestDocList } = useSelector(state => state.liftDownListReducer.dataLiftDownList);
  // uuid организации для передачи в запросе получения списка оборудования
  const { uuid } = useSelector(state => state.authReducer.dataAuth.organization);
  // статус  выполнения запроса на получение списка оборудования
  const { status } = useSelector(state => state.liftDownListReducer);

  // массивы для формирования списков с открытыми (всеми) и закрытыми заявками
  let listClosedStatement = [];
  let listOpenedStatement = [];

  // кнопки "открытые" "закрытые" заявки - активн и неактивн
  const [openClosedBtn, setOpenClosedBtn] = useState(false);
  // показывает скрывает панель фильтров
  const [filter_list_show, setFilter_list_show] = useState(false);
  // состояние содержания оперируемых (отображаемых) списков
  const [listStatements, setListStatements] = useState(requestDocList || []);

  // состояние списка оборудования после фильтрации по номеру efointid
  // const [equipmentOfOrganizatioListToFilter, setEquipmentOfOrganizatioListToFilter] = useState([]);
  // console.log('equipmentOfOrganizatioListToFilter: ', equipmentOfOrganizatioListToFilter);

  // фильтры поиска
  const [filterForIdStatement, setFilterForIdStatement] = useState('');
  const [filterForFactorynum, setFilterForFactorynum] = useState('');
  const [filterForEquip, setFilterForEquip] = useState('');
  const [filterForManufacture, setFilterForManufacture] = useState('');
  const [filterForBrand, setFilterForBrand] = useState('');
  const [filterForDetailname, setFilterForDetailname] = useState('');
  const [filterForDetailId, setFilterForDetailId] = useState('');
  const [filterForAddressInstall, setFilterForAddressInstall] = useState('');
  const [filterForStatusStatment, setFilterForStatusStatment] = useState('');
  const [filterForDateStatment, setFilterForDateStatment] = useState('');


  // функция очистки всех полей поиска
  const clearFields = () => {
    setFilterForIdStatement('');
    setFilterForFactorynum('');
    setFilterForEquip('');
    setFilterForManufacture('');
    setFilterForBrand('');
    setFilterForDetailname('');
    setFilterForDetailId('');
    setFilterForAddressInstall('');
    setFilterForStatusStatment('');
    setFilterForDateStatment('');
  };

  // функция очистки фильтров и возврат к исходному списку (для крестика внутри инпута фильтра)
  const clearFilter = () => {
    clearFields();

    if (openClosedBtn) {
      setListStatements(listClosedStatement);
    }

    if (!openClosedBtn) {
      setListStatements(listOpenedStatement);
    }
  };

  // функция получает массив с открытыми заявками
  const getOpenedStatement = () => {
    requestDocList?.forEach(item => {
      if (item.status !== 'CLOSED') {
        listOpenedStatement.push(item);
      }
    })
  };

  // функция получает массив с закрытыми заявками
  const getClosedStatement = () => {
    requestDocList?.forEach(item => {
      if (item.status === 'CLOSED') {
        listClosedStatement.push(item);
      }
    })
  };

  // функция отдает список заявок со статусом все кроме "Закрыта"
  const listOpened = () => {
    setListStatements(listOpenedStatement);
    setCurrentPageNumber(1);
    setOpenClosedBtn(false);

    // очистка полей фильтров
    clearFields();
  };

  // функция отдает список заявок со статусом "Закрыта"
  const listClosed = () => {
    setListStatements(listClosedStatement);
    setCurrentPageNumber(1);
    setOpenClosedBtn(true);

    // очистка полей фильтров
    clearFields();
  };

  // ФИЛЬТРЫ
  // 1 функция поиска по uid
  const searchId = (e) => {
    setFilterForIdStatement(e.target.value);
    let newListStatements = [];

    setFilterForFactorynum('');
    setFilterForEquip('');
    setFilterForManufacture('');
    setFilterForBrand('');
    setFilterForDetailname('');
    setFilterForDetailId('');
    setFilterForAddressInstall('');
    setFilterForStatusStatment('');
    setFilterForDateStatment('');

    if (openClosedBtn) {
      console.log('openClosedBtn: ', openClosedBtn);
      listClosedStatement.forEach(item => {
        if (item.uid.includes(e.target.value)) {
          newListStatements.push(item);
        }
      })
    }

    if (!openClosedBtn) {
      listOpenedStatement.forEach(item => {
        if (item.uid.includes(e.target.value)) {
          newListStatements.push(item);
        }
      })
    }

    setListStatements(newListStatements);

    setCurrentPageNumber(1);
  };

  // 2 функция поиска по "Заводскому номеру"
  const searchFactorynumber = (e) => {
    setFilterForFactorynum(e.target.value);
    let newListStatements = [];

    setFilterForIdStatement('');
    setFilterForEquip('');
    setFilterForManufacture('');
    setFilterForBrand('');
    setFilterForDetailname('');
    setFilterForDetailId('');
    setFilterForAddressInstall('');
    setFilterForStatusStatment('');
    setFilterForDateStatment('');

    if (openClosedBtn) {
      listClosedStatement.forEach(item => {
        if (item.equipmentOfOrganization.equipment.factorynumber.includes(e.target.value)) {
          newListStatements.push(item);
        }
      })
    }

    if (!openClosedBtn) {
      listOpenedStatement.forEach(item => {
        if (item.equipmentOfOrganization.equipment.factorynumber.includes(e.target.value)) {
          newListStatements.push(item);
        }
      })
    }

    setListStatements(newListStatements);

    setCurrentPageNumber(1);
  };

  // 3 функция поиска по оборудованию
  const searchEquip = (e) => {
    setFilterForEquip(e.target.value);
    let newListStatements = [];

    setFilterForIdStatement('');
    setFilterForFactorynum('');
    setFilterForManufacture('');
    setFilterForBrand('');
    setFilterForDetailname('');
    setFilterForDetailId('');
    setFilterForAddressInstall('');
    setFilterForStatusStatment('');
    setFilterForDateStatment('');

    if (openClosedBtn) {
      listClosedStatement.forEach(item => {
        if (item.equipmentOfOrganization.equipment.typeOfEquipment.includes(e.target.value)) {
          newListStatements.push(item);
        }
        if (e.target.value === 'Все') {
          newListStatements.push(item);
        }
      })
    }

    if (!openClosedBtn) {
      listOpenedStatement.forEach(item => {
        if (item.equipmentOfOrganization.equipment.typeOfEquipment.includes(e.target.value)) {
          newListStatements.push(item);
        }
        if (e.target.value === 'Все') {
          newListStatements.push(item);
        }
      })
    }

    setListStatements(newListStatements);

    setCurrentPageNumber(1);
  };

  // 4 функция поиска по "Производителю"
  const searchManufacture = (e) => {
    setFilterForManufacture(e.target.value);
    let newListStatements = [];

    setFilterForIdStatement('');
    setFilterForFactorynum('');
    setFilterForEquip('');
    setFilterForBrand('');
    setFilterForDetailname('');
    setFilterForDetailId('');
    setFilterForAddressInstall('');
    setFilterForStatusStatment('');
    setFilterForDateStatment('');

    if (openClosedBtn) {
      listClosedStatement.forEach(item => {
        if (item.equipmentOfOrganization.equipment.manufacturer.manufacturername.includes(e.target.value)) {
          newListStatements.push(item);
        }
      })
    }

    if (!openClosedBtn) {
      listOpenedStatement.forEach(item => {
        if (item.equipmentOfOrganization.equipment.manufacturer.manufacturername.includes(e.target.value)) {
          newListStatements.push(item);
        }
      })
    }

    setListStatements(newListStatements);

    setCurrentPageNumber(1);
  };

  // 5 функция поиска по "Марке"
  const searchBrand = (e) => {
    setFilterForBrand(e.target.value);
    let newListStatements = [];

    setFilterForIdStatement('');
    setFilterForFactorynum('');
    setFilterForEquip('');
    setFilterForManufacture('');
    setFilterForDetailname('');
    setFilterForDetailId('');
    setFilterForAddressInstall('');
    setFilterForStatusStatment('');
    setFilterForDateStatment('');

    if (openClosedBtn) {
      listClosedStatement.forEach(item => {
        if (item.equipmentOfOrganization.equipment.brand.brandname.includes(e.target.value)) {
          newListStatements.push(item);
        }
      })
    }

    if (!openClosedBtn) {
      listOpenedStatement.forEach(item => {
        if (item.equipmentOfOrganization.equipment.brand.brandname.includes(e.target.value)) {
          newListStatements.push(item);
        }
      })
    }

    setListStatements(newListStatements);

    setCurrentPageNumber(1);
  };

  // 6 функция поиска по "Детали"
  const searchDetail = (e) => {
    setFilterForDetailname(e.target.value);
    let newListStatements = [];

    setFilterForIdStatement('');
    setFilterForFactorynum('');
    setFilterForEquip('');
    setFilterForManufacture('');
    setFilterForBrand('');
    setFilterForDetailId('');
    setFilterForAddressInstall('');
    setFilterForStatusStatment('');
    setFilterForDateStatment('');

    if (openClosedBtn) {
      listClosedStatement.forEach(item => {
        if (item.part.partname === e.target.value) {
          newListStatements.push(item);
        }
        if (e.target.value === '') {
          newListStatements.push(item);
        }
      })
    }

    if (!openClosedBtn) {
      listOpenedStatement.forEach(item => {
        if (item.part.partname === e.target.value) {
          newListStatements.push(item);
        }
        if (e.target.value === '') {
          newListStatements.push(item);
        }
      })
    }

    setListStatements(newListStatements);

    setCurrentPageNumber(1);
  };

  // 7 функция поиска по "Номеру детали"
  const searchDetailid = (e) => {
    setFilterForDetailId(e.target.value);
    let newListStatements = [];

    setFilterForIdStatement('');
    setFilterForFactorynum('');
    setFilterForEquip('');
    setFilterForManufacture('');
    setFilterForBrand('');
    setFilterForDetailname('');
    setFilterForAddressInstall('');
    setFilterForStatusStatment('');
    setFilterForDateStatment('');

    if (openClosedBtn) {
      listClosedStatement.forEach(item => {
        if (item.part.partid?.includes(e.target.value)) {
          newListStatements.push(item);
        }
      })
    }

    if (!openClosedBtn) {
      listOpenedStatement.forEach(item => {
        if (item.part.partid?.includes(e.target.value)) {
          newListStatements.push(item);
        }
      })
    }

    setListStatements(newListStatements);

    setCurrentPageNumber(1);
  };

  // 8 функция поиска по "Адресу"
  const searchAddressinstall = (e) => {
    setFilterForAddressInstall(e.target.value);
    let newListStatements = [];

    setFilterForIdStatement('');
    setFilterForFactorynum('');
    setFilterForEquip('');
    setFilterForManufacture('');
    setFilterForBrand('');
    setFilterForDetailname('');
    setFilterForDetailId('');
    setFilterForStatusStatment('');
    setFilterForDateStatment('');

    if (openClosedBtn) {
      listClosedStatement.forEach(item => {
        if (item.equipmentOfOrganization.installationaddress.city.includes(e.target.value)) {
          newListStatements.push(item);
        } else if (item.equipmentOfOrganization.installationaddress.street.includes(e.target.value)) {
          newListStatements.push(item);
        }
      })
    }

    if (!openClosedBtn) {
      listOpenedStatement.forEach(item => {
        if (item.equipmentOfOrganization.installationaddress.city.includes(e.target.value)) {
          newListStatements.push(item);
        } else if (item.equipmentOfOrganization.installationaddress.street.includes(e.target.value)) {
          newListStatements.push(item);
        }
      })
    }

    setListStatements(newListStatements);

    setCurrentPageNumber(1);
  };

  // 9 функция поиска по "Статусу"
  const searchStatementstatus = (e) => {
    setFilterForStatusStatment(e.target.value);
    let newListStatements = [];

    setFilterForIdStatement('');
    setFilterForFactorynum('');
    setFilterForEquip('');
    setFilterForManufacture('');
    setFilterForBrand('');
    setFilterForDetailname('');
    setFilterForDetailId('');
    setFilterForAddressInstall('');
    setFilterForDateStatment('');

    if (openClosedBtn) {
      listClosedStatement.forEach(item => {
        if (item.status.includes(e.target.value)) {
          newListStatements.push(item);
        }
        if (e.target.value === 'Все') {
          newListStatements.push(item);
        }
      })
    }

    if (!openClosedBtn) {
      listOpenedStatement.forEach(item => {
        if (item.status.includes(e.target.value)) {
          newListStatements.push(item);
        }
        if (e.target.value === 'Все') {
          newListStatements.push(item);
        }
      })
    }

    setListStatements(newListStatements);

    setCurrentPageNumber(1);
  };

  // 10 функция поиска по "Дате заявки"
  const searchStatementdate = (e) => {
    setFilterForDateStatment(e.target.value);
    let newListStatements = [];

    setFilterForIdStatement('');
    setFilterForFactorynum('');
    setFilterForEquip('');
    setFilterForManufacture('');
    setFilterForBrand('');
    setFilterForDetailname('');
    setFilterForDetailId('');
    setFilterForAddressInstall('');
    setFilterForStatusStatment('');

    if (openClosedBtn) {
      listClosedStatement.forEach(item => {
        if (item.date.includes(e.target.value)) {
          newListStatements.push(item);
        }
      })
    }

    if (!openClosedBtn) {
      listOpenedStatement.forEach(item => {
        if (item.date.includes(e.target.value)) {
          newListStatements.push(item);
        }
      })
    }

    setListStatements(newListStatements);

    setCurrentPageNumber(1);
  };

  // ===== пагинация =====
  // номер текущей страницы
  const [currentPageNumber, setCurrentPageNumber] = useState(1);

  // количество заявок на странице
  const statementsQuantity = 4;

  // индекс последней заявки
  const lastStatementIndex = currentPageNumber * statementsQuantity;

  // индекс первой заявки
  const firstStatementIndex = lastStatementIndex - statementsQuantity;

  // список элементов текущей страницы
  const statementsCurrentList = listStatements.slice(firstStatementIndex, lastStatementIndex);

  // общее количество заявок
  const totalStatementsQuantity = listStatements.length;

  // список номеров-кнопок страниц
  let pages = [];
  for (let i = 1; i <= Math.ceil(totalStatementsQuantity / statementsQuantity); i++) {
    pages.push(i);
  }

  // функция кнопки следующая страница
  const nextPage = () => {
    setCurrentPageNumber(currentPageNumber !== pages.length ? prev => prev + 1 : prev => prev);
  };

  // функция кнопки предыдущая страница
  const prevPage = () => {
    setCurrentPageNumber(currentPageNumber > 1 ? prev => prev - 1 : prev => prev);
  };
  // ===== пагинация =====

  // если в store нет списка requestDocList, то диспатчим его в store
  useEffect(() => {
    if (!requestDocList) {
      // сurrentOrganizationUuid это uuid выбранной организации для построения списка ее оборудования
      dispatch(fetchLiftDownListOneOrg(uuid));
    }

    // сортировка массива заявок по дате (возможно не понадобится)
    // if (requestDocList && status === 'success') {
    // сортируем  массив по дате
    // setEquipmentOfOrganizatioListToFilter(Array.from(requestDocList).sort((a, b) => {
    //   return new Date(b.date) - new Date(a.date);
    // }));
    // сортируем  массив по ключу "id" (по порядковому номеру поля)
    // setEquipmentOfOrganizatioListToFilter(Array.from(requestDocList).sort((a, b) => {
    //   return (+a.efointid > +b.efointid) - 0.5;
    // }));
    // }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, requestDocList]);

  useEffect(() => {
    setListStatements(listOpenedStatement);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);


  if (status === 'success') {
    getOpenedStatement();
    getClosedStatement();

    return (
      <>
        <div className={st.deptclbase}>
          <div className={st.title_and_btn}>
            <div className={st.title_group}>
              <button
                className={openClosedBtn ? st.title_btn_enab : st.title_btn_disab}
                onClick={listOpened}>Открытые</button>

              <button
                className={openClosedBtn ? st.title_btn_disab : st.title_btn_enab}
                onClick={listClosed}>Закрытые</button>
            </div>

            <div className={st.btn_group}>
              <button
                className={st.btn_add}
                onClick={() => setModalAdd(true)}
              ><Plus />Подать&nbsp;заявку</button>

              <button
                className={st.btn_filter}
                onClick={() => { setFilter_list_show(!filter_list_show) }}>
                Фильтры

                <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1.5 6.75h21M5.25 12h13.5m-9 5.25h4.5" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </button>
            </div>
          </div>

          {/* заголовки таблицы */}
          <ul className={st.table_header}>
            <li className={st.header_item}>ID</li>
            <li className={st.header_item}>Заводской №</li>
            <li className={st.header_item}>Оборуд... <span>Оборудование</span></li>
            <li className={st.header_item}>Произв... <span>Производитель</span></li>
            <li className={st.header_item}>Марка</li>
            <li className={st.header_item}>Деталь</li>
            <li className={st.header_item}>Номер детали</li>
            <li className={st.header_item}>Адрес</li>
            <li className={st.header_item}>Статус</li>
            <li className={st.header_item}>Дата заявки</li>
          </ul>

          {/* список фильтров */}
          <div className={!filter_list_show ? st.filter_list_hidden : st.filter_list}>

            {/* 1 по id */}
            <div className={st.wrap_filter_item}>

              <p className={filterForIdStatement.length !== 0 ? st.placeholder_above : st.hidden}>ID</p>

              <div className={st.filter_list_item}>
                <input
                  className={st.filter_input}
                  type='search'
                  name='filterForIdStatement'
                  value={filterForIdStatement}
                  onChange={searchId}
                  placeholder='ID'
                />
                <span
                  className={st.clear_input}
                  onMouseDown={clearFilter}
                >
                  <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 1L7 7M7 1L1 7" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
                  </svg>
                </span>
              </div>
            </div>

            {/* 2 по "Заводскому номеру" */}
            <div className={st.wrap_filter_item}>

              <p className={filterForFactorynum.length !== 0 ? st.placeholder_above : st.hidden}>Заводской&nbsp;номер</p>

              <div className={st.filter_list_item}>
                <input
                  className={st.filter_input}
                  type='search'
                  name='filterForFactorynum'
                  value={filterForFactorynum}
                  onChange={searchFactorynumber}
                  placeholder='Заводской номер'
                />
                <span
                  className={st.clear_input}
                  onMouseDown={clearFilter}
                >
                  <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 1L7 7M7 1L1 7" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
                  </svg>
                </span>
              </div>
            </div>

            {/* 3 по "Оборудованию" */}
            <div className={st.wrap_filter_item}>

              <p className={filterForEquip.length !== 0 ? st.placeholder_above : st.hidden}>Оборудование</p>

              <div className={st.filter_list_select}>
                <select
                  className={st.filter_select}
                  name='filterForEquip'
                  value={filterForEquip}
                  onChange={searchEquip}>
                  <option value=''>Оборудование</option>
                  <option value='Elevator'>Лифт</option>
                  <option value='Travolator'>Травалатор</option>
                  <option value='Escalator'>Эскалатор</option>
                </select>
              </div>
            </div>

            {/* 4 по "Производителю" */}
            <div className={st.wrap_filter_item}>

              <p className={filterForManufacture.length !== 0 ? st.placeholder_above : st.hidden}>Производитель</p>

              <div className={st.filter_list_select}>
                <select
                  className={st.filter_select}
                  name='filterForManufacture'
                  value={filterForManufacture}
                  onChange={searchManufacture}>
                  <option value=''>Производитель</option>
                  <option value='KONE'>KONE</option>
                  <option value='BLT'>BLT</option>
                  <option value='OTIS'>OTIS</option>
                </select>
              </div>
            </div>

            {/* 5 по "Марке" */}
            <div className={st.wrap_filter_item}>

              <p className={filterForBrand.length !== 0 ? st.placeholder_above : st.hidden}>Марка</p>

              <div className={st.filter_list_item}>
                <input
                  className={st.filter_input}
                  type='search'
                  name='filterForBrand'
                  value={filterForBrand}
                  onChange={searchBrand}
                  placeholder='Марка'
                />
                <span
                  className={st.clear_input}
                  onMouseDown={clearFilter}
                >
                  <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 1L7 7M7 1L1 7" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
                  </svg>
                </span>
              </div>
            </div>

            {/* 6 по "Детали" */}
            <div className={st.wrap_filter_item}>

              <p className={filterForDetailname.length !== 0 ? st.placeholder_above : st.hidden}>Детали</p>

              <div className={st.filter_list_select}>
                <select
                  className={st.filter_select}
                  name='filterForDetailname'
                  value={filterForDetailname}
                  onChange={searchDetail}>
                  <option value=''>Детали</option>
                  <option value='Первая деталь'>Первая деталь</option>
                  <option value='Вторая деталь'>Вторая деталь</option>
                  <option value='Третья деталь'>Третья деталь</option>
                  <option value='Четвертая деталь'>Четвертая деталь</option>
                </select>
              </div>
            </div>

            {/* 7 по "Номеру детали" */}
            <div className={st.wrap_filter_item}>

              <p className={filterForDetailId.length !== 0 ? st.placeholder_above : st.hidden}>Номер детали</p>

              <div className={st.filter_list_item}>
                <input
                  className={st.filter_input}
                  type='search'
                  name='filterForDetailId'
                  value={filterForDetailId}
                  onChange={searchDetailid}
                  placeholder='Номер детали'
                />
                <span
                  className={st.clear_input}
                  onMouseDown={clearFilter}
                >
                  <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 1L7 7M7 1L1 7" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
                  </svg>
                </span>
              </div>
            </div>

            {/* 8 по "Адресу" */}
            <div className={st.wrap_filter_item}>

              <p className={filterForAddressInstall.length !== 0 ? st.placeholder_above : st.hidden}>Адрес</p>

              <div className={st.filter_list_item}>
                <input
                  className={st.filter_input}
                  type='search'
                  name='filterForAddressInstall'
                  value={filterForAddressInstall}
                  onChange={searchAddressinstall}
                  placeholder='Адрес'
                />
                <span
                  className={st.clear_input}
                  onMouseDown={clearFilter}
                >
                  <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 1L7 7M7 1L1 7" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
                  </svg>
                </span>
              </div>
            </div>

            {/* 9 по "Статусу" */}
            <div className={st.wrap_filter_item}>

              <p className={filterForStatusStatment.length !== 0 ? st.placeholder_above : st.hidden}>Статус</p>

              <div className={st.filter_list_select}>
                <select
                  className={st.filter_select}
                  name='filterForStatusStatment'
                  value={filterForStatusStatment}
                  onChange={searchStatementstatus}
                  disabled={openClosedBtn ? true : false}>
                  <option value=''>Статус</option>
                  <option value='NEW'>Новая</option>
                  <option value='INWORK'>В работе</option>
                  {/* <option value='CLOSED'>Закрыта</option> */}
                  <option value='REJECTED'>Отменена</option>
                </select>
              </div>
            </div>

            {/* 10 по "Дате заявки" */}
            <div className={st.wrap_filter_item}>

              <p className={filterForDateStatment.length !== 0 ? st.placeholder_above : st.hidden}>Дата заявки</p>
              <div className={st.filter_list_item}>
                <input
                  className={st.filter_input}
                  type='date'
                  name='filterForDateStatment'
                  value={filterForDateStatment}
                  onChange={searchStatementdate}
                  placeholder='Дата заявки'
                />
                <span
                  className={st.clear_input}
                  onMouseDown={clearFilter}
                >
                  <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 1L7 7M7 1L1 7" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
                  </svg>
                </span>
              </div>
            </div>

            {/* кнопка очистить фильтры */}
            <button
              className={st.clear_filters}
              onClick={clearFilter}
            >
              <svg width="14" height="14" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M24 0H21.75L12 9.75L2.25 0H2.95937e-06L0 2.25L9.75001 12L2.95937e-06 21.75V24H2.25L12 14.25L21.75 24L24 24V21.75L14.25 12L24 2.25V0Z" fill="currentColor" />
              </svg>
              Очистить фильтры
            </button>

            {/* кнопка показать результаты */}
            <button
              className={st.show_result}
              onClick={() => { setFilter_list_show(false) }}
            >
              Показать ({listStatements.length})
            </button>
          </div>

          {/* список заявок */}
          <ul className={st.statement_list_open}>
            {
              statementsCurrentList.length ?
                statementsCurrentList.map(item =>
                  <LiftDownCard
                    key={uuidv4()}
                    uid={item.uid}
                    factorynumber={item.equipmentOfOrganization.equipment?.factorynumber}
                    typeOfEquipment={item.equipmentOfOrganization.equipment?.typeOfEquipment}
                    manufacturer={item.equipmentOfOrganization.equipment?.manufacturer.manufacturername}
                    brand={item.equipmentOfOrganization.equipment?.brand.brandname}
                    partname={item.part.partname}
                    partid={item.part.partid}
                    installationaddress={item.equipmentOfOrganization.installationaddress}
                    statusstate={item.status}
                    dateinit={item.date}
                  />
                )
                : <h3>Заявок не найдено!</h3>
            }
          </ul>

          {/* пагинация */}
          <div className={st.pagination}>
            <button
              className={statementsQuantity <= totalStatementsQuantity ? st.pagination_btn_prev : st.hidden}
              onClick={() => {
                prevPage();
                window.scrollTo(0, 0);
              }}
            >
            </button>
            {
              pages.map((item) => {
                return (
                  <button
                    key={uuidv4()}
                    onClick={() => {
                      setCurrentPageNumber(item);
                      window.scrollTo(0, 0);
                    }}
                    className={
                      pages.length === 1 ? st.hidden :
                        item === currentPageNumber
                          ? st.pagination_item_active
                          : st.pagination_item
                    }>
                    {item}
                  </button>
                );
              })
            }
            <button
              className={statementsQuantity <= totalStatementsQuantity ? st.pagination_btn_next : st.hidden}
              onClick={() => {
                nextPage();
                window.scrollTo(0, 0);
              }}
            >
            </button>
          </div>
        </div>

        {/* оверлей с окном добавления заявки */}
        <Overlay className={!modalAdd ? st.overlay_hide : st.overlay_show}>
          <LiftDownAdd
            closeModal={() => setModalAdd(false)}
          />
        </Overlay>
      </>
    )
  } else {
    return (
      <div className={st.lifdown}>
        <div className={st.title_and_btn}>
          <h3 className={st.title}>Заявки</h3>
        </div>

        <p>загрузка LiftDown...</p>
      </div>
    );
  }
};
