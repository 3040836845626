import st from './LkSpecifications.module.css';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

export const LkSpecifications = ({
  closeModal,
  appointment,
  speed,
  loadcapacity,
  productdate,
  launchdate,
  passportlink
}) => {
  // технические характеристики
  const [editAppointment, setEditAppointment] = useState('');
  const [editSpeed, setEditSpeed] = useState('');
  const [editLoadcapacity, setEditLoadcapacity] = useState('');
  const [editProductdate, setEditProductdate] = useState('');
  const [editLaunchdate, setEditLaunchdate] = useState('');
  const [editPassport, setEditPassport] = useState('');
  

  useEffect(() => {
    // технические характеристики
    setEditAppointment(appointment);
    setEditSpeed(speed);
    setEditLoadcapacity(loadcapacity);
    setEditProductdate(productdate);
    setEditLaunchdate(launchdate);
    setEditPassport(passportlink.substring(passportlink.lastIndexOf('/') + 1));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={st.clientsedit_wrap}>
      <button
        className={st.btn_close}
        onClick={closeModal}>
      </button>

      <div className={st.clientsedit}>
        <div className={st.form}>
          <div className={st.ft_edit_requisites}>
            <h3 className={st.edit_title}>Технические характеристики</h3>

            {/* поле назначения оборудования */}
            <div className={st.edit_input}>
              <div className={st.edit_field_wrap}>
                <p className={st.edit_field_title}>Назначение</p>

                <p className={st.edit_field_text}>
                  {editAppointment}
                </p>
              </div>
            </div>

            {/* поле скорости оборудования */}
            <div className={st.edit_input}>
              <div className={st.edit_field_wrap}>
                <p className={st.edit_field_title}>Скорость</p>

                <p className={st.edit_field_text}>
                  {
                    editSpeed === 'SLOWEST' ?
                      '1,0 м/с' :
                      editSpeed === 'MEDIUMSLOW' ?
                        '1,6 м/с' :
                        editSpeed === 'SLOW' ?
                          '1,75 м/с' :
                          editSpeed === 'MEDIUM' ?
                            '2,0 м/с' :
                            editSpeed === 'FAST' ?
                              '2,5 м/с' :
                              editSpeed === 'SUPERFAST' ?
                                'Свыше 2,5 м/с' : 'Неизвестно'
                    }
                </p>
              </div>
            </div>

            {/* поле грузоподъемности оборудования */}
            <div className={st.edit_input}>
              <div className={st.edit_field_wrap}>
                <p className={st.edit_field_title}>Грузоподъемность</p>

                <p className={st.edit_field_text}>
                  {editLoadcapacity} кг
                </p>
              </div>
            </div>

            {/* поле даты производства */}
            <div className={st.edit_input}>
              <div className={st.edit_field_wrap}>
                <p className={st.edit_field_title}>Дата производства</p>

                <p className={st.edit_field_text}>{
                  editProductdate !== '' ? editProductdate : 'Не назначено'
                }</p>
              </div>
            </div>

            {/* поле даты запуска в эксплуатацию */}
            <div className={st.edit_input}>
              <div className={st.edit_field_wrap}>
                <p className={st.edit_field_title}>Дата запуска в эксплуатацию</p>

                <p className={st.edit_field_text}>{
                  editLaunchdate !== '' ? editLaunchdate : 'Не назначено'
                }</p>
              </div>
            </div>

            {/* поле пасспорта оборудования */}
            <div className={st.edit_input}>
              <div className={st.edit_field_wrap}>
                <p className={st.edit_field_title}>Паспорт</p>

                {
                  passportlink !== '' ?
                    <Link className={st.link} to={passportlink} target='_blank'>
                      <p className={st.edit_field_text}>
                        {editPassport ? passportlink.substring(passportlink.lastIndexOf('/') + 1) : 'Не загружен'}
                      </p>
                    </Link> :
                    <div className={st.link}>
                      <p className={st.edit_field_text}>
                        {editPassport ? passportlink.substring(passportlink.lastIndexOf('/') + 1) : 'Не загружен'}
                      </p>
                    </div>
                }
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  );
};
