import { configureStore } from '@reduxjs/toolkit';
import slidesListReducer from './slides/getSlides.js';
import newsListReducer from './news/getNewsList.js';
import docsListReducer from './docs/getDocs.js';
import docsFinListReducer from './docsfin/getDocsFin.js';
import ourclientsListReducer from './ourclients/getOurclientsList.js';
import servicecentersListReducer from './servicecenters/getServicecentersList.js';
import clientbaseListReducer from './clientbase/getClientbaseList.js';
import currentOrganizationReducer from './currentOrganization/currentOrganization.js';
import equipmentListReducer from './equipment/getEquipmentList.js';
import liftDownListReducer from './liftdown/getLiftDownList.js';
import authReducer from './authorization/auth.js';
import passRecoveryReducer from './authorization/passRecovery.js';
import regReducer from './registration/reg.js';
import changePassReducer from './changePass/changePass.js';


const store = configureStore({
  reducer: {
    slidesListReducer,
    newsListReducer,
    docsListReducer,
    docsFinListReducer,
    ourclientsListReducer,
    servicecentersListReducer,
    clientbaseListReducer,
    currentOrganizationReducer,
    equipmentListReducer,
    liftDownListReducer,
    authReducer,
    passRecoveryReducer,
    regReducer,
    changePassReducer,
  },

  // middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(???);
});

export default store;
