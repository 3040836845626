import st from './ServicePreview.module.css';
import { useEffect, useRef, useState } from 'react';
import { Overlay } from 'src/components/Overlay/Overlay';
import { ServiceEdit } from '../ServiceEdit/ServiceEdit';
import { useDispatch } from 'react-redux';
import { fetchServicecentersList } from 'src/store/servicecenters/getServicecentersList';
import { fetchServiceCenterDel } from '../../fetches/fetchDel';

export const ServicePreview = ({
  uid,
  logo,
  name,
  note,
  city,
  address,
  jobschedule,
  email,
  phone,
  countnum
}) => {
  // модальное окно закр откр
  const [modalEdit, setModalEdit] = useState(false);
  // поля для передачи пропсов в окно редактирования серв центра
  const [targetUid, setTargetUid] = useState('');
  const [targetLogo, setTargetLogo] = useState('');
  const [targetName, setTargetName] = useState('');
  const [targetNote, setTargetNote] = useState('');
  const [targetCity, setTargetCity] = useState('');
  const [targetAddress, setTargetAddress] = useState('');
  const [targetJobschedule, setTargetJobschedule] = useState('');
  const [targetPhone, setTargetPhone] = useState('');
  const [targetEmail, setTargetEmail] = useState('');
  const [targetCountnum, setTargetCountnum] = useState('');

  // ссылка на компонент с кнопками "редактировать - удалить"
  const refEditOrDel = useRef(null);

  // откр закр меню "редактировать - удалить"
  const [menuEditOrDel, setMenuEditOrDel] = useState(false);

  const dispatch = useDispatch();

  // закрываем меню по клику вне элемента
  useEffect(() => {
    let handler = (e) => {
      if (!refEditOrDel.current.contains(e.target)) {
        setMenuEditOrDel(false);
      }
    };

    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    }
  });

  
  // удаляем нашего клиента, закрываем меню, обновляем список сервисных центров
  const serviceCenterDel = async () => {

    setModalEdit(false);

    await fetchServiceCenterDel(uid);

    dispatch(fetchServicecentersList());
  };


  // показывает меню "редактировать удалить"
  const editOrDel = (e) => {
    e.preventDefault();

    setMenuEditOrDel(!menuEditOrDel);
  };

  // открывает окно "редактировать сервисный центр" выбранного элемента
  const editTargetElem = () => {
    setTargetUid(uid);

    setTargetLogo(logo);

    setTargetName(name);

    setTargetNote(note);

    setTargetCity(city);

    setTargetAddress(address);

    setTargetJobschedule(jobschedule);

    setTargetPhone(phone);

    setTargetEmail(email);

    setTargetCountnum(countnum);

    setModalEdit(true);
  };

  return (
    <>
      <li className={st.servcenters_item} id={uid}>
        <p className={st.servcenters_item_p}>{uid}</p>

        <h4 className={st.servcenters_item_h4}>{name}</h4>

        <p className={st.service_text}>{city}</p>

        <p className={st.service_text}>{address}</p>

        <div className={st.item_edit_del} ref={refEditOrDel}>
          <div className={st.item_btn} onClick={editOrDel}></div>

          <ul
            className={!menuEditOrDel ? st.btn_menu : st.btn_menu_show}

            onClick={(e) => { e.preventDefault() }}>
            <li className={st.btn_menu_item} onClick={editTargetElem}>Редактировать</li>

            <li className={st.btn_menu_item} onClick={serviceCenterDel}>Удалить</li>
          </ul>
        </div>
      </li>

      <Overlay className={!modalEdit ? st.overlay_hide : st.overlay_show}>
        <ServiceEdit
          closeModal={() => setModalEdit(false)}
          targetUid={targetUid}
          targetLogo={targetLogo}
          targetName={targetName}
          targetNote={targetNote}
          targetCity={targetCity}
          targetAddress={targetAddress}
          targetJobschedule={targetJobschedule}
          targetPhone={targetPhone}
          targetEmail={targetEmail}
          targetCountnum={targetCountnum}
        />
      </Overlay>
    </>
  )
};
