import st from './EditRequisites.module.css';
import { useDispatch } from 'react-redux';
import { fetchChangeData } from '../../fetches/fetchChangeData';
import { useLayoutEffect, useRef, useState } from 'react';
import { fetchUser } from 'src/store/authorization/auth';
import { Fias1 } from 'src/modules/FIAS/Fias1/Fias1';
import { Fias2 } from 'src/modules/FIAS/Fias2/Fias2';

export const EditRequisites = (
  {
    closeModal,
    dataAuth
  }
) => {
  const dispatch = useDispatch();

  const [orgFullName, setOrgFullName] = useState(
    Object.keys(dataAuth).length ?
      dataAuth.organization?.fullname :
      'нет данных'
  );
  const [orgShortName, setOrgShortName] = useState(
    Object.keys(dataAuth).length ?
      dataAuth.organization?.shortname :
      'нет данных'
  );
  const [orgINN, setOrgINN] = useState(
    Object.keys(dataAuth).length ?
      dataAuth.organization?.inn :
      'нет данных'
  );
  const [orgKPP, setOrgKPP] = useState(
    Object.keys(dataAuth).length ?
      dataAuth.organization?.fnsinn :
      'нет данных'
  );
  const [orgOGRN, setOrgOGRN] = useState(
    Object.keys(dataAuth).length ?
      dataAuth.organization?.serial :
      'нет данных'
  );
  // const [orgUrAddress, setOrgUrAddress] = useState(
  //   Object.keys(dataAuth).length ?
  //     dataAuth.organization?.address?.legaladdress :
  //     'нет данных'
  // );
  // const [orgFactAddress, setOrgFactAddress] = useState(
  //   Object.keys(dataAuth).length ?
  //     dataAuth.organization?.address?.physicaladdress :
  //     'нет данных'
  // );
  const [orgGenDir, setOrgGenDir] = useState(
    Object.keys(dataAuth).length ?
      dataAuth.organization?.persons?.directorname :
      'нет данных'
  );
  const [orgChiefBook, setOrgChiefBook] = useState(
    Object.keys(dataAuth).length ?
      dataAuth.organization?.persons?.bookername :
      'нет данных'
  );

  // ссылка на textarea у которого изменяем высоту автоматически
  const textareaRef = useRef(null);

  // ==============================================
  // это объект данных ФИАС юрадрес для отправки на сервер он начинается с "населенного пункта" 
  // и дальше вниз только обновляется (кажды раз добавляется одна иерархия)
  const [fiasDataObj1, setFiasDataObj1] = useState({});
  // console.log('это отправка данных fiasDataObj1: ', fiasDataObj1);

  // это объект данных ФИАС фактадрес для отправки на сервер он начинается с "населенного пункта" 
  // и дальше вниз только обновляется (кажды раз добавляется одна иерархия)
  const [fiasDataObj2, setFiasDataObj2] = useState({});
  // console.log('fiasDataObj2: ', fiasDataObj2);
  // ==============================================

  // данные для тела запроса по отправке изменений данных реквизитов
  const bodyChangeReqData = {
    'organization': {
      'uuid': dataAuth.organization.uuid,
      'inn': orgINN,
      'id': dataAuth.organization.id,
      'shortname': orgShortName,
      "fullname": orgFullName,
      "respusername": dataAuth.organization?.respusername,
      'name': dataAuth.organization.name,
      'email': dataAuth.user.email,
      'phone': dataAuth.user.phonenumber,
      // 'serial': dataAuth.organization.serial,
      'serial': orgOGRN,

      // 'jaddress': orgUrAddress,
      // 'faddress': orgFactAddress,

      // 'jaddressextfias': fiasDataObj1,
      'jaddressextfias': fiasDataObj1.hierarchy ? fiasDataObj1 : {},
      // 'faddressextfias': fiasDataObj2,
      'faddressextfias': fiasDataObj2.hierarchy ? fiasDataObj2 : {},


      'directorname': orgGenDir,
      'bookername': orgChiefBook,
      'sclientfnsinn': orgKPP,
    },
    'bank': {
      'id': '',
      'bankid': dataAuth.organization.bank?.bankid,
      'bankname': dataAuth.organization.bank?.bankname,
      'bankaccountnumber': dataAuth.organization.bank?.bankaccountnumber,
      'accountnumber': dataAuth.organization.bank?.accountnumber
    }
  };

  // функция стандартной отправки запроса на изменение данных реквизитов
  const postChangeDataReq = async (e) => {
    e.preventDefault();

    await fetchChangeData(bodyChangeReqData);

    dispatch(fetchUser());

    closeModal();

    console.log('форма запроса на изменение данных реквизитов отправлена');
  };

  // функция увеличивает или уменьшаем высоту textarea 
  // автоматически в зависимости от величины содержания
  // работает в паре со стилями "min-height: 32px;resize: none;" (указаны в файле стилей)
  useLayoutEffect(() => {
    // уменьшаем высоту при удалении невмещающегося текста
    textareaRef.current.style.height = 'inherit';

    // устанавливаем высоту
    textareaRef.current.style.height = `${Math.max(
      textareaRef.current.scrollHeight,
      32
    )}px`;
  }, [orgFullName]);


  return (
    <div className={st.docsedit_wrap}>
      <button
        className={st.btn_close}
        onClick={closeModal}
      ></button>

      <div className={st.docsedit}>
        <form className={st.form}>

          {/* окно изменения реквизитов */}
          <fieldset className={st.fieldset}>
            <h3 className={st.title}>Изменение реквизитов</h3>

            {/* инпут редактирования полного наименования организации */}
            <div className={st.wrap_title}>
              <p className={orgFullName !== '' ? st.wrap_title_p : st.wrap_title_p_hide}>
                Полное наименование</p>

              <textarea
                ref={textareaRef}
                className={st.textarea}
                style={{
                  // minHeight: 32,
                  // resize: 'none'
                }}
                // rows='2'
                cols='38'
                name='orgFullName'
                value={orgFullName}
                maxLength='200'
                placeholder='Полное наименование'
                onChange={(e) => setOrgFullName(e.target.value)}
              ></textarea>
            </div>

            {/* инпут редактирования краткого наименования организации */}
            <div className={st.wrap_title}>
              <p className={orgShortName !== '' ? st.wrap_title_p : st.wrap_title_p_hide}>
                Краткое наименование</p>

              <input
                className={st.input}
                name='orgShortName'
                value={orgShortName}
                maxLength='60'
                placeholder='Краткое наименование'
                onChange={(e) => setOrgShortName(e.target.value)}
              />
            </div>

            {/* инпут редактирования ИНН организации */}
            <div className={st.wrap_title}>
              <p className={orgINN !== '' ? st.wrap_title_p : st.wrap_title_p_hide}>
                ИНН</p>

              <input
                className={st.input}
                name='orgINN'
                value={orgINN}
                maxLength='60'
                placeholder='ИНН'
                onChange={(e) => setOrgINN(e.target.value)}
              />
            </div>

            {/* инпут редактирования КПП организации */}
            <div className={st.wrap_title}>
              <p className={orgKPP !== '' ? st.wrap_title_p : st.wrap_title_p_hide}>
                КПП</p>

              <input
                className={st.input}
                name='orgKPP'
                value={orgKPP}
                maxLength='60'
                placeholder='КПП'
                onChange={(e) => setOrgKPP(e.target.value)}
              />
            </div>

            {/* инпут редактирования ОГРН организации */}
            <div className={st.wrap_title}>
              <p className={orgOGRN !== '' ? st.wrap_title_p : st.wrap_title_p_hide}>
                ОГРН</p>

              <input
                className={st.input}
                name='orgOGRN'
                value={orgOGRN}
                maxLength='60'
                placeholder='ОГРН'
                onChange={(e) => setOrgOGRN(e.target.value)}
              />
            </div>

            {/* юридический адрес */}
            <div className={st.address_group}>
              {/* заголовок */}
              <div className={st.wrap_title}>
                <h6 className={st.wrap_title_h6}>Юридический адрес:</h6>
              </div>

              <Fias1
                setFiasDataObj1={setFiasDataObj1}
                dataForFields1={dataAuth.organization.address.legaladdressextfias}
              />
            </div>


            {/* фактический адрес */}
            <div className={st.address_group}>
              {/* заголовок */}
              <div className={st.wrap_title}>
                <h6 className={st.wrap_title_h6}>Фактический адрес:</h6>
              </div>

              <Fias2
                setFiasDataObj2={setFiasDataObj2}
                dataForFields2={dataAuth.organization.address.physicaladdressextfias}
              />
            </div>

            {/* инпут редактирования генерального директора организации */}
            <div className={st.wrap_title}>
              <p className={orgGenDir !== '' ? st.wrap_title_p : st.wrap_title_p_hide}>
                Генеральный директор</p>

              <input
                className={st.input}
                name='orgGenDir'
                value={orgGenDir}
                maxLength='100'
                placeholder='Генеральный директор'
                onChange={(e) => setOrgGenDir(e.target.value)}
              />
            </div>

            {/* инпут редактирования главного бухгалтера организации */}
            <div className={st.wrap_title}>
              <p className={orgChiefBook !== '' ? st.wrap_title_p : st.wrap_title_p_hide}>
                Главный бухгалтер</p>

              <input
                className={st.input}
                name='orgChiefBook'
                value={orgChiefBook}
                maxLength='100'
                placeholder='Главный бухгалтер'
                onChange={(e) => setOrgChiefBook(e.target.value)}
              />
            </div>

            <button
              className={st.btn_submit}
              onClick={postChangeDataReq}
            >Сохранить</button>
          </fieldset>
        </form>
      </div>
    </div>
  );
};
