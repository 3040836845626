import st from './Advantages.module.css';
import { advantagesList } from '../../data/advantagesList.js';
import { v4 as uuidv4 } from 'uuid';
import { useRef } from 'react';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, A11y, Scrollbar } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';


export const Advantages = () => {
  const sliderList = useRef(null);

  return (
    <section className={`${st.section_advantages} container`}>

      <h2 className={st.title}>Преимущества работы с&nbsp;LIFTUP</h2>

      <div className={st.after768px}>
        <ul className={st.advantages_list}>
          {advantagesList.map(item =>
            <li className={st.advantages_item} key={uuidv4()}>
              <div className={st.item_border}></div>

              <div className={st.item_background}></div>

              <img className={st.advantages_image} src={item.link} alt={item.title} />

              <h3 className={st.item_title}>{item.title}</h3>

              <p className={st.item_text}>{item.text}</p>

            </li>
          )}
        </ul>
      </div>

      <div className={st.before768px}>
        <div className={st.advantages_list}>
          <Swiper
            ref={sliderList}
            className='about_swiper'
            modules={[Pagination, A11y, Scrollbar]}
            slidesPerView={4}
            spaceBetween={20}
            onSlideChange={() => { }}
            onSwiper={(swiper) => { }}
            direction={'horizontal'}
            breakpoints={
              {
                500: {
                  slidesPerView: 2
                },
                300: {
                  slidesPerView: 1
                }
              }
            }
            scrollbar={{ draggable: true }}
          >

            {advantagesList.map(item =>
              <SwiperSlide key={uuidv4()}>
                <div className={st.advantages_item} key={uuidv4()}>
                  <div className={st.item_border}></div>

                  <div className={st.item_background}></div>

                  <img className={st.advantages_image} src={item.link} alt={item.title} />

                  <h3 className={st.item_title}>{item.title}</h3>

                  <p className={st.item_text}>{item.text}</p>
                </div>
              </SwiperSlide>
            )}
          </Swiper>
        </div>
      </div>
    </section>
  )
};
