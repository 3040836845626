import { MEDIAFILE_ADD, DOCS_ADD } from "src/consts";
import { updateTokens } from "src/utils/updateTokens";

// двойной запрос: добавляем медиафайл, добавляем остальные данные с ответом из первого запроса
export const fetchDocsAddEdit = async (bodyMediaFile, bodyDocsData) => {
  // первый запрос
  const responsemediafile = await fetch(MEDIAFILE_ADD, {
    method: 'POST',
    body: bodyMediaFile,
    headers: {
      'User-Agent': 'LiftupSite/1.0.0',
      'Authorization': `Bearer ${localStorage.getItem('tokenlu')}`,
    },
  });

  // ошибка когда отправляем слишком большой файл
  if (responsemediafile.status === 413) {
    alert('Передаваемый файл слишком большой! \r\nМаксимальный размер файла - 5MB');
  }

  try {
    const datamediafile = await responsemediafile.json();

    // в случае протухания токена обновляем пару "token - rtoken"
    if (responsemediafile.status === 401 || datamediafile.loginstatus === 7) {
      // перезапись токенов
      await updateTokens();
      // первый запрос
      const responsemediafile = await fetch(MEDIAFILE_ADD, {
        method: 'POST',
        body: bodyMediaFile,
        headers: {
          'User-Agent': 'LiftupSite/1.0.0',
          'Authorization': `Bearer ${localStorage.getItem('tokenlu')}`,
        },
      });
      const datamediafile = await responsemediafile.json();

      // добавляем полученные из первого запроса данные в массив данных для body
      bodyDocsData.documentlinkuid = datamediafile.mediafileuid;

      // второй запрос
      const responsedocadd = await fetch(DOCS_ADD, {
        method: 'POST',
        body: JSON.stringify(bodyDocsData),
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'User-Agent': 'LiftupSite/1.0.0',
          'Authorization': `Bearer ${localStorage.getItem('tokenlu')}`,
        },
      });
      const datadocadd = await responsedocadd.json();

      console.log('datadocadd: ', datadocadd);
      console.log('данные на добавление слайда отправлены c перезаписью токенов');

    } else {

      // добавляем полученные из первого запроса данные в массив данных для body
      bodyDocsData.documentlinkuid = datamediafile.mediafileuid;

      // второй запрос
      const responsedocadd = await fetch(DOCS_ADD, {
        method: 'POST',
        body: JSON.stringify(bodyDocsData),
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'User-Agent': 'LiftupSite/1.0.0',
          'Authorization': `Bearer ${localStorage.getItem('tokenlu')}`,
        },
      });
      const datadocadd = await responsedocadd.json();

      console.log('datadocadd: ', datadocadd);
      console.log('данные на добавление слайда отправлены без перезаписи токенов');
    }
  } catch (err) {
    console.error('сообщение об ошибке', err.message);
  }

};
