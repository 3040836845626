import st from './LkDetails.module.css';
import { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { fetchWarrantyService } from '../../../LkLiftDown/fetches/fetchWarrantyService.js';
import { useDispatch } from 'react-redux';
import { fetchLiftDownListOneOrg } from 'src/store/liftdown/getLiftDownList';

export const LkDetails = ({
  closeModal,
  efouid,
  uuid,
  equid,
  typeOfEquipment,
  factorynumber,
  manufacturername,
  brandname,
  partsList
}) => {
  const dispatch = useDispatch();
  // переключение окон "детали" и "подача заявки"
  const [winDetails, setWinDetails] = useState(true);
  const [winOrder, setWinOrder] = useState(false);

  // получаем данные кликнутой детали для подачи заявки
  const [orderData, setOrderData] = useState([]);

  // поля значений некоторых данных детали для заявки
  const [detailName, setDetailName] = useState('нет данных');
  const [detailUUID, setDetailUUID] = useState('нет данных');
  const [detailProductDate, setDetailProductDate] = useState('нет данных');
  const [detailWarrAcceptDate, setDetailWarrAcceptDate] = useState('нет данных');
  const [detailWarrExpiratDate, setDetailWarrExpiratDate] = useState('нет данных');
  const [detailIndNumber, setDetailIndNumber] = useState('нет данных');

  // управление полем "Дополнительная информация"
  const [additInfo, setAdditInfo] = useState('');

  // чекбоксы управление
  const [checked1, setChecked1] = useState(true);
  const [checked2, setChecked2] = useState(true);

  // функция получения текущей даты в нужном формате
  const getCurrentDate = () => {
    const date = new Date();
    // возвращаем в нужном формате
    return date.toISOString().slice(0, -14);
  };

  // переключает модальные окна
  const changeModalWin = (e) => {
    setWinDetails(!winDetails);
    setWinOrder(!winOrder);
  };

  // данные для тела запроса подачи заявки на гарантийное обслуживание
  const bodyWarrantyService = {
    'uid': equid, // возможно equid, пока отправим пустым
    'status': 'NEW', // первональный статус заявки
    'organization': {
      'id': uuid // uuid организации
    },
    'date': getCurrentDate(), // текущая дата
    'equipmentOfOrganization': {
      'efouid': efouid // uuid оборудования
    },
    'infowarrantycase': additInfo, // письменное сообщение
    'orderpartreplace': checked1, // Заказать услуги по замене детали
    'ordernonwarrantypart': checked2, // Заказать негарантийную деталь
    'part': {
      'partid': detailUUID, // uuid детали
      'partname': detailName, // Наименование детали
      'selfnumber': detailIndNumber, // Индивидуальный номер
      'equipmentDate': {
        'dateofcommissioning': detailProductDate, // Дата производства
        'dateofacceptanceforwarranty': detailWarrAcceptDate, // Дата приемки на гарантию
        'warrantyexpirationdate': detailWarrExpiratDate // Дата окончания гарантии
      }
    },
  };

  // функция отправки запроса на добавление новой заявки
  const handleFormSubmit = async (e) => {
    e.preventDefault();

    await fetchWarrantyService(bodyWarrantyService);

    // обновляем список заявок в stor
    dispatch(fetchLiftDownListOneOrg(uuid));

    closeModal();
  };

  return (
    <div className={!winOrder ? st.clientsedit_wrap : `${st.clientsedit_wrap} ${st.small_width}`}>
      <button
        className={st.btn_close}
        onClick={winDetails ? closeModal : changeModalWin}>
      </button>

      <div className={st.clientsedit}>
        <div className={st.container}>

          {/* окно с деталями */}
          <div className={winDetails ? st.window_detials : st.hidden}>
            <h3 className={st.title}>Детали</h3>

            <div className={st.column_names}>
              <p>Наименование</p>
              <p>Дата производства</p>
              <p>Дата приемки на&nbsp;гарантию</p>
              <p>Дата окончания гарантии</p>
              <p>Индивидуальный номер</p>
              <p>Заявка</p>
            </div>

            <div className={st.details}>
              {
                partsList.map(item =>

                (
                  // костыль, но пока оставлю (условие позволяет не отображать деталь 
                  // с именем 'Плата управления приводом дверей кабины' если тип оборудования не 'Elevator')
                  typeOfEquipment === 'Elevator' || item.partname !== 'Плата управления приводом дверей кабины' ?
                    <div className={st.detail_container} key={uuidv4()}>
                      <h4 className={st.detail_title}>{item.partname}</h4>
                      <div className={st.detail}>

                        <div className={st.detail_wrap}>
                          <div className={st.wrap}>
                            <p className={st.detail_item}>{item.partname}</p>
                          </div>

                          <div className={st.wrap}>
                            <p className={st.detail_elem}>Дата производства</p>
                            <p className={st.detail_item}>
                              {item.equipmentDate.dateofcommissioning?.slice(0, -9)}
                            </p>
                          </div>

                          <div className={st.wrap}>
                            <p className={st.detail_elem}>Дата приемки на гарантию</p>
                            <p className={st.detail_item}>
                              {item.equipmentDate.dateofacceptanceforwarranty?.slice(0, -9)}
                            </p>
                          </div>

                          <div className={st.wrap}>
                            <p className={st.detail_elem}>Дата окончания гарантии</p>
                            <p className={st.detail_item}>
                              {item.equipmentDate.warrantyexpirationdate?.slice(0, -9)}
                            </p>
                          </div>

                          <div className={st.wrap}>
                            <p className={st.detail_elem}>Индивидуальный номер</p>
                            <p className={st.detail_item}>{item.selfnumber}</p>
                          </div>

                          <button
                            className={st.detail_item_btn}
                            onClick={() => {
                              changeModalWin();
                              setOrderData(
                                // отображает значения в полях заявки
                                [
                                  item.partname,
                                  item.equipmentDate.dateofcommissioning.slice(0, -9),
                                  item.equipmentDate.dateofacceptanceforwarranty.slice(0, -9),
                                  item.equipmentDate.warrantyexpirationdate.slice(0, -9),
                                  item.selfnumber
                                ]
                              );
                              // устанавливает значения в объекте bodyWarrantyService
                              setDetailName(item.partname)
                              setDetailUUID(item.partid)
                              setDetailProductDate(item.equipmentDate.dateofcommissioning.slice(0, -9))
                              setDetailWarrAcceptDate(item.equipmentDate.dateofacceptanceforwarranty.slice(0, -9))
                              setDetailWarrExpiratDate(item.equipmentDate.warrantyexpirationdate.slice(0, -9))
                              setDetailIndNumber(item.selfnumber)
                            }}
                          >Подать заявку</button>
                        </div>
                      </div>

                      {/* кнопка для мобильной версии */}
                      <button
                        className={st.detail_item_btn_mob}
                        onClick={(e) => {
                          changeModalWin();
                          setOrderData([
                            item[0],
                            item[1].detproductdate,
                            item[1].detacceptwarrantydate,
                            item[1].detexpiratwarrantydate,
                            item[1].det_id])
                        }}
                      >Подать заявку</button>

                    </div> : null
                ))
              }
            </div>
          </div>

          {/* окно с заявкой */}
          <div className={winOrder ? st.window_order : st.hidden}>
            <h3 className={st.title}>Подача заявки</h3>

            <form className={st.form}>
              {/* данные целевого элемента */}
              {orderData.map((item, i) => {
                const arrCaption = [
                  'Наименование детали',
                  'Дата производства',
                  'Дата приемки на гарантию',
                  'Дата окончания гарантии',
                  'Индивидуальный номер'
                ];

                return (
                  <div className={st.form_elem} key={uuidv4()}>
                    <p className={st.form_elem_caption}>{arrCaption[i]}</p>
                    <p className={st.form_elem_data}>{item}</p>
                  </div>
                )
              })}

              {/* поле ввода доп информации */}
              <textarea
                className={st.addit_info}
                id='additInfo'
                name='additInfo'
                rows='3'
                maxLength='600'
                minLength='2'
                spellCheck='true'
                placeholder='Информация о гарантийном случае'
                value={additInfo}
                onChange={(e) => setAdditInfo(e.target.value)}
              ></textarea>

              {/* чкбокс "Заказать услуги по замене детали" */}
              <fieldset className={st.fieldset_assent}>
                <label className={st.label_check}>
                  <input
                    className={st.input_check}
                    type='checkbox'
                    name='checkbox1'
                    checked={checked1}
                    onChange={() => setChecked1(!checked1)}
                  />

                  <p>Заказать услуги по замене детали</p>
                </label>
              </fieldset>

              {/* чкбокс "Заказать негарантийную деталь" */}
              <fieldset className={st.fieldset_assent}>
                <label className={st.label_check}>
                  <input
                    className={st.input_check}
                    type='checkbox'
                    name='checkbox2'
                    checked={checked2}
                    onChange={() => setChecked2(!checked2)}
                  />

                  <p>Заказать негарантийную деталь</p>
                </label>
              </fieldset>

              <button
                className={st.btn_tosend}
                onClick={handleFormSubmit}
              >Отправить</button>
            </form>
          </div>

        </div>
      </div>
    </div>
  );
};
