import st from './DeptMain.module.css';
import { useState, useEffect } from 'react';
// import { main_list } from './data/main_list_json.js';
// import { slide_list } from './data/slideL_list.js';
import { v4 as uuidv4 } from 'uuid';
import { ReactComponent as BtnAdd } from '../../../../img/btn_add.svg';
import { SlidePreviews } from './components/SlidePreviews/SlidePreviews';
import { Overlay } from 'src/components/Overlay/Overlay';
import { SlideAdd } from './components/SlideAdd/SlideAdd';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSlidesList } from 'src/store/slides/getSlides';

export const DeptMain = () => {
  // откр закр модалка
  const [modalAdd, setModalAdd] = useState(false);
  // сортированный массив слайдов
  const [slideListSort, setSlideListSort] = useState([]);

  const { slideList } = useSelector(state => state.slidesListReducer.dataSlidesList);
  const { status } = useSelector(state => state.slidesListReducer);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!slideList) {
      dispatch(fetchSlidesList());
    }

    if (status === 'success') {
      // сортируем  массив по ключу "slideOrder" (по порядковому номеру слайда)
      setSlideListSort(Array.from(slideList).sort((a, b) => {
        return (Object.values(a)[3] > Object.values(b)[3]) - 0.5;
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, status]);

  return (
    <>
      <div className={st.deptmain}>
        <h3 className={st.title}>Слайды</h3>

        <button
            className={st.btn_add}
            onClick={() => setModalAdd(!modalAdd)}>
            <BtnAdd />
          </button>

        {/* список карточек слайдов */}
        <div className={st.deptmain_container}>
          <ul className={st.slide_previews_list}>
            {
              status === 'success' ?
                slideListSort.map(item =>
                  <SlidePreviews
                    key={uuidv4()}
                    slideID={item.slideID}
                    slideLink={item.slideLink}
                    slideimagelink={item.slideimagelink}
                    slideOrder={item.slideOrder}
                  />
                ) : <p>загрузка слайдов...</p>
            }
          </ul>

        </div>
      </div>


      <Overlay className={!modalAdd ? st.overlay_hide : st.overlay_show}>
        <SlideAdd
          modalClose={() => setModalAdd(false)}
          slideList={slideList}
          status={status}
        />
      </Overlay>
    </>
  )
};
