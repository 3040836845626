import st from './EditData.module.css';
import store from 'src/store/store';
import { useEffect, useRef, useState } from 'react';
import phoneNumberMask from 'src/utils/phoneNumberMask';
import handleInputChange from 'src/utils/handleInputChange';
import { fetchChangeData } from '../../fetches/fetchChangeData';
import { useDispatch } from 'react-redux';
import { fetchUser } from 'src/store/authorization/auth';
import { fetchConfirmPhone } from '../../fetches/fetchConfirmPhone';
import { fetchConfirmCode } from '../../fetches/fetchConfirmCode';
import { fetchChangeEmail } from '../../fetches/fetchChangeEmail';

export const EditData = (
  {
    closeModal,
    dataAuth
  }
) => {
  const dispatch = useDispatch();

  const [orgName, setOrgName] = useState(
    Object.keys(dataAuth).length ?
      dataAuth.organization?.name :
      'нет данных');
  const [orgFIO, setOrgFIO] = useState(
    Object.keys(dataAuth).length ?
      dataAuth.organization?.respusername :
      'нет данных');
  const [orgPhone, setOrgPhone] = useState(
    Object.keys(dataAuth).length ?
      `+7 ${phoneNumberMask(dataAuth.user?.phonenumber?.slice(1))}` :
      'нет данных');
  const [orgMail, setOrgMail] = useState(
    Object.keys(dataAuth).length ?
      dataAuth.user?.email :
      'нет данных');

  // ввод телефона
  const [phoneConfirm, setPhoneConfirm] = useState('');
  // ввод майла
  const [mailChange, setMailChange] = useState('');

  // уведомления о неправильном вводе телефона и майла
  const [showNotePhone, setShowNotePhone] = useState('');
  const [showNoteMail, setShowNoteMail] = useState('');

  // формы модального окна: 
  // общ. данные + тел. + mail, 
  const [formEditGeneralData, setFormEditGeneralData] = useState(true);
  // ввод нового телефона
  const [formPhoneEntry, setFormPhoneEntry] = useState(false);
  // ввод проверочного кода
  const [formCodeEntry, setFormCodeEntry] = useState(false);
  // ввод нового e-mail
  const [formMailEntry, setFormMailEntry] = useState(false);

  // ввод символов проверочного кода
  const [codeSimbol1, setCodeSimbol1] = useState('');
  const [codeSimbol2, setCodeSimbol2] = useState('');
  const [codeSimbol3, setCodeSimbol3] = useState('');
  const [codeSimbol4, setCodeSimbol4] = useState('');

  // ссылки на инпуты ввода символов проверочного кода
  const inputRef1 = useRef();
  const inputRef2 = useRef();
  const inputRef3 = useRef();
  const inputRef4 = useRef();

  // состояние для функции ограничения ввода проверочного кода фиксированное число раз
  const [isVisible, setIsVisible] = useState(false);

  // состояние счетчика
  let [count, setCount] = useState(0);

  // логинстатус приходит в ответе сервера и в зависимости от его значения 
  // показываем уведомление о вводе не верного проверочного кода
  const [loginstatus, setLoginstatus] = useState(5);

  // патткрн эл. почты
  const patternMail = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;


  // меняет значение в инпуте телефона на отформатированное
  const phoneNumberFormatter = (e) => {
    const formattedTargetValue = phoneNumberMask(e.target.value);

    e.target.value = formattedTargetValue;

    setPhoneConfirm(e.target.value);

    if (e.target.value.length === 13) {
      setShowNotePhone(true);
    } else {
      setShowNotePhone(false);
    }
  };

  // запрет ввода всего кроме цифр и контроль инпутов кода подтверждения
  const handleInputCode = (e, stateInput) => {
    stateInput(e.target.value.replace(/\D/g, ''))
  };

  // перемещаем фокус на соседний инпут
  useEffect(() => {
    if (codeSimbol1.length >= 1) {
      inputRef1.current.nextSibling.focus();
    }

    if (codeSimbol2.length >= 1) {
      inputRef2.current.nextSibling.focus();
    }

    if (codeSimbol3.length >= 1) {
      inputRef3.current.nextSibling.focus();
    }

    if (codeSimbol4.length >= 1) {
      inputRef4.current.blur();
    }
  }, [codeSimbol1, codeSimbol2, codeSimbol3, codeSimbol4]);

  // счетчик
  const countPlus = () => {
    setCount(count + 1);
  };

  // обработчик кнопки повторной отправки
  const handleResendButton = () => {
    countPlus();

    setIsVisible(false);

    setTimeout(() => {
      setIsVisible(true);
    }, 6000);
  };

  // открывает окно ввода телефона
  const openWinPhone = () => {
    setFormEditGeneralData(false);
    setFormPhoneEntry(true);
  };

  // меняет окно ввода телефона на окно общие сведения
  const closeWinPhone = () => {
    setFormEditGeneralData(true);
    setFormPhoneEntry(false);
  };

  // открывает окно ввода e-mail
  const openWinMail = () => {
    setFormEditGeneralData(false);
    setFormMailEntry(true);
  };

  // меняет окно ввода e-mail на окно общие сведения
  const closeWinMail = (e) => {
    e.preventDefault();
    setFormEditGeneralData(true);
    setFormMailEntry(false);
  };

  // открывает окно ввода проверочного кода и закрывает окно ввода телефона
  const openWinCode = (e) => {
    e.preventDefault();
    setFormCodeEntry(true);
    setFormPhoneEntry(false);
    handleResendButton();
  };

  // меняет окно ввода кода  на окно общие сведения
  const closeWinCode = (e) => {
    e.preventDefault();
    setFormEditGeneralData(true);
    setFormCodeEntry(false);
  };

  // меняет окно ввода кода  на окно ввода телефона
  const backWinCode = (e) => {
    e.preventDefault();
    setFormPhoneEntry(true);
    setFormCodeEntry(false);
  };

  // включаем возможность повторной отправки через час
  useEffect(() => {
    if (count >= 4) {
      setTimeout(() => {
        setCount(0);
      }, 18000); // 18 секунд пока, потом 3600000 - это 1 час
    }
  }, [count]);


  // данные для тела запроса по отправке изменений стандартных данных
  // по сути здесь меняется только поле 'respusername', остальное без изменений
  const bodyChangeData = {
    'organization': {
      'uuid': dataAuth.organization.uuid,
      'inn': dataAuth.organization.inn,
      'id': dataAuth.organization.id,
      'shortname': dataAuth.organization.shortname,
      "fullname": dataAuth.organization.fullname,
      "respusername": orgFIO,
      'name': dataAuth.organization.name,
      'email': dataAuth.user.email,
      'phone': dataAuth.user.phonenumber,
      'serial': dataAuth.organization.serial,


      'jaddress': dataAuth.organization?.address?.legaladdressextfias?.full_name,
      'faddress': dataAuth.organization?.address?.physicaladdressextfias?.full_name,


      'directorname': dataAuth.organization?.persons?.directorname,
      'bookername': dataAuth.organization?.persons?.bookername,
      'sclientfnsinn': dataAuth.organization?.fnsinn,
    },
    'bank': {
      'id': '',
      'bankid': dataAuth.organization?.bank?.bankid,
      'bankname': dataAuth.organization?.bank?.bankname,
      'bankaccountnumber': dataAuth.organization?.bank?.bankaccountnumber,
      'accountnumber': dataAuth.organization?.bank?.accountnumber
    }
  };

  // функция стандартной отправки запроса на изменение данных
  const postChangeData = async (e) => {
    e.preventDefault();

    await fetchChangeData(bodyChangeData);

    dispatch(fetchUser());

    closeModal();

    console.log('форма запроса на изменение данных отправлена');
  };


  // PHONE
  // отправка телефона для получения проверочного кода
  const bodyPhone = {
    'phone': `7${phoneConfirm.replace(/\D/g, '')}`
  };

  // отправка нового телефона для полученния проверочного кода
  const postСonfirmPhone = (e) => {
    e.preventDefault();

    // обработчик кнопки повторной отправки
    handleResendButton();

    fetchConfirmPhone(bodyPhone);

    // группа переводит к окну ввода кода из смс
    setFormCodeEntry(true);
    setFormPhoneEntry(false);

    // console.log('bodyPhone: ', bodyPhone);
    console.log('форма отправки нового телефона для получения кода отправлена');
  };

  // PHONE CHANGE REQUEST
  // отправка телефона и e-mail для смены телефона
  const bodyPhoneChange = {
    'loginName': dataAuth.user?.email,
    'phone': bodyPhone.phone,
  };

  // CODE
  // отправка проверочного кода из смс
  const bodyCode = {
    'loginName': bodyPhone.phone,
    'smscode': codeSimbol1 + codeSimbol2 + codeSimbol3 + codeSimbol4,
    'smshash': ''
  }

  // отправка кода подтверждения из смс и приказ поменять номера телефона
  const postСonfirmСode = async (e) => {
    e.preventDefault();

    // в этом запросе отправляются два запроса поочередно - сначала отправка кода из смс, 
    // затем (если он верный) запрос на смену номера телефона (второй запрос отправляется с токеном)
    await fetchConfirmCode(bodyCode, bodyPhoneChange);

    // обновляем данные юзера
    dispatch(fetchUser());

    // группа в полосатых купальниках закрывает модалку
    // и приводит все нужные состояния в соответствие с изначальными
    closeModal();
    setFormEditGeneralData(true);
    setFormCodeEntry(false);

    console.log('форма с кодом из смс и с новым телефоном отправлена');
  };

  // EMAIL
  // тело запроса для получения shc
  const bodySHC = {
    'login': dataAuth.user?.email,
  }

  // тело запроса по отправке нового e-mail
  const bodyMail = {
    'loginName': dataAuth.user?.email,
    'email': mailChange,
  }

  // функция отправки shc и нового e-mail для его смены
  const postСhangeMail = async (e) => {
    e.preventDefault();

    // фетч на отправку запроса
    await fetchChangeEmail(bodySHC, bodyMail);

    // группа в полосатых купальниках закрывает модалку
    // и приводит все нужные состояния в соответствие с изначальными
    closeModal();
    setFormEditGeneralData(true);
    setFormCodeEntry(false);

    // console.log('bodySHC: ', bodySHC);
    // console.log('bodyMail: ', bodyMail);
    console.log('форма с shc и с новым e-mail отправлена');
  };

  // подписка на изменение значения "loginstatus"
  useEffect(() => {
    store.subscribe(() => setLoginstatus(store.getState().authReducer?.dataAuth?.loginstatus));
    if (loginstatus === undefined) setLoginstatus(5);
  }, [loginstatus]);


  return (
    <div className={st.docsedit_wrap}>
      <button
        className={st.btn_close}
        onClick={
          formEditGeneralData ?
            closeModal : formPhoneEntry ?
              closeWinPhone : formCodeEntry ?
                closeWinCode : closeWinMail
        }
      ></button>

      <div className={st.docsedit}>
        <form className={st.form}>

          {/* окно изменения общих данных */}
          <fieldset className={formEditGeneralData ? st.fieldset : st.fieldset_hide}>
            <h3 className={st.title}>Изменение данных</h3>

            {/* инпут редактирования наименования организации */}
            <div className={st.wrap_title}>
              <p className={orgName !== '' ? st.wrap_title_p : st.wrap_title_p_hide}>
                Наименование</p>

              <input
                className={st.input}
                type='text'
                name='orgName'
                value={orgName}
                placeholder='Наименование'
                onChange={(e) => setOrgName(e.target.value)}
                disabled
              />
            </div>

            {/* инпут редактирования ФИО ответственного сотрудника */}
            <div className={st.wrap_title}>
              <p className={orgFIO !== '' ? st.wrap_title_p : st.wrap_title_p_hide}>
                ФИО ответственного сотрудника</p>

              <input
                className={st.input}
                type='text'
                name='orgFIO'
                value={orgFIO}
                placeholder='ФИО'
                onChange={(e) => setOrgFIO(e.target.value)}
              />
            </div>

            {/* инпут редактирования номера телефона */}
            <div className={st.wrap_title}>
              <p className={orgPhone !== '' ? st.wrap_title_p : st.wrap_title_p_hide}>
                Номер телефона</p>

              <input
                className={st.input}
                type='text'
                name='orgPhone'
                value={orgPhone}
                // value={() => setOrgPhone(phoneNumberMask(orgPhone))}
                placeholder='Номер телефона'
                onChange={(e) => setOrgPhone(e.target.value)}
                onClick={openWinPhone}
              />
            </div>

            {/* инпут редактирования e-mail */}
            <div className={st.wrap_title}>
              <p className={orgMail !== '' ? st.wrap_title_p : st.wrap_title_p_hide}>
                E-mail</p>

              <input
                className={st.input}
                type='text'
                name='orgMail'
                value={orgMail}
                placeholder='e-mail'
                onChange={(e) => setOrgMail(e.target.value)}
                onClick={openWinMail}
              />
            </div>

            <button
              className={st.btn_submit}
              onClick={postChangeData}
            >Сохранить</button>
          </fieldset>

          {/* окно ввода номера телефона для отправки кода */}
          <fieldset className={formPhoneEntry ? st.fieldset : st.fieldset_hide}>
            <h3 className={st.title}>Изменение номера телефона</h3>

            {/* инпут ввода нового номера телефона */}
            <div className={st.wrap_title}>
              <p className={st.description}>На&nbsp;ваш новый номер телефона будет отправлено СМС с&nbsp;кодом подтверждения</p>

              <div className={st.note_wrap}>
                <p className={
                  showNotePhone === '' ? st.note_empty : showNotePhone ? st.note_hide : st.note_mail
                }>Введите корректный номер телефона</p>

                <label className={st.label}>
                  <p className={st.select}>+7</p>

                  <input
                    className={st.input_phone}
                    type='text'
                    name='phoneConfirm'
                    value={phoneConfirm}
                    placeholder='Новый номер телефона'
                    onChange={(e) => phoneNumberFormatter(e)}
                  />
                </label>
              </div>
            </div>

            {/* кнопка отправки нового номера телефона для получения смс или звонка на него */}
            <button
              className={st.button_submit}
              onClick={postСonfirmPhone}
              disabled={showNotePhone ? false : true}
            >Получить код</button>

            {/* вспомогательная кнопка */}
            <button
              className={st.button_submit}
              style={{ marginTop: '20px' }}
              onClick={openWinCode}
            >Шаг вперед</button>
          </fieldset>

          {/* окно ввода кода подтверждения */}
          <fieldset className={formCodeEntry ? st.fieldset : st.fieldset_hide}>
            <h3 className={st.title}>Подтвердите номер телефона</h3>
            <p className={st.sub_title}>Введите код из СМС</p>

            {/* инпуты ввода кода подтверждения */}
            <div className={st.note_wrap}>
              <div className={st.input_wrap}>
                <input
                  ref={inputRef1}
                  className={st.input_code}
                  type='text'
                  name='codeSimbol1'
                  value={codeSimbol1}
                  onChange={(e) => handleInputCode(e, setCodeSimbol1)}
                  maxLength='1'
                />

                <input
                  ref={inputRef2}
                  className={st.input_code}
                  type='text'
                  name='codeSimbol2'
                  value={codeSimbol2}
                  onChange={(e) => handleInputCode(e, setCodeSimbol2)}
                  maxLength='1'
                />

                <input
                  ref={inputRef3}
                  className={st.input_code}
                  type='text'
                  name='codeSimbol3'
                  value={codeSimbol3}
                  onChange={(e) => handleInputCode(e, setCodeSimbol3)}
                  maxLength='1'
                />

                <input
                  ref={inputRef4}
                  className={st.input_code}
                  type='text'
                  name='codeSimbol4'
                  value={codeSimbol4}
                  onChange={(e) => handleInputCode(e, setCodeSimbol4)}
                  maxLength='1'
                />
              </div>

              <p className={loginstatus !== 5 ? st.note_code : st.note_hide}>Введен неверный код</p>
            </div>

            {/* кнопка отправки нового номера телефона ПОВТОРНО! для получения смс или звонка на него */}
            <div className={st.wrapper}>
              {count > 4
                ? <p className={st.notice}>Вы&nbsp;превысили количество запросов кода подтверждения. Попробуйте через 55&nbsp;минут</p>
                : !isVisible
                  ? <p className={st.notice}>Новый код для этого номера можно будет запросить через 01:00</p>
                  : <button
                    className={st.button_forgot}
                    // onClick={postСonfirmPhone}
                    onClick={handleResendButton}
                  >Отправить код ещё раз</button>
              }
            </div>


            <div className={st.btns_wrapper}>
              <button className={st.btn_back} onClick={backWinCode}></button>

              {/* кнопка отправки проверочного кода полученного по телефону */}
              <button
                className={st.button_submit2}
                onClick={postСonfirmСode}
                disabled={
                  codeSimbol1 &&
                    codeSimbol2 &&
                    codeSimbol3 &&
                    codeSimbol4 ? false : true
                }
              >Подтвердить</button>
            </div>

            {/* вспомогательная кнопка */}
            <button
              className={st.button_submit}
              style={{ marginTop: '20px' }}
              onClick={(e) => {
                e.preventDefault();
                closeModal();
                setFormEditGeneralData(true);
                setFormCodeEntry(false);
              }
              }
            >Шаг вперед</button>
          </fieldset>

          {/* окно ввода е-mail для отправки ссылки подтверждения */}
          <fieldset className={formMailEntry ? st.fieldset : st.fieldset_hide}>
            <h3 className={st.title}>Изменение e-mail</h3>

            {/* инпут ввода нового e-mail */}
            <div className={st.wrap_title}>
              <p className={st.description}>На&nbsp;новый <nobr>e-mail</nobr> будет отправлено письмо со&nbsp;ссылкой для подтверждения. <nobr>E-mail</nobr> заменится после перехода по&nbsp;этой ссылке.</p>

              <div className={st.note_wrap}>
                <p className={
                  showNoteMail === '' ? st.note_empty : showNoteMail ? st.note_hide : st.note_mail
                }>Введите корректный e-mail</p>

                <input
                  className={st.input}
                  type='text'
                  name='mailChange'
                  value={mailChange}
                  placeholder='E-mail'
                  onChange={(e) => handleInputChange(e, patternMail, setShowNoteMail, setMailChange)}
                />
              </div>
            </div>

            <button
              className={st.button_submit}
              onClick={postСhangeMail}
              disabled={showNoteMail ? false : true}
            >Подтвердить</button>

            <button
              className={st.button_submit}
              style={{ marginTop: '20px' }}
              onClick={(e) => {
                // closeWinMail // возврат на предыдущее окно
                e.preventDefault();
                closeModal();
                setFormEditGeneralData(true);
                setFormMailEntry(false);
              }
              }
            >Шаг вперед</button>
          </fieldset>

        </form>
      </div>
    </div>
  )
};
