import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { LIFTDOWN_LIST_ALL, LIFTDOWN_LIST_ORG } from 'src/consts.js'; // ручка не рабочая
import { updateTokens } from 'src/utils/updateTokens.js';


// получаем полный список заявок на гарантийное обслуживание всех организаций
export const fetchLiftDownList = createAsyncThunk(
  'liftDownList/fetchLiftDownList',

  async () => {
    const res = await fetch(LIFTDOWN_LIST_ALL, {
      method: 'GET',
      headers: {
        'Content-type': 'application/json; charset=UTF8',
        'User-Agent': 'LiftupSite/1.0.0',
        // заголовок с токеном для проверки
        'Authorization': `Bearer ${localStorage.getItem('tokenlu')}`,
      }
    }
    );

    const data = await res.json();

    // в случае просроченного токена обновляем пару "token - rtoken"
    if (res.status === 401 || data.loginstatus === 7) {
      // перезапись токенов
      await updateTokens();

      const res = await fetch(LIFTDOWN_LIST_ALL, {
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF8',
          'User-Agent': 'LiftupSite/1.0.0',
          // заголовок с токеном для проверки
          'Authorization': `Bearer ${localStorage.getItem('tokenlu')}`,
        }
      }
      );
      const data = await res.json();
      return data;
    }

    return data;
  }
);

// получаем список заявок на гарантийное обслуживание одной организации
export const fetchLiftDownListOneOrg = createAsyncThunk(
  'liftDownList/fetchLiftDownListOneOrg',

  async (orgUuid) => {
    const res = await fetch(`${LIFTDOWN_LIST_ORG}${orgUuid}`, {
      method: 'GET',
      headers: {
        'Content-type': 'application/json; charset=UTF8',
        'User-Agent': 'LiftupSite/1.0.0',
        // заголовок с токеном для проверки
        'Authorization': `Bearer ${localStorage.getItem('tokenlu')}`,
      }
    }
    );

    const data = await res.json();

    // в случае просроченного токена обновляем пару "token - rtoken"
    if (res.status === 401 || data.loginstatus === 7) {
      // перезапись токенов
      await updateTokens();

      const res = await fetch(`${LIFTDOWN_LIST_ORG}${orgUuid}`, {
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF8',
          'User-Agent': 'LiftupSite/1.0.0',
          // заголовок с токеном для проверки
          'Authorization': `Bearer ${localStorage.getItem('tokenlu')}`,
        }
      }
      );
      const data = await res.json();
      return data;
    }

    return data;
  }
);

const initialState = {
  status: 'idle',
  dataLiftDownList: {},
  error: null,
};

const liftDownListSlice = createSlice({
  name: 'liftDownList',
  initialState,
  reducers: {
    removeLiftDownList: (state) => {
      state.dataLiftDownList = {};
    }
  },

  extraReducers: (builder) => {
    builder
      // при вызове fetchLiftDownList (полный список)
      .addCase(fetchLiftDownList.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(fetchLiftDownList.fulfilled, (state, action) => {
        state.status = 'success';
        state.dataLiftDownList = action.payload;
      })
      .addCase(fetchLiftDownList.rejected, (state, action) => {
        state.status = 'failed';
        state.dataLiftDownList = action.error.message;
      })

      // при вызове fetchLiftDownListOneOrg (список одной организации)
      .addCase(fetchLiftDownListOneOrg.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(fetchLiftDownListOneOrg.fulfilled, (state, action) => {
        state.status = 'success';
        state.dataLiftDownList = action.payload;
      })
      .addCase(fetchLiftDownListOneOrg.rejected, (state, action) => {
        state.status = 'failed';
        state.dataLiftDownList = action.error.message;
      })
  }
});

export const { removeLiftDownList } = liftDownListSlice.actions;

export default liftDownListSlice.reducer;
