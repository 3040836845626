// отображает положение прокрутки на сайте
const scrollingHendler = (setStateNum, setStateDirection) => {
  // стартовое и предыдущее значение положения прокрутки для сравнения с текущим
  let previousPosition = window.scrollY;

  // функция определяет высоту скрола а также направление прокрутки
  const determineScrollHeight = () => {
    let currentPosition = window.scrollY;

    if (previousPosition > currentPosition) {
      setStateDirection(true);
    } else {
      setStateDirection(false);
    }

    // определяет цифру для показа в окне этажей
    const scrolled = window.scrollY;
    setStateNum((scrolled / 210).toFixed());
  };

  // слушаем событие скролл на документе
  document.addEventListener('scroll', determineScrollHeight);
};

export default scrollingHendler;
