// функция меняет индекс в зависимости от условия
export const indexСhange = (typeOfDistrict, num1, num2) => {
  let index = null;
  if (typeOfDistrict.startsWith('г')) index = num1;
  if (typeOfDistrict.startsWith('м')) index = num2;
  // console.log('index: ', index);
  return index;
};

// убираем фокус с элемента
export const removeFocuse = () => {
  const activeElement = document.activeElement;
  if (activeElement) {
    activeElement.blur();
  }
};

// формирует массив найденных совпадений (поиск)
export const searchInArrayElement = (e, dataList, indexArr, setResultSearchArray) => {
  let arr = [];

  dataList.forEach(item => {
    if (item.hierarchy) {
      // поиск подстроки в строке (если есть возврат - true) и тогда > arr.push(item)
      if (item.hierarchy[indexArr]?.full_name_short.toString().includes(e.target.value)) arr.push(item)
    }
  })

  setResultSearchArray(arr);
};

// получаем и устанавливаем выбранное значение
export const setElem = (e, setSelectedElem) => {
  e.preventDefault();
  setSelectedElem(e.target.textContent);
};
