export const advantagesList = [
  {
    link: '../assets/img/numbers/01.svg',
    title: 'Гарантия',
    text: 'Наши заказчики получают страховку на работоспособность лифтов на весь жизненный цикл работы оборудования, что обеспечивает спокойствие и уверенность в надежности эксплуатации'
  },
  {
    link: '../assets/img/numbers/02.svg',
    title: 'Контроль и сервис',
    text: 'Мы предоставляем объективный контроль за сервисом и диагностикой причин неисправностей, что позволяет оперативно решать возникающие проблемы'
  },
  {
    link: '../assets/img/numbers/03.svg',
    title: 'Страховка',
    text: 'Наши клиенты застрахованы от ухода производителей с рынка и возможных банкротств, благодаря нашей солидной репутации и надежной системе хранения запчастей'
  },
  {
    link: '../assets/img/numbers/04.svg',
    title: 'Решение потребностей',
    text: 'Работая с нами, вы расширяете свой горизонт мышления и планирования при покупке лифтов, получая оптимальное решение для своих потребностей'
  },
  {
    link: '../assets/img/numbers/05.svg',
    title: 'Экономия',
    text: 'Однократная оплата или рассрочка позволяет заказчикам и управляющим компаниям существенно сэкономить средства и снизить риски, связанные с покупкой дорогостоящего оборудования'
  },
  {
    link: '../assets/img/numbers/06.svg',
    title: 'Преимущества',
    text: 'Сотрудничество с нами дает заказчикам конкурентное маркетинговое преимущество перед другими застройщиками'
  },
  {
    link: '../assets/img/numbers/07.svg',
    title: 'Запчасти',
    text: 'Мы создаем стратегический запас запчастей для всех брендов лифтов, обеспечивая оперативное решение любых задач'
  },
  {
    link: '../assets/img/numbers/08.svg',
    title: 'Статистика',
    text: 'Наша объективная статистика анализа отказов способствует конкурентной саморегуляции среди производителей лифтов, управляющих и сервисных компаний'
  },
  {
    link: '../assets/img/numbers/09.svg',
    title: 'Безопасность',
    text: 'С нами вы повышаете безопасность эксплуатации, уменьшаете количество и время простоев, а также снижаете риск аварийных ситуаций'
  },
  {
    link: '../assets/img/numbers/10.svg',
    title: 'Паспорт',
    text: 'Каждый лифт с гарантией "Лифтап" имеет цифровой паспорт, в котором указаны все ремонты, замены узлов и деталей, ответственные лица. Цифровой паспорт невозможно потерять'
  },
];
