import { LIFTDOWN_STATUS_CHANGE } from "src/consts.js";
import { updateTokens } from "src/utils/updateTokens.js";

// запрос отправляет данные на изменение статуса заявки из панели администратора
export const fetchUpdateStatusLiftDown = async (bodyData) => {
  // запрос
  const response = await fetch(LIFTDOWN_STATUS_CHANGE, {
    method: 'POST',
    body: JSON.stringify(bodyData),
    headers: {
      'Content-type': 'application/json; charset=UTF-8',
      'User-Agent': 'LiftupSite/1.0.0',
      'Authorization': `Bearer ${localStorage.getItem('tokenlu')}`,
    },
  });
  const data = await response.json();
  // console.log('data: ', data);

  if (response.status === 401 || data.loginstatus === 7) {

    // перезапись токенов
    await updateTokens();

    // запрос после перезаписи токенов
    const response = await fetch(LIFTDOWN_STATUS_CHANGE, {
      method: 'POST',
      body: JSON.stringify(bodyData),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
        'User-Agent': 'LiftupSite/1.0.0',
        'Authorization': `Bearer ${localStorage.getItem('tokenlu')}`,
      },
    });
    const data = await response.json();
    console.log('data: ', data);

    console.log('обновление статуса заявки произошло c перезаписью токенов');

    return;
  }

  console.log('обновление статуса заявки произошло без перезаписи токенов');
};
