import st from './ClBasePreview.module.css';
import { useEffect, useRef, useState } from 'react';
import { Overlay } from 'src/components/Overlay/Overlay';
import { ClBaseEdit } from '../ClBaseEdit/ClBaseEdit.jsx';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { fetchOrgDel } from '../../fetches/fetchDel';
import { setCurrentOrganization } from 'src/store/currentOrganization/currentOrganization';
import { fetchClientbaseList } from 'src/store/clientbase/getClientbaseList';

export const ClBasePreview = ({
  uuid,
  id,

  nameorg,
  respusername,
  phone,
  email,
  statusorg,

  fullname,
  shortname,
  inn,
  serialOGRN,
  fnsinnKPP,
  // legaladdress,
  // physicaladdress,
  legaladdressextfias,
  physicaladdressextfias,
  directorname,
  bookername,

  accountnumber,
  bankaccountnumber,
  bankname,
  bankid
}) => {
  // console.log('statusorg: ', statusorg);
  const dispatch = useDispatch();
  // модальное окно "редактировать"
  const [modalEdit, setModalEdit] = useState(false);
  // меню редактировать удалить
  const [menuEditOrDel, setMenuEditOrDel] = useState(false);

  // разделы модального окна "ред-е клиента", "реквизитов клиента" и "банковских реквизитов"
  const [openClient, setOpenClient] = useState(false);
  const [openRequisit, setOpenRequisit] = useState(false);
  const [openRequisitBank, setOpenRequisitBank] = useState(false);

  // ссылки на элементы
  const refEditOrDel = useRef(null);
  const refTargetLi = useRef(null);

  // закрываем меню по клику вне элемента
  useEffect(() => {
    let handler = (e) => {
      if (!refEditOrDel.current.contains(e.target)) {
        setMenuEditOrDel(false);
      }
    };

    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    }
  });

  // удаляем организацию, закрываем меню, обновляем список организаций
  const organizationDel = async () => {
    setModalEdit(false);

    await fetchOrgDel(uuid); // пока не работает

    dispatch(fetchClientbaseList());

    console.log('Организация удалена!');
  };

  // показывает меню "редактировать удалить"
  const editOrDel = (e) => {
    e.preventDefault();

    setMenuEditOrDel(!menuEditOrDel);
  };

  // открывает окно "редактировать" выбранного элемента
  const editTargetClient = (e) => {
    e.preventDefault();

    setModalEdit(!modalEdit);

    setOpenClient(true);
    setOpenRequisit(false);
    setOpenRequisitBank(false);
  };

  // открывает окно "реквизиты" выбранного элемента
  const editTargetRequisit = (e) => {
    e.preventDefault();

    setModalEdit(true);

    setOpenClient(false);
    setOpenRequisit(true);
    setOpenRequisitBank(false);
  };

  // открывает окно "банковские реквизиты" выбранного элемента
  const editTargetRequisitBank = (e) => {
    e.preventDefault();

    setModalEdit(true);

    setOpenClient(false);
    setOpenRequisit(false);
    setOpenRequisitBank(true);
  };

  // записывает uuid организации в stor чтобы использовать
  // его для формирования списка оборудования организации по id
  const setCurrentOrg = () => {
    dispatch(setCurrentOrganization([uuid, shortname]));
  };

  return (
    <>
      <li
        id={id}
        ref={refTargetLi}
        onClick={setCurrentOrg}
      >
        {/* <Link className={st.clbase_item} to={`/superlift/equipment/${id}`}> */}
        <div className={st.clbase_item}>
          <div className={st.item_name}>
            <p className={st.item_name_p}>ID</p>
            <p className={st.item_id}>{id}</p>
          </div>

          <div className={st.item_name}>
            <p className={st.item_name_p}>Наименование</p>
            <h4 className={`${st.item_text} ${st.item_title}`}>{nameorg}</h4>
          </div>

          <div className={st.item_name}>
            <p className={st.item_name_p}>ИНН</p>
            <p className={st.item_text}>{inn}</p>
          </div>

          <div className={st.item_name}>
            <p className={st.item_name_p}>ФИО</p>
            <p className={st.item_text}>{respusername}</p>
          </div>

          <div className={st.item_name}>
            <p className={st.item_name_p}>Телефон</p>
            <p className={st.item_text}>{phone}</p>
          </div>

          <div className={st.item_name}>
            <p className={st.item_name_p}>e-mail</p>
            <p className={st.item_text}>{email}</p>
          </div>

          <div className={st.item_name}>
            <p className={st.item_name_p}>Статус</p>
            <div className={statusorg === 'VERIFIED'
              ? st.item_ver : st.item_nover}></div>
          </div>

          {/* кнопки открытия реквизитов клиента и его банковских реквизитов */}
          <div className={st.item_requisites}>
            <p className={st.item_name_p}>Реквизиты</p>
            <button
              className={st.btn_requisites}
              onClick={editTargetRequisit}></button>

            <button
              className={st.btn_bankrequisites}
              onClick={editTargetRequisitBank}></button>
          </div>

          {/* <div className={st.item_name}>
            <p className={st.item_name_p}>Оборуд</p>
            <div className={st.item_equip}>
              {quantityequipment}</div>
          </div> */}

          <div className={st.item_name}>
            <p className={st.item_name_p}>Оборуд</p>
            <Link className={st.item_equip} to={`/superlift/equipment/${id}`}></Link>
          </div>

          <div className={st.item_edit_del} ref={refEditOrDel}>
            <div className={st.item_btn} onClick={editOrDel}></div>

            <ul
              className={!menuEditOrDel ? st.btn_menu : st.btn_menu_show}
              onClick={(e) => { e.preventDefault() }}>
              {
                uuid !== '93448e23-f016-44f3-a53f-b888f5ee1a8d' ?
                  <li className={st.btn_menu_item} onClick={editTargetClient}>Редактировать</li> :
                  <li className={st.btn_menu_item} onClick={editTargetClient}><span>Редактировать управляющую организацию</span></li>
              }
              {
                uuid !== '93448e23-f016-44f3-a53f-b888f5ee1a8d' ?
                  <li className={st.btn_menu_item} onClick={organizationDel}>Удалить</li> :
                  <li className={st.btn_menu_item}></li>
              }
            </ul>
          </div>
        </div>
      </li>

      <Overlay className={!modalEdit ? st.overlay_hide : st.overlay_show}>
        <ClBaseEdit
          closeModal={() => setModalEdit(false)}

          id={id}
          uuid={uuid}

          nameorg={nameorg}
          respusername={respusername}
          phone={phone}
          email={email}
          statusorg={statusorg}

          fullname={fullname}
          shortname={shortname}
          inn={inn}
          serialOGRN={serialOGRN}
          fnsinnKPP={fnsinnKPP}
          // legaladdress={legaladdress}
          // physicaladdress={physicaladdress}
          legaladdressextfias={legaladdressextfias}
          physicaladdressextfias={physicaladdressextfias}
          directorname={directorname}
          bookername={bookername}

          accountnumber={accountnumber}
          bankaccountnumber={bankaccountnumber}
          bankname={bankname}
          bankid={bankid}

          openClient={openClient}
          openRequisit={openRequisit}
          openRequisitBank={openRequisitBank}
        />
      </Overlay>
    </>
  )
};
