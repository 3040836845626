import st from './AutorForm.module.css';
import { useDispatch } from 'react-redux';
import { useState, useEffect } from 'react';
import { fetchAuth } from 'src/store/authorization/auth';
import { Link, useNavigate } from 'react-router-dom';
import store from 'src/store/store.js';
import hashPassword from 'src/utils/hashPassword.js';
import handleInputChange from 'src/utils/handleInputChange.js';
import passwordShowHide from 'src/utils/passwordShowHide.js';

export const AuthorForm = () => {
  const [authorMail, setAuthorMail] = useState('');
  const [autorPassword, setAutorPassword] = useState('');
  const [showNoteMail, setShowNoteMail] = useState('');
  const [showNotePass, setShowNotePass] = useState('');

  const [role, setRole] = useState(store.getState().authReducer?.dataAuth?.user?.userroles);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [loginstatus, setLoginstatus] = useState(5);
  // console.log('loginstatus: ', loginstatus);

  // формируем объект для передачи в теле запроса
  const authorizationBody = {
    "loginName": authorMail,
    "passHash": hashPassword(autorPassword),
    "typelogin": 1
  };

  // готовый объект для передачи в теле запроса
  // const authorizationBody = {
  //   "loginName": "ivav@test.ru",
  //   "passHash": hashPassword("12345sS@"),
  //   "typelogin": 1
  // };

  // console.log('это хэш поля passHash: ', authorizationBody.passHash);

  // скрываем показываем пароль
  const [passwordVisibility, setPasswordVisibility] = useState('password');

  const patternMail = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
  const patternPassword = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[ -/:-@[-`{-~]).{8,64}$/;

  // авторизация по кнопке "войти"
  const inAuth = (e) => {
    e.preventDefault();

    // получаем токен и юзера
    dispatch(fetchAuth(authorizationBody));

    // блокируем кнопку "Войти"
    setShowNoteMail('');
    setShowNotePass('');

    // очищаем поля ввода
    setAuthorMail('');
    setAutorPassword('');
  };

  useEffect(() => {
    // подписка на изменение значения "role"
    store.subscribe(() => setRole(store.getState().authReducer?.dataAuth?.user?.userroles));
    if (role === 999) navigate('/superlift/main');

    if (role === 1) {
      const { id } = store.getState().authReducer?.dataAuth?.organization;
      navigate(`/lk/${id}/aboutcompany`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [role]);

  useEffect(() => {
    // подписка на изменение значения "loginstatus"
    store.subscribe(() => setLoginstatus(store.getState().authReducer?.dataAuth?.loginstatus));
    if (!loginstatus) setLoginstatus(5);
  }, [loginstatus]);

  return (
    <>
      <h2 className={st.title}>Добро пожаловать</h2>

      <p className={st.description}>Введите свои данные для доступа в личный кабинет</p>

      <Link className={st.close_btn} to='/'></Link>

      <div className={st.reg_autor_btns}>
        <button
          className={st.reg_autor_btn_active}
        // onClick={() => navigate('/signin/author')}
        >Авторизация</button>
        <button
          className={st.reg_autor_btn}
          onClick={() => navigate('/signin/reg')}
        >Регистрация</button>
      </div>

      <form className={st.autor_form}>
        <div className={st.note_wrap}>

          <p className={
            showNoteMail === '' ? st.note_empty : showNoteMail ? st.note_hide : st.note_mail
          }>Введите корректный адрес</p>

          <p className={loginstatus !== 5 ? st.note_mail : st.note_hide}>Неверный логин или пароль</p>

          <input
            className={st.input}
            type='email '
            name='autormail'
            value={authorMail}
            onChange={
              (e) => handleInputChange(e, patternMail, setShowNoteMail, setAuthorMail)
            }
            onMouseDown={() => {
              setLoginstatus(5);
            }}
            placeholder='E-mail'
          />
        </div>

        <div className={st.note_wrap}>
          <p className={showNotePass === '' ? st.note_empty : showNotePass ? st.note_hide : st.note_pass}>
            Пароль должен содержать не менее 8 символов и состоять из больших латинских букв, маленьких латинских букв, цифр и символов
          </p>

          <p className={
            loginstatus !== 5 ? st.note_mail : st.note_hide
          }>Неверный логин или пароль</p>

          <input
            className={st.input}
            type={passwordVisibility}
            name='autorpassword'
            value={autorPassword}
            onChange={
              (e) => handleInputChange(e, patternPassword, setShowNotePass, setAutorPassword)
            }
            onMouseDown={() => {
              setLoginstatus(5);
            }}
            placeholder='Пароль'
          />

          <div className={st.input_wrap}><button
            className={passwordVisibility === 'password' ? st.input_btn_closed : st.input_btn_open}
            onClick={(e) => passwordShowHide(e, passwordVisibility, setPasswordVisibility)}
          ></button></div>
        </div>

        <button
          className={st.button_submit}
          onClick={inAuth}
          disabled={
            (showNoteMail && showNotePass) ? false : true
          }
        >Войти</button>
      </form>

      <button
        className={st.button_forgot}
        onClick={() => navigate('/signin/passrecphone')}
      >Забыли пароль?</button>
    </>
  )
};
