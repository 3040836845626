import { DOCS_REORDER } from "src/consts";
import { updateTokens } from "src/utils/updateTokens";

// смена порядка документов
export const fetchDocReorder = async (bodyReorder) => {
  // отправляем запрос на смену порядковых номеров документов
  const responsedocreorder = await fetch(DOCS_REORDER, {
    method: 'POST',
    body: JSON.stringify(bodyReorder),
    headers: {
      'Content-type': 'application/json; charset=UTF-8',
      'User-Agent': 'LiftupSite/1.0.0',
      'Authorization': `Bearer ${localStorage.getItem('tokenlu')}`,
    },
  });
  const data = await responsedocreorder.json();
  // console.log('data1: ', data);
  console.log('данные на смену порядковых номеров документов отправлены без перезаписи токенов');

  // в случае протухания токена обновляем пару "token - rtoken"
  if (responsedocreorder.status === 401 || data.loginstatus === 7) {
    // перезапись токенов
    await updateTokens();

    // снова отправляем запрос 
    const responsedocreorder = await fetch(DOCS_REORDER, {
      method: 'POST',
      body: bodyReorder,
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
        'User-Agent': 'LiftupSite/1.0.0',
        'Authorization': `Bearer ${localStorage.getItem('tokenlu')}`,
      },
    });
    const data = await responsedocreorder.json();
    console.log('data2: ', data);
    console.log('данные на смену порядковых номеров документов отправлены c перезаписью токенов');
  }
};
