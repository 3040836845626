import st from './LiftDownPreview.module.css';
import { useEffect, useRef, useState } from 'react';
import { Overlay } from 'src/components/Overlay/Overlay';
import { LiftDownEdit } from '../LiftDownEdit/LiftDownEdit.jsx';
import { useDispatch } from 'react-redux';
import { fetchLiftDownDel } from '../../fetches/fetchDel';
import { fetchLiftDownList } from 'src/store/liftdown/getLiftDownList';

export const LiftDownPreview = ({
  uid,
  organizationName,
  factorynumber,
  typeOfEquipment,
  installationaddress,
  statusstate,
  dateinit,
  manufacturer,
  brand,
  partname,
  partid
}) => {
  const refEditOrDel = useRef(null);
  const dispatch = useDispatch();

  // модальное окно редактирования заявки
  const [modalEdit, setModalEdit] = useState(false);
  // показываем скрываем меню редактировать удалить
  const [menuEditOrDel, setMenuEditOrDel] = useState(false);

  // const [openStatement, setOpenClient] = useState(false);

  // функция заменяет латинские названия на русские
  const handlerTypeEquip = () => {
    let typeOfEquipmentRus = '';

    if (typeOfEquipment === 'Elevator') typeOfEquipmentRus = 'Лифт';
    if (typeOfEquipment === 'Escalator') typeOfEquipmentRus = 'Эскалатор';
    if (typeOfEquipment === 'Travolator') typeOfEquipmentRus = 'Травалатор';

    return typeOfEquipmentRus;
  };

  // функция заменяет латинские названия на русские
  const handlerStatusState = () => {
    let statusstateRus = '';

    if (statusstate === 'NEW') statusstateRus = 'Новая';
    if (statusstate === 'INWORK') statusstateRus = 'В работе';
    if (statusstate === 'CLOSED') statusstateRus = 'Закрыта';
    if (statusstate === 'REJECTED') statusstateRus = 'Отменена';

    return statusstateRus;
  };

  // открываем закрываем меню редактировать удалить
  const editOrDel = (e) => {
    e.preventDefault();
    setMenuEditOrDel(!menuEditOrDel);
  };

  // открываем модальное окно редактирование заявки
  const editTargetStatement = (e) => {
    if (e.target.dataset.exclude) {
      return
    } else {
      setModalEdit(!modalEdit);
      // setOpenClient(true);
    }
  };

  // функция отправки запроса на удаление заявки
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    await fetchLiftDownDel(uid);

    // обновляем список заявок в stor
    dispatch(fetchLiftDownList());
    setModalEdit(false);
    console.log('форма отправлена');
  };

  // закрываем меню по клику вне элемента
  useEffect(() => {
    let handler = (e) => {
      if (!refEditOrDel.current.contains(e.target)) {
        setMenuEditOrDel(false);
      }
    };

    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    }
  });


  return (
    <>
      <li
        className={st.liftdown_item}
        id={uid}
        onClick={editTargetStatement}
      >
        <div className={st.item_name}>
          <p className={st.item_name_p}>ID</p>
          <p className={st.item_text}>{uid}</p>
        </div>

        <div className={st.item_name}>
          <p className={st.item_name_p}>Наименование клиента</p>
          <p className={st.item_text}>{organizationName}</p>
        </div>

        <div className={st.item_name}>
          <p className={st.item_name_p}>Заводской номер</p>
          <p className={st.item_text}>{factorynumber}</p>
        </div>

        <div className={st.item_name}>
          <p className={st.item_name_p}>Оборудование</p>
          <p className={st.item_text}>{handlerTypeEquip()}</p>
        </div>

        <div className={st.item_name}>
          <p className={st.item_name_p}>Адрес</p>
          <p className={st.item_text}>{`
          г. ${installationaddress?.city}, 
          ул. ${installationaddress?.street}, 
          д. ${installationaddress?.house}, 
          п. ${installationaddress?.entrance}
          `}</p>
        </div>

        <div className={st.item_name}>
          <p className={st.item_name_p}>Статус</p>
          <p className={st.item_text}>{handlerStatusState()}</p>
        </div>

        <div className={st.item_name}>
          <p className={st.item_name_p}>Дата Заявки</p>
          <p className={st.item_text}>{
            dateinit.slice(0, -9).split('-').reverse().join('-')
          }</p>
        </div>

        <div className={st.item_edit_del} ref={refEditOrDel} >
          <div
            className={st.item_btn}
            data-exclude='excludeevent'
            onClick={editOrDel}></div>

          <ul
            className={!menuEditOrDel ? st.btn_menu : st.btn_menu_show}
            data-exclude='excludeevent'>

            <li className={st.btn_menu_item}>Редактировать</li>

            <li
              className={st.btn_menu_item}
              data-exclude='excludeevent' onClick={handleFormSubmit}>Удалить</li>
          </ul>
        </div>
      </li>

      <Overlay className={!modalEdit ? st.overlay_hide : st.overlay_show}>
        <LiftDownEdit
          closeModal={() => setModalEdit(false)}
          // openStatement={openStatement}

          uid={uid}
          organizationName={organizationName}
          factorynumber={factorynumber}
          typeOfEquipment={typeOfEquipment}
          installationaddress={installationaddress}
          statusstate={statusstate}
          dateinit={dateinit}
          manufacturer={manufacturer}
          brand={brand}
          partname={partname}
          partid={partid}
        />
      </Overlay>
    </>
  )
};
