import { Outlet } from 'react-router-dom';
import { Footer } from 'src/modules/Footer/Footer';
import { Header } from 'src/modules/Header/Header';
import { LineDisapp } from '../LineDisapp/LineDisapp.jsx';
import { MobMenu } from 'src/modules/MobMenu/MobMenu.jsx';

export const Layout = () => {

  return (
    <>
      <Header />
      <MobMenu />
      <div className='body_container'>
        <LineDisapp />
        <Outlet />
      </div>
      <Footer />
    </>
  )
};
