import { MEDIAFILE_ADD, NEWS_ADD } from 'src/consts';
import { updateTokens } from 'src/utils/updateTokens';

// двойной запрос: добавляем медиафайл, добавляем остальные данные с ответом из первого запроса
export const fetchNewsWithPhotoAddEdit = async (bodyMediaFile, bodyNewsData) => {
  // первый запрос
  const responsemediafile = await fetch(MEDIAFILE_ADD, {
    method: 'POST',
    body: bodyMediaFile,
    headers: {
      'User-Agent': 'LiftupSite/1.0.0',
      'Authorization': `Bearer ${localStorage.getItem('tokenlu')}`,
    },
  });
  const datamediafile = await responsemediafile.json();

  // в случае протухания токена обновляем пару "token - rtoken"
  if (datamediafile.loginstatus === 7) {

    // перезапись токенов
    await updateTokens();

    // первый запрос
    const responsemediafile = await fetch(MEDIAFILE_ADD, {
      method: 'POST',
      body: bodyMediaFile,
      headers: {
        'User-Agent': 'LiftupSite/1.0.0',
        'Authorization': `Bearer ${localStorage.getItem('tokenlu')}`,
      },
    });
    const datamediafile = await responsemediafile.json();

    bodyNewsData.imageuid = datamediafile.mediafileuid;

    // второй запрос
    const responsenewsadd = await fetch(NEWS_ADD, {
      method: 'POST',
      body: JSON.stringify(bodyNewsData),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
        'User-Agent': 'LiftupSite/1.0.0',
        'Authorization': `Bearer ${localStorage.getItem('tokenlu')}`,
      },
    });
    const datanewsadd = await responsenewsadd.json();
    console.log('datanewsadd: ', datanewsadd);
    console.log('сработал вариант отправки с фотографией новости c перезаписью токенов');

  } else {

    bodyNewsData.imageuid = datamediafile.mediafileuid;

    // второй запрос
    const responsenewsadd = await fetch(NEWS_ADD, {
      method: 'POST',
      body: JSON.stringify(bodyNewsData),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
        'User-Agent': 'LiftupSite/1.0.0',
        'Authorization': `Bearer ${localStorage.getItem('tokenlu')}`,
      },
    });
    const datanewsadd = await responsenewsadd.json();
    console.log('datanewsadd: ', datanewsadd);
    console.log('сработал вариант отправки с фотографией новости');
  }
};

export const fetchNewsNoPhotoAddEdit = async (bodyNewsData) => {
  // второй запрос
  const responsenewsadd = await fetch(NEWS_ADD, {
    method: 'POST',
    body: JSON.stringify(bodyNewsData),
    headers: {
      'Content-type': 'application/json; charset=UTF-8',
      'User-Agent': 'LiftupSite/1.0.0',
      'Authorization': `Bearer ${localStorage.getItem('tokenlu')}`,
    },
  });
  const datanewsadd = await responsenewsadd.json();

  if (datanewsadd.loginstatus !== 7) {
    console.log('сработал вариант отправки без фото новости');
  };

  // в случае протухания токена обновляем пару "token - rtoken"
  if (datanewsadd.loginstatus === 7) {

    // перезапись токенов
    await updateTokens();

    // второй запрос
    const responsenewsadd = await fetch(NEWS_ADD, {
      method: 'POST',
      body: JSON.stringify(bodyNewsData),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
        'User-Agent': 'LiftupSite/1.0.0',
        'Authorization': `Bearer ${localStorage.getItem('tokenlu')}`,
      },
    });
    const datanewsadd = await responsenewsadd.json();

    console.log('datanewsadd: ', datanewsadd);
    console.log('сработал вариант отправки без фото новости c перезаписью токенов');
  }
};
