import st from './LiftDownEdit.module.css';
import { ReactComponent as BtnClose } from '../../../../../../img/btn_close.svg';
import { useEffect, useState } from 'react';
import { fetchUpdateStatusLiftDown } from '../../fetches/fetchUpdateStatus';
import { useDispatch } from 'react-redux';
import { fetchLiftDownList } from 'src/store/liftdown/getLiftDownList';

export const LiftDownEdit = ({
  closeModal,

  uid,
  organizationName,
  factorynumber,
  typeOfEquipment,
  installationaddress,
  statusstate,
  dateinit,
  manufacturer,
  brand,
  partname,
  partid
}) => {
  const dispatch = useDispatch();

  // поля модального окна редактируемой заявки
  const [statementStatus, setStatementStatus] = useState('');
  const [statementId, setStatementId] = useState('');
  const [statementNameClient, setStatementNameClient] = useState('');
  const [statementFactorynumber, setStatementFactorynumber] = useState('');
  const [statementEquip, setStatementEquip] = useState('');
  const [statementDate, setStatementDate] = useState('');
  const [statementManufacture, setStatementManufacture] = useState('');
  const [statementBrand, setStatementBrand] = useState('');
  const [statementDetailname, setStatementDetailname] = useState('');
  const [statementDetailid, setStatementDetailid] = useState('');
  const [statementAddressinstall, setStatementAddressinstall] = useState({});

  useEffect(() => {
    setStatementStatus(statusstate);
    setStatementManufacture(manufacturer);
    setStatementBrand(brand);
    setStatementDetailname(partname);
    setStatementId(uid);
    setStatementNameClient(organizationName);
    setStatementFactorynumber(factorynumber);
    setStatementEquip(typeOfEquipment);
    setStatementAddressinstall(installationaddress);
    setStatementDate(dateinit);
    setStatementDetailid(partid);
    // setOpenStatementWin(openStatement);
  }, [
    statusstate,
    manufacturer,
    brand,
    partname,
    uid,
    organizationName,
    factorynumber,
    typeOfEquipment,
    installationaddress,
    dateinit,
    partid,
    // openStatement
  ]);

  // данные для тела запроса подачи заявки на гарантийное обслуживание
  const bodyUpdateStatus = {
    'uid': uid,
    'status': statementStatus
  };

  // отправляет форму
  const handleFormSubmit = async (e) => {
    e.preventDefault();

    await fetchUpdateStatusLiftDown(bodyUpdateStatus);

    // обновляем список заявок в stor
    dispatch(fetchLiftDownList());

    await closeModal();
    console.log('форма отправлена');

    // if (!openClosedBtn) listOpened()
    // if (!openClosedBtn) listClosed()

    // listOpened();
  };

  return (
    <div className={st.clientsedit_wrap}>
      <button className={st.btn_close} onClick={() => {
        closeModal();
      }}>
        <BtnClose />
      </button>

      <div className={st.clientsedit}>
        <form className={st.form}>

          <fieldset className={st.ft_edit_client}>
            <h3 className={st.title}>Заявка</h3>

            {/* инпут редактирования статуса заявки */}
            <div className={st.wrap_title}>
              {/* <p className={statementStatus === '' ? st.wrap_title_p : st.wrap_title_p_hide}>
                Статус заявки</p> */}

              <div className={st.input_wrap}>
                <label className={st.label_title}>
                  <select
                    className={st.input_select}
                    name='statementStatus'
                    value={statementStatus}

                    onChange={(e) => setStatementStatus(e.target.value)}>
                    <option value=''>Статус заявки</option>
                    <option value='NEW'>Новая</option>
                    <option value='INWORK'>В работе</option>
                    <option value='CLOSED'>Закрыта</option>
                    <option value='REJECTED'>Отменена</option>
                  </select>
                </label>
              </div>
            </div>

            {/* поле ID заявки  */}
            <div className={st.edit_input}>
              <div className={st.edit_field_wrap}>
                <p className={st.edit_field_title}>ID&nbsp;заявки</p>

                <p className={st.edit_field_text}>{
                  statementId ? statementId : 'нет данных'
                }</p>
              </div>
            </div>

            {/* поле наименование клиента  */}
            <div className={st.edit_input}>
              <div className={st.edit_field_wrap}>
                <p className={st.edit_field_title}>Наименование&nbsp;клиента</p>

                <p className={st.edit_field_text}>{
                  statementNameClient ? statementNameClient : 'нет данных'
                }</p>
              </div>
            </div>

            {/* поле заводской номер  */}
            <div className={st.edit_input}>
              <div className={st.edit_field_wrap}>
                <p className={st.edit_field_title}>Заводской&nbsp;номер</p>

                <p className={st.edit_field_text}>{
                  statementFactorynumber ? statementFactorynumber : 'нет данных'
                }</p>
              </div>
            </div>

            {/* поле оборудование  */}
            <div className={st.edit_input}>
              <div className={st.edit_field_wrap}>
                <p className={st.edit_field_title}>Оборудование</p>

                <p className={st.edit_field_text}>{
                  statementEquip ? statementEquip : 'нет данных'
                }</p>
              </div>
            </div>

            {/* поле производитель  */}
            <div className={st.edit_input}>
              <div className={st.edit_field_wrap}>
                <p className={st.edit_field_title}>Производитель</p>

                <p className={st.edit_field_text}>{
                  statementManufacture ? statementManufacture : 'нет данных'
                }</p>
              </div>
            </div>

            {/* поле марка  */}
            <div className={st.edit_input}>
              <div className={st.edit_field_wrap}>
                <p className={st.edit_field_title}>Марка</p>

                <p className={st.edit_field_text}>{
                  statementBrand ? statementBrand : 'нет данных'
                }</p>
              </div>
            </div>

            {/* поле название детали  */}
            <div className={st.edit_input}>
              <div className={st.edit_field_wrap}>
                <p className={st.edit_field_title}>Название&nbsp;детали</p>

                <p className={st.edit_field_text}>{
                  statementDetailname ? statementDetailname : 'нет данных'
                }</p>
              </div>
            </div>

            {/* поле уникальный номер детали  */}
            <div className={st.edit_input}>
              <div className={st.edit_field_wrap}>
                <p className={st.edit_field_title}>Уникальный&nbsp;номер&nbsp;детали</p>

                <p className={st.edit_field_text}>{
                  statementDetailid ? statementDetailid : 'нет данных'
                }</p>
              </div>
            </div>

            {/* поле адрес установки  */}
            <div className={st.edit_input}>
              <div className={st.edit_field_wrap}>
                <p className={st.edit_field_title}>Адрес&nbsp;установки</p>

                <p className={st.edit_field_text}>{statementAddressinstall ?
                  `г. ${statementAddressinstall.city}, 
                  ул. ${statementAddressinstall.street}, 
                  д. ${statementAddressinstall.house}, 
                  п. ${statementAddressinstall.entrance}
                  ` : 'нет данных'}</p>
              </div>
            </div>

            {/* поле дата создания */}
            <div className={st.edit_input}>
              <div className={st.edit_field_wrap}>
                <p className={st.edit_field_title}>Дата&nbsp;создания</p>

                <p className={st.edit_field_text}>{
                  statementDate ? statementDate.slice(0, -9).split('-').reverse().join('-') : 'нет данных'
                }</p>
              </div>
            </div>

            <button
              className={st.btn_submit}
              onClick={handleFormSubmit}
              disabled={
                statementStatus === '' ? true : false
              }
            >Сохранить</button>
          </fieldset>
        </form>
      </div>
    </div>
  )
};
