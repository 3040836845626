import st from './NewPassword.module.css';
// import { useDispatch } from 'react-redux';
import { useState } from 'react';
// import { fetchAuth } from 'src/store/authorization/auth';
import { Link, useNavigate } from 'react-router-dom';
// import store from 'src/store/store.js';
// import hashPassword from 'src/utils/hashPassword.js';
import handleInputChange from 'src/utils/handleInputChange.js';
import passwordShowHide from 'src/utils/passwordShowHide.js';

export const NewPassword = () => {
  const [newPassword1, setNewPassword1] = useState('');
  const [newPassword2, setNewPassword2] = useState('');

  const [showNotePass1, setShowNotePass1] = useState('');
  const [showNotePass2, setShowNotePass2] = useState('');

  const [passwordVisibility, setPasswordVisibility] = useState('password');
  // const [passwordVisibility2, setPasswordVisibility2] = useState('password');

  // const [role, setRole] = useState(store.getState().authReducer?.dataAuth?.user?.userroles);

  const navigate = useNavigate();
  // const dispatch = useDispatch();

  // let loginstatus = useSelector(state => state.authReducer.dataAuth.loginstatus) || 5;
  // const [loginstatus, setLoginstatus] = useState(useSelector(state => state.authReducer.dataAuth.loginstatus) || 5);
  // const [loginstatus, setLoginstatus] = useState(5);
  // console.log('loginstatus: ', loginstatus);

  // формируем объект для передачи в теле запроса
  // const newPasswordBodyRes = {
  //   "passHash": hashPassword(newPassword1),
  //   "typelogin": 2
  // };



  const patternPassword = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[ -/:-@[-`{-~]).{8,64}$/;

  // авторизация по кнопке "войти"
  // const inAuth = (e) => {
  //   e.preventDefault();

  //   // получаем токен и юзера
  //   dispatch(fetchAuth(authorizationBody));

  //   // блокируем кнопку "Войти"
  //   setShowNoteMail('');
  //   setShowNotePass('');

  //   // очищаем поля ввода
  //   setAuthorMail('');
  //   setAutorPassword('');
  // };


  return (
    <>
      <h2 className={st.title}>Введите новый пароль</h2>

      <Link className={st.close_btn} to='/'></Link>

      <form className={st.autor_form}>
        <div className={st.note_wrap}>
          <p className={showNotePass1 === '' ? st.note_empty : showNotePass1 ? st.note_hide : st.note_pass}>
            Пароль должен содержать не менее 8 символов и состоять из больших латинских букв, маленьких латинских букв, цифр и символов
          </p>

          <input
            className={st.input}
            type={passwordVisibility}
            name='newPassword1'
            value={newPassword1}
            onChange={(e) => handleInputChange(e, patternPassword, setShowNotePass1, setNewPassword1)}
            onMouseDown={() => {
              // setLoginstatus(5);
            }}
            placeholder='Новый пароль'
          />

          <div className={st.input_wrap}>
            <button
              className={passwordVisibility === 'password' ? st.input_btn_closed : st.input_btn_open}
              onClick={(e) => passwordShowHide(e, passwordVisibility, setPasswordVisibility)}
            ></button>
          </div>
        </div>

        <div className={st.note_wrap}>
          <p className={showNotePass2 === '' ? st.note_empty : newPassword1 === newPassword2 ? st.note_hide : st.note_pass2}>
            Пароли не совпадают
          </p>

          <input
            className={st.input}
            type={passwordVisibility}
            name='newPassword2'
            value={newPassword2}
            onChange={(e) => handleInputChange(e, patternPassword, setShowNotePass2, setNewPassword2)}
            onMouseDown={() => {
              // setLoginstatus(5);
            }}
            placeholder='Повторный ввод пароля'
          />
        </div>

        <button
          className={st.button_submit}
          // onClick={inAuth}
          disabled={
            (showNotePass1 && showNotePass1 === showNotePass2) ? false : true
          }
        >Отправить</button>

        <button
          className={st.button_submit}
          onClick={() => navigate('/signin/passchangesuccess')}
        >Шаг вперед</button>
      </form>
    </>
  )
};
