import st from './ServiceAdd.module.css';
import { ReactComponent as BtnClose } from '../../../../../../img/btn_close.svg';
import { ReactComponent as BtnLoad } from '../../../../../../img/btn_folder.svg';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { fetchServicecentersList } from 'src/store/servicecenters/getServicecentersList';
import { fetchServiceCentersAddEdit } from '../../fetches/fetchAddEdit';
import addressMask from 'src/utils/addressMask.js';
import { fetchServCentCoordinates } from '../../fetches/fetchServCentCoordinates';
import { fetchEditWithoutImgServiceCenter } from '../../fetches/fetchEditWithoutImg';

export const ServiceAdd = ({

  closeModal,
  serviceCentres,
  status

}) => {

  // для определения максимального имеющегося значения счетчика заявок
  const [nextOrder, setNextOrder] = useState(0);

  useEffect(() => {
    // при перезагрузке или открытии прямой ссылки чтобы исключить ошибку undefined
    if (status === 'success' && serviceCentres && serviceCentres.length) {
      const maxOrder = Array.from(serviceCentres).reduce((previous, current) => {
        if (+current.countnum > +previous.countnum) {
          return current;
        } else {
          return previous;
        }
      });
      setNextOrder(maxOrder.countnum + 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serviceCentres]);

  // превью выбранного файла
  const [imagePreview, setImagePreview] = useState(null);
  // инпут ввода наименования сервисного центра
  const [title, setTitle] = useState('');
  // селект ввода города сервисного центра
  const [serviceCity, setServiceCity] = useState('');
  // инпут ввода адреса сервисного центра
  const [serviceAddress, setServiceAddress] = useState('');
  // инпут ввода режима работы сервисного центра
  const [serviceJobschedule, setServiceJobschedule] = useState('');
  // инпут ввода телефона сервисного центра
  const [servicePhone, setServicePhone] = useState('');
  // инпут ввода почты сервисного центра
  const [serviceEmail, setServiceEmail] = useState('');

  // ссылка на инпут с кнопкой для выбора файла для загрузки на ПК
  const fileSelection = useRef(null);

  // данные выбранного для отправки файла
  const [selectedFileDouble, setSelectedFileDouble] = useState('');

  const dispatch = useDispatch();

  // меняет значение в инпуте на отформатированное
  const addressFormatter = (e) => {
    const formattedTargetValue = addressMask(e.target.value);

    e.target.value = formattedTargetValue;

    setServiceAddress(e.target.value);
  }

  // данные для второго запроса
  const serviceCentersDataBody = {
    'uid': '',
    'logo': '',
    'name': title,
    'note': '',
    'city': serviceCity,
    'address': serviceAddress,
    'jobschedule': serviceJobschedule,
    'email': serviceEmail,
    'phone': servicePhone,
    'countnum': nextOrder,
    // 'coordinates': pointsNumbers
  };

  // выбирает новый файл на ПК и вставляет в превью
  const previewFile = (e) => {
    const reader = new FileReader();

    const selectedFile = e.target.files[0];

    setSelectedFileDouble(selectedFile);

    if (selectedFile) {
      reader.readAsDataURL(selectedFile);
    }

    reader.onload = (readerEvent) => {
      if (selectedFile.type.includes('image')) {
        setImagePreview(readerEvent.target.result);
      }
    };
  };

  // удаляем поле "превью файла"
  const clearPreviewFile = (e) => {
    e.preventDefault();
    setImagePreview(null);
  };

  // отправляет форму
  const handleFormSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('uploadedFile', selectedFileDouble);
    console.log('formData: ', formData);

    // вставляем в объект данныех для второго запроса поле с полученными координатами
    serviceCentersDataBody.coordinates = await fetchServCentCoordinates(serviceCity, serviceAddress);

    // если лого не добавлен отправляем запрос на добавление сервисного центра
    if (formData.get('uploadedFile') === '') {
      console.log('пустая строка');

      await fetchEditWithoutImgServiceCenter(serviceCentersDataBody);
    }
    // если лого добавлен отправляем запрос на добавление сервисного центра
    if (formData.get('uploadedFile') !== '') {
      console.log('НЕЕЕЕЕ пустая строка: ', formData.get('uploadedFile'));

      await fetchServiceCentersAddEdit(formData, serviceCentersDataBody);
    }

    // обновляем список сервисных центров
    dispatch(fetchServicecentersList());

    closeModal();

    console.log('форма отправлена');
  };

  return (
    <div className={st.clientsadd_wrap}>
      <button className={st.btn_close} onClick={closeModal}>
        <BtnClose />
      </button>

      <div className={st.clientsadd}>
        <h3 className={st.title}>Добавление сервисного центра</h3>

        <form className={st.form}>

          {/* блок с превью выбранного для загрузки нового слайда */}
          <div className={imagePreview !== null ? st.image_preview_show : st.image_preview_hide}>
            <p className={st.image_preview_show_p}>Логотип</p>

            <div className={st.image_preview}>
              {imagePreview !== null && <img src={imagePreview} alt='' />}
              <button className={st.btn_delete} onClick={clearPreviewFile}></button>
            </div>
          </div>

          {/* инпут с кнопкой для выбора файла для загрузки c ПК */}
          <fieldset className={imagePreview === null ? st.fieldset_show : st.fieldset_hide}>
            <label className={st.label_image}>
              <input
                ref={fileSelection}
                className={st.input_image}
                type='file'
                name='image'
                accept='image/*,image/jpeg,image/png,image/svg'
                onChange={previewFile}
              />
              <p>Логотип...</p>
              <BtnLoad />
            </label>
          </fieldset>

          {/* инпут ввода наименования сервисного центра */}
          <div className={st.wrap_title}>
            <p className={title !== '' ? st.wrap_title_p : st.wrap_title_p_hide}>
              Наименование</p>

            <fieldset className={st.fieldset_title}>
              <label className={st.label_title}>
                <input
                  className={st.input_title}
                  type='text'
                  name='title'
                  value={title}
                  placeholder='Наименование'
                  onChange={(e) => setTitle(e.target.value)}
                />
              </label>
            </fieldset>
          </div>

          {/* инпут ввода города сервисного центра */}
          {/* <div className={st.wrap_title}>
            <p className={serviceCity !== '' ? st.wrap_title_p : st.wrap_title_p_hide}>Город</p>

            <fieldset className={st.fieldset_title}>
              <label className={st.label_title}>
                <input
                  className={st.input_title}
                  type='text'
                  name='serviceCity'
                  value={serviceCity}
                  placeholder='Город'
                  onChange={(e) => setServiceCity(e.target.value)}
                />
              </label>
            </fieldset>
          </div> */}

          {/* селект выбора города сервисного центра */}
          <div className={st.wrap_select}>
            <p className={serviceCity !== '' ? st.hint_show : st.hint_hide}>
              Выбор города</p>

            <div className={st.select_wrap}>
              <label className={st.label}>
                <select
                  className={st.select}
                  name='serviceCity'
                  value={serviceCity}

                  onChange={(e) => setServiceCity(e.target.value)}>
                  <option value=''>Выберите город</option>
                  <option value='Москва'>Москва</option>
                  <option value='Санкт-Петербург'>Санкт-Петербург</option>
                  <option value='Казань'>Казань</option>
                  <option value='Уфа'>Уфа</option>
                  <option value='Омск'>Омск</option>
                  <option value='Екатеринбург'>Екатеринбург</option>
                  <option value='Тюмень'>Тюмень</option>
                  <option value='Новосибирск'>Новосибирск</option>
                </select>
              </label>
            </div>
          </div>

          {/* инпут ввода адреса сервисного центра */}
          <div className={st.wrap_title}>
            <p className={serviceAddress !== '' ? st.wrap_title_p : st.wrap_title_p_hide}>Адрес</p>

            <fieldset className={st.fieldset_title}>
              <label className={st.label_title}>
                <input
                  className={st.input_title}
                  type='text'
                  name='serviceAddress'
                  value={serviceAddress}
                  placeholder='Адрес'
                  onChange={(e) => addressFormatter(e)}
                />
              </label>
            </fieldset>
          </div>

          {/* инпут ввода режима работы сервисного центра */}
          <div className={st.wrap_title}>
            <p className={serviceJobschedule !== '' ? st.wrap_title_p : st.wrap_title_p_hide}>
              График работы</p>

            <fieldset className={st.fieldset_title}>
              <label className={st.label_title}>
                <input
                  className={st.input_title}
                  type='text'
                  name='serviceJobschedule'
                  value={serviceJobschedule}
                  placeholder='График работы'
                  onChange={(e) => setServiceJobschedule(e.target.value)}
                />
              </label>
            </fieldset>
          </div>

          {/* инпут ввода телефона сервисного центра */}
          <div className={st.wrap_title}>
            <p className={servicePhone !== '' ? st.wrap_title_p : st.wrap_title_p_hide}>
              Номер телефона</p>

            <fieldset className={st.fieldset_title}>
              <label className={st.label_title}>
                <input
                  className={st.input_title}
                  type='text'
                  name='servicePhone'
                  value={servicePhone}
                  placeholder='Номер телефона'
                  onChange={(e) => setServicePhone(e.target.value)}
                />
              </label>
            </fieldset>
          </div>

          {/* инпут ввода E-mail сервисного центра */}
          <div className={st.wrap_title}>
            <p className={serviceEmail !== '' ? st.wrap_title_p : st.wrap_title_p_hide}>
              E-mail</p>

            <fieldset className={st.fieldset_title}>
              <label className={st.label_title}>
                <input
                  className={st.input_title}
                  type='text'
                  name='serviceEmail'
                  value={serviceEmail}
                  placeholder='E-mail'
                  onChange={(e) => setServiceEmail(e.target.value)}
                />
              </label>
            </fieldset>
          </div>

          <button
            className={st.btn_submit}
            onClick={handleFormSubmit}
            disabled={
              title === '' ||
                serviceCity === '' ||
                serviceAddress === '' ||
                serviceJobschedule === '' ||
                servicePhone === '' ||
                serviceEmail === '' ? true : false}
          >Добавить</button>
        </form>
      </div>
    </div>
  )
};
