import { MEDIAFILE_ADD, SLIDES_ADD } from "src/consts";
import { updateTokens } from "src/utils/updateTokens";

// двойной запрос: добавляем медиафайл, добавляем остальные данные с ответом из первого запроса
export const fetchSlideAddEdit = async (bodyMediaFile, bodySlidesData) => {
  // первый запрос
  const responsemediafile = await fetch(MEDIAFILE_ADD, {
    method: 'POST',
    body: bodyMediaFile,
    headers: {
      'User-Agent': 'LiftupSite/1.0.0',
      'Authorization': `Bearer ${localStorage.getItem('tokenlu')}`,
    },
  });
  const datamediafile = await responsemediafile.json();

  // в случае протухания токена обновляем пару "token - rtoken"
  if (datamediafile.loginstatus === 7) {
    // перезапись токенов
    await updateTokens();
    // первый запрос
    const responsemediafile = await fetch(MEDIAFILE_ADD, {
      method: 'POST',
      body: bodyMediaFile,
      headers: {
        'User-Agent': 'LiftupSite/1.0.0',
        'Authorization': `Bearer ${localStorage.getItem('tokenlu')}`,
      },
    });
    const datamediafile = await responsemediafile.json();

    // добавляем полученные из первого запроса данные в массив данных для body
    bodySlidesData.slideimage = datamediafile.mediafileuid;
    console.log('datamediafile.mediafileuid: ', datamediafile.mediafileuid);

    // второй запрос
    const responseslideadd = await fetch(SLIDES_ADD, {
      method: 'POST',
      body: JSON.stringify(bodySlidesData),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
        'User-Agent': 'LiftupSite/1.0.0',
        'Authorization': `Bearer ${localStorage.getItem('tokenlu')}`,
      },
    });
    const dataslideadd = await responseslideadd.json();

    console.log('dataslideadd: ', dataslideadd);
    console.log('данные на добавление слайда отправлены c перезаписью токенов');

  } else {

    // добавляем полученные из первого запроса данные в массив данных для body
    bodySlidesData.slideimage = datamediafile.mediafileuid
    console.log('datamediafile.mediafileuid: ', datamediafile.mediafileuid);

    // второй запрос
    const responseslideadd = await fetch(SLIDES_ADD, {
      method: 'POST',
      body: JSON.stringify(bodySlidesData),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
        'User-Agent': 'LiftupSite/1.0.0',
        'Authorization': `Bearer ${localStorage.getItem('tokenlu')}`,
      },
    });
    // eslint-disable-next-line no-unused-vars
    const dataslideadd = await responseslideadd.json();
    // console.warn('dataslideadd: ', dataslideadd);

    console.log('данные на добавление слайда отправлены без перезаписи токенов');
  }
};
