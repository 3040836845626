import st from './Analytics.module.css';

export const Analytics = () => {

  return (
    <main className={`${st.analytics} container`}>
      <h1 className={st.title}>Аналитика</h1>

      <div className={st.flashlight2}></div>

      <div className={st.image_wrap}>
        <div className={st.image}></div>
      </div>
    </main>
  )
};
