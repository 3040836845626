import st from './DocsAdd.module.css';
import { ReactComponent as BtnClose } from '../../../../../../img/btn_close.svg';
import { ReactComponent as BtnLoad } from '../../../../../../img/btn_folder.svg';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { fetchDocsList } from 'src/store/docs/getDocs';
import { fetchDocsAddEdit } from '../../fetches/fetchAddEdit';

export const DocsAdd = ({ closeModal, documentList, status }) => {
  // для определения максимального slideOrder
  const [nextOrder, setNextOrder] = useState(0);

  useEffect(() => {
    // при перезагрузке или открытии прямой ссылки чтобы исключить ошибку undefined
    if (status === 'success' && documentList && documentList.length) {
      const maxOrder = Array.from(documentList).reduce((previous, current) => {
        if (+current.documentordernum > +previous.documentordernum) {
          return current;
        } else {
          return previous;
        }
      });
      setNextOrder(maxOrder.documentordernum + 1);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentList]);


  // превью выбранного файла
  const [docPreview, setDocPreview] = useState(null);
  // инпут ввода наименования документа
  const [docName, setDocName] = useState('');
  // ссылка на инпут с кнопкой для выбора файла для загрузки на ПК
  const fileSelection = useRef(null);

  // данные выбранного для отправки файла
  const [selectedFileDouble, setSelectedFileDouble] = useState('');

  const dispatch = useDispatch();

  // данные для второго запроса
  const docsDataBody = {
    'documentuid': '',
    'documentname': docName,
    'documentlink': '',
    'documentordernum': nextOrder
  };

  // отправляет форму
  const handleFormSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('uploadedFile', selectedFileDouble);

    await fetchDocsAddEdit(formData, docsDataBody);

    dispatch(fetchDocsList());

    closeModal();

    console.log('форма отправлена');
  };

  // выбирает новый файл на ПК и вставляет в превью
  const previewFile = (e) => {
    const reader = new FileReader();

    const selectedFile = e.target.files[0];

    setSelectedFileDouble(selectedFile);

    if (selectedFile) {
      reader.readAsDataURL(selectedFile);
    }

    reader.onload = (readerEvent) => {
      if (selectedFile.type.includes('application')) {
        setDocPreview(selectedFile.name);
      }
    };
  };

  // удаляем поле "превью файла"
  const clearPreviewFile = (e) => {
    e.preventDefault();
    setDocPreview(null);
    setSelectedFileDouble('');
  };

  return (
    <div className={st.docsadd_wrap}>
      <button className={st.btn_close} onClick={closeModal}>
        <BtnClose />
      </button>

      <div className={st.docsadd}>
        <h3 className={st.title}>Добавление документа</h3>

        <form className={st.form}>
          {/* блок с превью выбранного для загрузки нового документа */}
          <div className={docPreview !== null ? st.doc_preview_show : st.doc_preview_hide}>
            <p className={st.doc_preview_show_p}>Загрузка файла</p>

            <div className={st.doc_preview}>
              {docPreview !== null && <p className={st.pdf_preview}><span>{docPreview}</span></p>}
              <button className={st.btn_delete} onClick={clearPreviewFile}></button>
            </div>
          </div>

          {/* инпут с кнопкой для выбора документа для загрузки с ПК */}
          <fieldset className={docPreview === null ? st.fieldset_show : st.fieldset_hide}>
            <label className={st.label_image}>
              <input
                ref={fileSelection}
                className={st.input_image}
                type='file'
                name='doc'
                accept='application/pdf'
                onChange={previewFile}
              />
              <p>Загрузка файла...</p>
              <BtnLoad />
            </label>
          </fieldset>

          {/* инпут ввода наименования документа */}
          <div className={st.wrap_title}>
            <p className={docName !== '' ? st.wrap_title_p : st.wrap_title_p_hide}>
              Наименование документа
            </p>

            <fieldset className={st.fieldset_title}>
              <label className={st.label_title}>
                <input
                  className={st.input_title}
                  type='text'
                  name='docname'
                  value={docName}
                  placeholder='Наименование документа'
                  onChange={(e) => setDocName(e.target.value)}
                />
              </label>
            </fieldset>
          </div>

          <button
            className={st.btn_submit}
            onClick={handleFormSubmit}
            disabled={docPreview === null || docName === '' ? true : false}
          >Добавить</button>
        </form>
      </div>
    </div>
  )
};
